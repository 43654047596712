@import '../../global/colors.scss';
.template1{
	.templateList{
		.title{
			.addNewButton{
				display: flex;
		    align-items: center;
		    justify-content: flex-end;
				button{
					margin: 0;
				}
			}
		}
		
		.navigationList{
			.navButtons{
				&.prevButton{
					
				}
				&.nextButton{
			    display: flex;
			    justify-content: flex-end;
				}
			}
			.curNumber{
				//margin-top: 15px;
				.navigationTracker{
					text-align: center;
					display: flex;
			    align-items: center;
			    height: 65px;
			    justify-content: center;
				}
			}
		}
	}
  .iconButton{
    min-width: 0px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    margin: 4px auto;
    font-size: 12px;
  }
}


.templateList{
	.showTrashButton{
		float: right;
    font-weight: 500;
    cursor: pointer;
    padding: 5px 0;
	}
}