@import '../../global/colors.scss';
#MouthMoving{
  position: relative;
  .stepImagesWrapper{
    .stepImages{
      position: relative;
      .stepImage{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        height: auto;
        width: auto;
        opacity: 0;
        border-radius: 15px 15px 0 0;
        &.active{
          opacity: 1;
        }
        &.step1{
          z-index: 1;
        }
        &.step2{
          z-index: 2;
        }
        &.step3{
          z-index: 3;
        }
        &.step4{
          z-index: 4;
        }
        &.step5{
          z-index: 5;
        }
        &.step6{
          z-index: 6;
        }
        &.step7{
          z-index: 7;
        }
        &.step8{
          position: relative;
          z-index: 8;
        }
      }
    }
  }
  .sliderBar{
    height: 2%;
    position: absolute;
    z-index: 10;
    bottom: 0;
    right: 0;
    left: 0;
    .loadedDarker{
      position: absolute;
      top: -1px;
      left: 0;
      right: 0;
      bottom: -2px;
      width: calc( 100% - 16px );
      z-index: 1;
      background: darken(#c01bb9, 20%);
    }
    .sliderBarLoader{
      z-index: 2;
      -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
      width: 100%; /* Specific width is required for Firefox. */
      background: transparent; /* Otherwise white in Chrome */
      
      //background-color: lighten(#c01bb9, 30%);
      position: absolute;
      top: 0px;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      
      /*
        ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        SLIDER HANDLE THUMB
        ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
      */
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
        }
        
        &:focus {
          outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */
        }
        
        &::-ms-track {
          width: 100%;
          cursor: pointer;
        
          /* Hides the slider so custom styles can be added */
          background: transparent; 
          border-color: transparent;
          color: transparent;
        }
        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          border: 1px solid darken(#c01bb9, 10%);
          height: 40px;
          width: 16px;
          border-radius: 3px;
          background: #c01bb9;
          cursor: pointer;
          margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
          //box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d; /* Add cool effects to your sliders! */
        }
        
        /* All the same stuff for Firefox */
        &::-moz-range-thumb {
          //box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
          //border: 1px solid #000000;
          height: 40px;
          width: 16px;
          border-radius: 3px;
          background: #ffffff;
          cursor: pointer;
        }
        
        /* All the same stuff for IE */
        &::-ms-thumb {
          //box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
          //border: 1px solid #000000;
          height: 40px;
          width: 16px;
          border-radius: 3px;
          background: #ffffff;
          cursor: pointer;
        }

      /*
        ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
        Runnable Track
        ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------
      */
        &::-webkit-slider-runnable-track {
          width: 100%;
          height: 14px;
          cursor: pointer;
          //box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
          background: rgba(247,75,239, .64);
          border: none;
          border-radius: 1.3px;
          //border: 0.2px solid #010101;
        }
        
        &:focus::-webkit-slider-runnable-track {
          background: rgba(247,75,239, .64);
          border: none;
        }
        
        &::-moz-range-track {
          width: 100%;
          height: 14px;
          cursor: pointer;
          //box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
          background: rgba(247,75,239, .64);
          border: none;
          border-radius: 1.3px;
          //border: 0.2px solid #010101;
        }
        
        &::-ms-track {
          width: 100%;
          height: 14px;
          cursor: pointer;
          background: transparent;
          border: none;
          border-color: transparent;
          border-width: 16px 0;
          color: transparent;
        }
        &::-ms-fill-lower {
          background: rgba(247,75,239, .64);
          border: none;
          //border: 0.2px solid #010101;
          border-radius: 2.6px;
          //box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        }
        &:focus::-ms-fill-lower {
          background: rgba(247,75,239, .64);
          border: none;
        }
        &::-ms-fill-upper {
          background: rgba(247,75,239, .64);
          border: none;
          //border: 0.2px solid #010101;
          border-radius: 2.6px;
          //box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
        }
        &:focus::-ms-fill-upper {
          background: rgba(247,75,239, .64);
          border: none;
        }
      
    }
    
  }
  @media only screen and (max-width: 801px){

  }
}