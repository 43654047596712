@import './colors.scss';
#AreYouSurePopup{
	#HeaderText{
		padding: 0;
		.textZone{
			padding-bottom: 0;
			padding-left: 0;
			padding-right: 0;
			.title{
				padding-bottom: 0;
				font-size: 35px;
			}
		}
	}
	.row{
		padding-top: 40px;
		padding-bottom: 40px;
	}
	.textZone{
		padding: 20px;
	}
	img{
		width: 100%;
	}
	.title{
		font-weight: 700;
		font-size: 30px;
		line-height: 35px;
		padding-bottom: 20px;
	}
	.subTitle{
		font-weight: 500;
		font-size: 25px;
		line-height: 35px;
	}
	.row1{
		.col-12 > div{
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			background: #dcf4ff;
		}
		.column1{
			flex: 1;
			padding: 20px 40px 20px 40px;;
			.title{
			}
			.subTitle{
			}
		}
	}
	.row2{
		padding-top: 20px;
		padding-bottom: 20px;
		.column1{
			.title{
			}
			.subTitle{
				font-weight: 800;
				text-align: center;
				font-size: 35px;
				
			}
		}
	}
	.row3{
		#areYouSurePopupImage2{
		}
		.col-12 > div{
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
		}
		.column2{
			.title{
			}
			.subTitle{
			}
			.subTitle2{
				font-weight: 700;
				padding: 10px 0;
				font-size: 22px;
			}
			.miniText{
				font-weight: 600;
				font-size: 11px;
			}
		}
	}
	.row4{
		.col-12 > div{
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			background: #dcf4ff;
		}
		.column1{
			flex: 1;
			padding: 20px 40px 20px 40px;
			.title{
			}
			.subTitle{
			}
		}
	}
}


@media only screen and (max-width: 1199px){
	#AreYouSurePopup{
		.row{
			padding-top: 20px;
			padding-bottom: 20px;
		}
		.textZone{
			padding: 20px;
		}
		img{
			width: 100%;
		}
		.title{
			font-weight: 700;
			font-size: 25px;
			line-height: 30px;
			padding-bottom: 20px;
		}
		.subTitle{
			font-weight: 500;
			font-size: 20px;
			line-height: 35px;
		}
	}
}

@media only screen and (max-width: 991px) {
	#AreYouSurePopup{
		.row{
			padding-top: 20px;
			padding-bottom: 20px;
		}
		.textZone{
			padding: 20px;
		}
		img{
			width: 100%;
		}
		.title{
			font-weight: 700;
			font-size: 25px;
			line-height: 30px;
			padding-bottom: 20px;
		}
		.subTitle{
			font-weight: 500;
			font-size: 20px;
			line-height: 35px;
		}
		.row1 .col-12:last-child > div{
			background-color: transparent;
			padding-top: 20px;
		}
		.row2 {
			padding-top: 50px;
			.column1 .subTitle{
				font-size: 30px;
				line-height: 40px;
			}
		}
		.row3{
			padding-bottom: 50px;
		}
		.row4 .col-12:last-child > div{
			background-color: transparent;
			padding-top: 20px;
		}
	}
}

@media only screen and (max-width: 480px) {
}