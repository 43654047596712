@import '../../global/colors.scss';
#Website{
    #PageStore{
        .product{
            background: #f7f7f7;
            border-radius: 5px;
            border: 1px solid #ededed;
            margin-bottom: 60px;
            padding: 20px;
            .productImage{
                padding-bottom: 20px;
                a{
                    .productImageWrapper{
                        overflow: hidden;
                        border-radius: 5px;
                        img{

                        }
                    }
                }
            }
            .productText{
                padding-bottom: 0px;
                flex: 1;
                .productTextPreHeader{
                  padding-bottom: 10px;
                  .productTextPreHeaderCategories{
                    color:#000;
                  }
                  //font-weight: 600;
                  color:#686868;
                  //font-size: 18px;
                }
                .productTextName{
                    padding-top: 0px;
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 1.3;
                    min-height: 52px;
                    max-height: 52px;
                    overflow: hidden;
                }
                @media only screen and (max-width: 767px){
                  .productTextName{
                    font-size: 20px;
                  }
                }
                .productTextDesc{
                    padding-top: 15px;
                    padding-bottom: 0;
                    font-size: 13px;
                    min-height: 155px;
                  div, span, p, ul, li, ol{
                    font-weight: 400;
                    strong{
                      font-weight: 600;
                    }
                  }
                }
                
                .productTextCost{
                  display: flex;
                  align-items: center;
                  padding-top: 10px;
                  .cost{
                    padding-left: 5px;
                    font-size: 22px;
                    font-weight: 600;
                    color: $color1;
                  }
                  .originalPrice{
                    font-weight: 300;
                    text-decoration: line-through;
                  }
                }
                .savings{
                  font-weight: 500;
                  //font-size: 16px;
                  //padding-top: 15px;
                  //padding-left: 10px;
                  font-size: 14px;
                  strong{
                    color: $color4;
                    font-weight: 700;
                  }
                }
                .buttonGroup .button {
                    min-width: 100%;
                    margin: 20px 0 5px;
                }
                @media only screen and (max-width: 780px){
                  .productTextCost{
                    display: block !important;
                    div{
                      display: inline-block !important;;
                    }
                  }
                }
                .productTextSave{
                  font-size: 18px;
                  padding-bottom: 15px;
                }
                .TttonGroup{
                  .dontCloseCart{
                    width: 200px;
                  }
                }
            }
        }
    }
    #SingleProduct{
        padding: 40px 0;
        .productColumnWrapper{
            margin: auto;
            #product{
              display: flex;
              .productImage{
                &.largeScreen{
                  padding: 40px 40px 0px 0;
                  max-width: 500px;
                }
                padding: 0 40px 40px 0;
                max-width: 500px;
                .activeImage{
                  padding: 0 0 10px 0;
                  img{
                    max-width: 100%;
                  }
                }
                .smollImages{
                  display: flex;
                  margin: 0 -5px;
                  .smollImage{
                      flex: 1;
                      padding: 5px;
                      max-width: 33.33%;
                    &.active{
                      img{
                        border: 2px solid $color5;
                      }
                    }
                    cursor: pointer;
                    &:hover{
                      opacity: .5;
                    }
                    img{
                      max-width: 100%;
                    }
                  }
                }
              }
              .productText{
                padding-bottom: 20px;
                flex: 1;
                .productTextPreHeader{
                  padding-bottom: 10px;
                  .productTextPreHeaderCategories{
                    color:#000;
                  }
                  //font-weight: 600;
                  color:#686868;
                  //font-size: 18px;
                }
                .productTextName{
                  padding-top: 0px;
                  font-weight: 700;
                  font-size: 32px;
                  line-height: 1.4;
                }
                @media only screen and (max-width: 767px){
                  .productTextName{
                    font-size: 28px;
                  }
                }
                .productTextDesc{
                  padding-top: 15px;
                  padding-bottom: 15px;
                  div, span, p, ul, li, ol{
                    font-weight: 400;
                    strong{
                      font-weight: 600;
                    }
                  }
                }
                .productIncludes{
                  hr{
                    color: #848484;
                  }
                  .includesTitle{
                    padding-top: 15px;
                    font-size: 16px;
                    font-weight: 600;
                    padding-bottom: 10px;
                  }
                  .includeWrapper{
                    padding-bottom: 20px;
                    .includeItem{
                      .includeItemDiv{
                        
                      }
                    }
                  }
                }
                .productTextCost{
                  display: flex;
                  align-items: center;
                  padding-top: 15px;
                  .cost{
                    padding-left: 5px;
                    font-size: 22px;
                    font-weight: 600;
                    color: $color1;
                  }
                  .originalPrice{
                    font-weight: 300;
                    text-decoration: line-through;
                  }
                }
                .savings{
                  font-weight: 500;
                  //font-size: 16px;
                  padding-top: 15px;
                  //padding-left: 10px;
                  font-size: 14px;
                  strong{
                    color: $color4;
                    font-weight: 800;
                  }
                }
                @media only screen and (max-width: 780px){
                  .productTextCost{
                    display: block !important;
                    div{
                      display: inline-block !important;;
                    }
                  }
                }
                .productTextSave{
                  font-size: 18px;
                  padding-bottom: 15px;
                }
                .TttonGroup{
                  .dontCloseCart{
                    width: 200px;
                  }
                }
                .floatingCheckoutBar{
                    position: fixed;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #efefef;
                    border-top: 1px solid #ddd;
                    padding: 10px 20px;
                    z-index: 6;
                    align-items: stretch;
                    justify-content: space-between;
                    .productTextCost{
                        margin: auto 0;
                        padding: 0;
                        .originalPrice{
                            height: 23px;
                        }
                        .cost{
                            color: #000;
                            padding-left: 10px;
                        }
                    }
                    .buttonGroup{
                        .button{
                            margin-top: 10px;
                        }
                    }
                }
                .buttonGroup{
                    flex-wrap: wrap;
                    .button{
                      margin: 20px 5px 10px;
                      &.inputNumber{
                        border: 1px solid #ddd;
                        color: #000;
                        pointer-events: none;
                        width: 60px;
                        max-width: 60px;
                        min-width: 60px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                      }
                      &.dontCloseCart{
                        width: 200px;
                        padding: 14px 5px;
                      }
                    }
                    .orSpacer{
                      margin: 20px 5px 10px; 
                    }
                  }
              }
              .productImageWrapper{
                max-width: 50%;
                min-width: 330px;
                padding-right: 60px;
                padding-bottom: 20px;
                .productImage{
                  padding: 0;
                  img{
                    border-radius: 10px;
                    overflow: hidden;
                    border: 1px solid #ddd;
                  }
                }
              }
              @media only screen and (max-width: 990px){
                .productImageWrapper{
                    max-width: 100%;
                    min-width: 330px;
                    padding-right: 0px;
                    .productImage{
                        margin: auto;

                    }
                }
              }
            }
            .riskFree{
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 20px;
              border: 2px solid #f2f2f2;
              border-radius: 10px;
              margin-top: 20px;
              margin-bottom: 20px;
              .award{
                color: #ffb600;
                font-size: 60px;
                width: 60px;
              }
              .text{
                flex: 1;
                padding-left: 10px;
                .header{
                  font-weight: 600;
                  font-size: 20px;
                }
                .guarantee{
                  .emph{
                    font-weight: 500;
                  }
                  .emph2{
                    font-weight: 600;
                    color: $color4;
                  }
                }
              }
            }
        }
    }
}