@import '../../global/colors.scss';
#Website{
  #PageBuyImpressionKit{
    .container.simpleStepsHeader{
      padding-bottom: 0 !important;
      margin-bottom: -40px;
    }
    .impressionKitContainer{
      padding-bottom: 10px;
    }
    .stepHeader{
      line-height: 20px;
      min-height: 50px;
      padding-bottom: 10px;
      .stepHeaderStep{
        padding-top: 15px;
        padding-bottom: 6px;
        font-weight: 800;
        font-size: 18px;
        min-width: 80px;
        padding-right: 10px;
        display: inline;
      }
      .stepHeaderTitle{
        padding-top: 15px;
        padding-bottom: 6px;
        font-size: 18px;
        font-weight: 600;
        color: $color5;
        flex: 1;
        min-width: 160px;
        display: inline;
      }
    }
    .stepPostHeader{
      min-height: 100px;
  
    }
    .stepContent{
      font-weight: 500;
      padding-top: 10px;
    }
    .fullSizeImage{
      flex: 1;
      width: 100%;
      img{
        max-width: 100%;
      }
    }
    .imageGroup{
      display: flex;
      margin: 0 -2% 0px;
      position: relative;
      .floatingImage{
        position: absolute;
        top: -30px;
        right: -0px;
        width: 100px;
        img{
          max-width: 100%;
        }
      }
      .image{
        padding: 2%;
        width: 48%;
        img{
          max-width: 100%;
        }
      }
    }
    .iconImage{
      max-width: 120px;
    }
    .smallImageGroup{
      display: flex;
      flex-wrap: wrap;
      margin: 0px -10px 0px;
      img{
        width: 100px;
        padding: 10px;
        margin-bottom: 10px;
      }
    }
  }
  
  
  
  @media only screen and (max-width: 550px) {
    #PageImpressionKit{
      .step{
        border-bottom: 1px solid #ddd;
        padding-bottom: 40px;
      }
      .buttonGroup{
        justify-content: center;
      }
      .stepHeader{
        align-items: flex-start;
        .stepHeaderStep{
          text-align: center;
          flex: 1;
          min-width: 100%;
          padding-top: 5px;
          padding-bottom: 6px;
          font-weight: 800;
          font-size: 18px;
          padding-right: 10px;
        }
        .stepHeaderTitle{
          text-align: center;
          padding-top: 5px;
          padding-bottom: 6px;
          font-size: 18px;
          font-weight: 600;
          color: $color5;
          flex: 1;
          min-width: 100%;
        }
      }
      .stepPostHeader{
        text-align: center;
      }
      .stepContent{
        text-align: center;
      }
      .fullSizeImage{
      }
      .imageGroup{
        display: flex;
        margin: 0 -2% 0px;
        position: relative;
        .floatingImage{
          position: absolute;
          top: -30px;
          right: -0px;
          width: 60px;
          img{
            max-width: 100%;
          }
        }
        .image{
          padding: 2%;
          width: 48%;
          img{
            max-width: 100%;
          }
        }
      }
      .iconImageContent{
        text-align: center;
      }
      .iconImage{
        max-width: 100%;
        min-width: 100%;
        .content {
          padding-bottom: 30px;
          align-items: center;
          justify-content: center;
          display: flex;
          img{
            width: 80%;
            max-width: 120px;
          }
        }
      }
      .smallImageGroup{
        display: flex;
        flex-wrap: wrap;
        margin: 0px -10px 0px;
        img{
          width: 100px;
          padding: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
}
