@import '../../global/colors.scss';
#Website{
  #TreatmentPlanBar{
    align-items: center;
    align-items: center;
    border-top: 10px solid #e91e63;
    border-bottom: 10px solid #e91e63 !important;
    margin-top: -1px;
    .productColumnWrapper{
      margin: auto;
    }
    .productLayout1{
      display: flex;
      .productImage{
        &.largeScreen{
          padding: 40px 40px 0px 0;
          max-width: 500px;
        }
        padding: 0 40px 40px 0;
        max-width: 500px;
        .activeImage{
          padding: 0 0 10px 0;
          img{
            max-width: 100%;
          }
        }
        .smollImages{
          display: flex;
          margin: 0 -5px;
          .smollImage{
            flex: 1;
            padding: 5px;
            cursor: pointer;
            &:hover{
              opacity: .5;
            }
            img{
              max-width: 100%;
            }
          }
        }
      }
      .productText{
        padding-bottom: 0px;
        flex: 1;
        .productTextName{
          padding-top: 0px;
          font-weight: 800;
          font-size: 28px;
          line-height: 32px;
        }
        .productTextDesc{
          padding-top: 15px;
          padding-bottom: 15px;
          font-size: 14px;
          div, span, p, ul, li, ol{
            font-weight: 400;
            strong{
              font-weight: 600;
            }
          }
        }
        .productTextCost{
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          div{
            font-size: 24px;
            font-weight: 800;
          }
          .cost{
            color: $color3;
            padding-left: 10px;
          }
          .originalPrice{
            padding: 0 10px;
            font-weight: 600;
            color: $lightText;
            text-decoration: line-through;
          }
          .savings{
            background-color: $color4;
            font-weight: 600;
            font-size: 13px;
            color: #fff;
            border-radius: 10px;
            width: 180px;
            padding: 2px;
            text-align: center;
            margin-bottom: 5px;
            strong{
              font-weight: 800;
            }
          }
        }
        @media only screen and (max-width: 780px){
          .productTextCost{
            display: block !important;
            div{
              display: inline-block !important;;
            }
          }
        }
        .productTextSave{
          font-size: 18px;
          padding-bottom: 15px;
        }
        .subProductTitle{
          font-weight: 500;
          font-size: 20px;
          padding: 2px 0 20px;
          line-height: 26px;
        }
        .hightLightAffirmText{
          font-size: 16px;
          padding-bottom: 5px;
          font-weight: 600;
          margin-top: -3px;
          strong{
            font-weight: 800;
            color: $color4;
            font-size: 18px;
          }
        }
        
        .buttonGroup{
          .dontCloseCart{
            width: 200px;
          }
        }
      }
    }
    .productImageWrapper{
      max-width: 540px;
      min-width: 330px;
      padding-right: 50px;
      padding-bottom: 20px;
      .productImage{
        padding: 0;
        img{
          border-radius: 10px;
          overflow: hidden;
          border: 1px solid #ddd;
        }
      }
    }
    .productText{
      padding-bottom: 20px;
      .buttonGroup{
        flex-wrap: wrap;
        .button{
          margin: 20px 5px 10px;
        }
        .orSpacer{
          margin: 20px 5px 10px; 
        }
      }
    }

    .stars{
      padding: 5px 0 10px;
      .star{
        display: inline-block;
        color: #ffb600;
        font-size: 18px;
        padding-right: 2px;
      }
    }
    .riskFree{
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 20px;
      .award{
        color: #ffb600;
        font-size: 60px;
        width: 60px;
      }
      .text{
        flex: 1;
        padding-left: 10px;
        .header{
          font-weight: 600;
          font-size: 20px;
        }
        .guarantee{
          .emph{
            font-weight: 500;
          }
          .emph2{
            font-weight: 600;
            color: $color4;
          }
        }
      }
    }
    .container.impressionKitContainer{
      padding-bottom: 40px;
      .smallPrint{
        font-size: 12px;
        color: #6e6e6e;
        padding-top: 40px;
        max-width: 100%;
      }
    }
  }
  &.ESPAÑOL{
    #TreatmentPlanBar{
      .productLayout1{
        .productText{
          .productTextCost{
            .savings{
              width: 195px;
            }
          }
        }
      }
    }
  }



  @media only screen and (max-width: 990px){
    #TreatmentPlanBar{
      flex-wrap: wrap;
      .productImageWrapper{
        max-width: 400px;
        min-width: 0px;
        width: 90%;
        padding-right: 0px;
        padding-bottom: 30px;
        margin: auto;
        .productImage{
          padding: 0;
        }
      }
      .productText{
        padding-bottom: 20px;
        width: 100%;
      }
    }
  }





  @media only screen and (max-width: 767px){
    #TreatmentPlanBar{
      .productImageWrapper{
        .productImage{
        }
      }
      .productText{
        text-align: center;
        .productTextCost{
          justify-content: center;
        }
        .buttonGroup{
          justify-content: center;
        }
        h2{
          max-width: 400px;
          margin: 10px auto 0;
          padding-bottom: 10px;
        }
      }
      .riskFree{
        flex-wrap: wrap;
        .award{
          width: 100%;
          min-width: 100%;
        }
        .text{
          flex: 1;
          padding: 0px 0px 0px;
          .header{
            font-weight: 600;
            font-size: 18px;
          }
          .guarantee{
            font-size: 14px;
            .emph{
              font-weight: 500;
            }
            .emph2{
              font-weight: 600;
              color: $color4;
            }
          }
        }
      }
    }
  }
}