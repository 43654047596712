@import '../global/colors.scss';

#LabWrapper{
	//background-color: darken($color1, 20%); //#f7f7f7;
	height: 100%;
  width: 100%;
  padding: 15px;
  background: $color1;
  //background: linear-gradient(90deg, rgba(0,186,227,1) 0%, rgba(0,155,204,1) 25%, rgba(0,121,180,1) 50%, rgba(0,155,204,1) 81%, rgba(0,212,255,1) 100%);

  //background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background: linear-gradient(-45deg, lighten($color5, 40%), darken($color5, 10%), #785bb6, $color1, mix($color1, $color2), $color2);
  background-size: 400% 400%;
  animation: gradient 20s ease infinite;
  .blur{
  	filter: blur(8px);
		-webkit-filter: blur(8px);
  }
  .fullHeight{
  	min-height: 100%;
  	> .content {
  		height: 100%;
  	}
  }
  img{
  	max-width: 100%;
  }
  .button.actionButton{
    margin: 0;
    width: 90px;
    height: 30px;
    min-width: 0;
    display: flex;
    padding: 0;
    align-items: center;
    justify-content: center;
    .text{
      font-weight: 600;
      padding-right: 5px;
    }
  }
  .headerButton{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    h1{
      flex: 1;
    }
    button{
      margin: 0px 0 20px 0;
    }
  }
	#Lab{
		//font-weight: 900;
    //max-width: 1600px;
    width: 100%;
    margin: auto;
    height: 100%;
    //background-color: #FFF;
    display: flex;
    flex-direction: column;
    //border: 1px solid #ddd;
    border-radius: 40px;
    box-shadow: 0 0 40px rgba(0,0,0,.1);
    //background-color: $color1; //#f7f7f7;
    //border-left: 40px solid $color1;

    max-width: 1800px;
    border-left: 40px solid transparent;
    background: rgba(255,255,255,.54);
    .template1{
      .container-fluid{
        padding: 0;
      }
    }
    .form-control{
      font-size: .9rem;
      color: #53575a;
    }
    .scrollZone{
      flex: 1;
      overflow: auto;
      padding: 20px 15px;
      border: 1px solid #ececec;
      border-radius: 10px;
    }
    #Header{
    	.logo{
    		max-width: 200px;
    	}
    	.info{
    		align-items: center;
    		justify-content: center;
    		.notification{
    			position: relative;
    			font-size: 20px;
    			margin-right: 20px;
    			color: #333;
    			.number{
    				font-size: 12px;
    				position: absolute;
    				top: -4px;
    				right: -4px;
    			}
    			&:hover{
    				color: $color1;
    			}
    		}
    		.doctorIcon{
			    width: 40px;
			    height: 40px;
			    border-radius: 100px;
			    overflow: hidden;
			    display: flex;
			    align-items: center;
			    justify-content: center;
			    border: 1px solid #e8e8e8;
			    img{
			    	max-width: none;
            width: 40px;
            height: 40px;
			    }
    		}
    		#LabSideLanguageToggle{
    			padding-right: 20px;
	        .languageMenu{
	          .languageMenuItem{
	            &:hover, &.active{
	              div{
	                color: $color1;
	              }
	              img{
	                border-color: $color1; 
	              }
	            }
	            div{
	              padding-right: 5px;
	              text-transform: uppercase;
	              color: #000;
	              //font-size: 14px;
	              //font-weight: 500;
	            }
	            img{
	            }
	          }
	        }
	      }
    		.welcomeText{
    			padding-right: 10px;
    			text-decoration: none;
    			color: #333;
    			&:hover{
    				color: $color1;
    			}
    		}
    	}
    }

    


    .notifications{
    	.notification{
    		display: flex;
    		padding-bottom: 10px;
    		.notificationIcon{
    			padding-right: 10px;
    		}
				.notificationText{

				}
    	}
    }
    .activities{
    	.activity{
    		display: flex;
    		padding-bottom: 10px;
    		.activityIcon{
    			padding-right: 10px;
    		}
				.activityText{

				}
    	}
    }


  	.progessIcon{
  		display: flex;
	    align-items: center;
	    justify-content: center;
  		.progessIconImage{
  			height: 200px;
  			width: 200px;
    		img{
    			max-height: 100%;
    		}
  		}
			.progessIconPercent{
				text-align: left;
				padding-left: 10px;
				.percent{
					font-size: 50px;
    			font-weight: 600;
    			line-height: 1em;
				}
				.text{
					font-size: 30px;
					font-weight: 500;
				}
			}
    }


    .sectionValueZone{
      margin-top: -5px;
    }
    .sectionValueWrapper{
      min-width: 300px;
      .sectionValue{
      	
      	//position: relative;
      	// &:after{
  	    // 	border-top: 1px solid #eaeaea;
  	    // 	content: " ";
  	    // 	width: 50%;
  	    // 	margin: auto;
  	    // 	position: absolute;
  	    // 	left: 0;
  	    // 	right: 0;
  	    // 	top: 0px;
      	// }
        border-top: 1px solid $borderColor;
        margin-top: 10px;
        padding-top: 10px;
        display: flex;
        //align-items: center;
        flex-wrap: wrap;
        //justify-content: center;
  			.section{
  				font-weight: 600;
  				text-align: left;
  				min-width: 150px;
          max-width: 150px;
  				padding-right: 20px;
          font-size: 13px;
  			}
  			.value{
  				flex: 1;
          font-size: 13px;
  			}
      }
    }
    #LabContent{
    	display: flex;
    	//height: 100%;
    	flex: 1;
	    height: auto;
	    overflow: hidden;
	    border-radius: 40px;
    	
    	#Content{
    		flex: 1;
		    padding: 0 20px 30px;
		    background: #FFFFFF;//#f7fafb; lighten($color1, 52%);//#fbfbfb;
		    height: 100%;
		    overflow: hidden;
		    position: relative;
        display: flex;
        flex-direction: column;
		    h1.pageHeader{
			    font-size: 30px;
			    text-transform: uppercase;
			    font-weight: 600;
			    margin: 0px 0 20px 0;
			    i{
			    	padding-right: 15px;
			    }
		    }
		    .component {
			    border: 1px solid #ddd;
			    border-radius: 15px;
			    background: #FFF;
			    padding: 20px 30px;
			    margin-bottom: 15px;
			    box-shadow: 5px 5px 6px rgba(0, 0, 0, 0.04);
          &.overflowRightScroll{
            overflow: auto;
            >div{
              //min-width: 300px;
            }
            .value{
              word-break: break-all;
            }
          }
			    .itemHeader{
				    padding-bottom: 10px;
				    width: 100%;
				    align-items: center;
				    justify-content: center;
            display: flex;
            .itemHeaderTextZone{
              flex: 1;
  				    .itemHeaderText{
                &.withIcon{
                  display: flex;
                  .text{
                    flex: 1;
                    font-weight: 600;
                    font-size: 20px;
                  }
                }
  				    	font-weight: 600;
                font-size: 20px;
                div{
                  font-size: 20px;
                  font-weight: 600;
                }
  				    }
              .itemHeaderSubText{
                font-size: 14px;
              }
              .itemHeaderSubText2{
                font-size: 14px;
              }
            }
				    .fa-pencil-alt{
				    	cursor: pointer;
				    	&:hover{
				    		color: $color1;
				    	}
				    }
			    }
				}
    	}
    }
	}

	table.table{
		thead.thead-dark{
      .buttonCategory{
        font-weight: 600;
        font-size: 12px;
      }
			tr{
				th{
					padding: 8px;
          //min-width: 0px !important;
					.icon{
				    padding-left: 5px;
				    height: 7px;
				    display: inline-block;
				    position: relative;
						.fas.fa-sort-up{
							position: absolute;
						}
						.fas.fa-sort-down{
							top: -5px;
							position: absolute;
						}
					}
					&.formButtonWrapper{
			  		width: 70px;
				    text-align: center;
			  	}
          .headerColWrapper{
            &.active{
              //color: $color1Light;
              .headerColText{
                //border-bottom: 2px solid $color1Light;
              }
            }
            cursor: pointer;
            display: flex;
            align-items: center;
            .headerColText{
              position: relative;
              padding-right: 15px;
              //border-bottom: 2px solid transparent;
              .text{
                font-weight: 600;
                font-size: 12px;
              }
              .icon{
                position: absolute;
                top: 5px;
                bottom: 0;
                right: 0;
                margin: auto;
                height: 10px;
                color: $color1Dark;
                i{
                  display: block;
                  height: 100%;
                  width: 100%;
                  margin: auto;
                  &.fa-sort-down{
                    margin-top: -5px;
                  }
                  &.fa-sort-up{
                  }
                }
              }//icon
            }
          }
				}
			}
		}
		tbody{
			tr{
        &:hover{
          background-color: lighten($color1, 53%);
        }
			  &.active{
			    background: #FFF;
			    background: lighten($color1, 45%)
			  }
			  th{
			  	padding: 10px 8px;
			  	&.formButtonWrapper{
			  		width: 70px;
				    text-align: center;
			  	}
			    div{
			      font-weight: 400;
			      font-size: 11px !important;
			    }
			    .formButton{
			    	i{
			    		color: $color1;
				    	cursor: pointer;
				    	&:hover{
				    		color: $color1Dark;
				    	}
			    	}
			    }
			    i{
			      cursor: pointer;
			      &:hover{
			        color: $color1;
			      }
			    }
			  }
        .previewButtonIcon{
          max-width: 40px !important;
          min-width: 40px !important;
          height: 30px;
          margin: auto;
          text-align: center;
          padding: 0;
          align-items: center;
          justify-content: center;
        }
			}
		}
	}
  .navigationList{
    .navButtons{
      &.prevButton{
        
      }
      &.nextButton{
        display: flex;
        justify-content: flex-end;
      }
      button.button{
        min-width: 100px;
        padding: 8px 15px;
      }
    }
    .curNumber{
      //margin-top: 15px;
      .navigationTracker{
        text-align: center;
        display: flex;
        align-items: center;
        height: 65px;
        justify-content: center;
      }
    }
  }






  #LabProgressChanger{
    position: fixed;
    top: 0px;
    bottom: 0px;
    background: #fff;
    padding: 20px;
    z-index: 10000;
    right: -300px;
    border-left: 1px solid;
    border-top: 1px solid;
    transition: right .5s ease;
    width: 350px;
    &:hover{
      right: 0px;
    }
    .header{
      h5{
      }
      i{
        position: absolute;
        right: 0;
        width: 50px;
        background: #fff;
        text-align: center;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 20px;
        border: 1px solid;
        top: 0px;
        cursor: pointer;
      }
    }
    .buttonList{
      .buttonWrapper{
        .button{
        }
      }
    }
  }
}

.doctorSlideInRightBody{
  padding: 20px;
}


@keyframes gradient {
  0% {
    background-position: 0% 100%;
  }
  50% {
    background-position: 100% 0%;
  }
  100% {
    background-position: 0% 100%;
  }
}


@media only screen and (max-width: 850px){
  #LabWrapper{
    #Lab{
      border-left: 0px solid transparent;
    }
  }
}