@import '../../global/colors.scss';
#Website{
  #PageResetPassword{
    .error, .success{
      display: flex;
      //align-items: center;
      .icon{
        font-size: 50px;
        padding-right: 30px;
      }
      .textWrapper{
        flex: 1;
        .header{
          font-size: 2rem;
          font-weight: 700;
        }
        .text{
          font-size: 16px;
        }
      }
    }
    .error{    
      border: 1px solid #ddd;
      border-radius: 5px;
      padding: 20px 15px 30px;
      background-color: #fff;
      .icon{
      }
      .textWrapper{
        .header{
        }
        .text{
        }
      }
    }
    .resetPWButton{
      padding-top: 5px;
      font-weight: 500;
      text-transform: uppercase;
    }
  }
}