@import '../../global/colors.scss';
#LabHome{
  .priceSummary{
    display: flex;
    justify-content: space-between;
    .priceTotal{
      display: flex;
      align-items: center;
      .priceTotalTitle{
        font-weight: 500;
        padding-right: 10px;
      }
      .priceTotalPrice{
        font-size: 20px;
        font-weight: 600;
      }
    }
    .payNowButton{
      //flex: 1;
      align-items: flex-end;
      justify-content: flex-end;
    }
  }
  .status{
    background: #f5f5f5;
    padding: 15px 20px;
    min-height: 220px;
    .statusText{
      .statusTextContainer{
        display: flex;
        border-bottom: 2px solid $color1;
        padding: 15px 0;
        margin-bottom: 10px;
        .statusIcon{
          display: flex;
          align-items: center;
          justify-content: center;
          i{
            font-size: 50px;
          }
        }
        .statusText{
          padding-left: 20px;
          flex: 1;
          .statusTextTitle{
            font-size: 18px;
            margin-bottom: -5px;
          }
          .statusTextStatus{
            font-size: 22px;
            font-weight: 700;
            color: $color1;
            text-transform: uppercase;
          }
        }
      }
      .statusTextDesc{
        padding-top: 10px;
      }
    }
  }
}