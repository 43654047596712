@import './colors.scss';
#ContactForm{
	#SuccessMessage{
		text-align: center;
		.logoHeader{
			max-width: 400px;
		}
	}
}


@media only screen and (max-width: 800px) and (min-width: 576px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 480px) {
}