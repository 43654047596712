@import '../../global/colors.scss';
#SmilesClub{
  #PageCheckout{
    min-height: 100%;
    .pageCheckoutHeader{
      background-color: $color1;
      h1{
        margin: 0;
        padding: 20px 0;
        color: #FFFFFF;
      }
    }
    #SideSummaryColumn{
      > .content{
        border-right: 2px solid $color1;
        background: #fafcff;
      }
    }
    #CheckoutPageComponentLeftSide{
      padding-bottom: 40px;
      //min-height: 100%;
      
      display: flex;
      flex-direction: column;
      #CartContentWrapper{
        flex: 1;
        #CartContent{
          
        }
      }
    }
    #FinancingWrapper{
      text-align: center;
      #FinancingWrapperTitle{
        #FinancingWrapperTitle1{
          font-size: 38px; 
          font-weight: 600;
          strong{
            
          }
        }
        #FinancingWrapperTitle2{
          font-size: 28px; 
          font-weight: 600;
          background-color: $color4;
          color: #fff;
          max-width: 500px;
          margin: auto;
        }
        @media only screen and (max-width: 1199px) {
          #FinancingWrapperTitle1{
            padding-top: 40px;
            font-size: 32px; 
          }
          #FinancingWrapperTitle2{
            font-size: 22px; 
          }
        }
       strong{
        
       }
      }
      #BlerbWrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        padding-top: 10px;
        #AfterpayBlerb{
          .logo{
            img{
              
            }
          }
        }
        #AffirmBlerb{
          .logo{
            img{
            }
          }
        }
        .blerbWrapper{
          padding: 20px 0px;
          flex: 1;
          .blerb{
            border: 2px solid $color4;
            border-radius: 15px;
            padding: 10px 10px 30px;
            min-width: 350px;
            width: 45%;
            margin: auto;
            height: 100%;
            .logo{
              width: 200px;
              margin: auto;
            }
            .info{
              font-size: 22px;
              strong{
                color: $color4;
                font-weight: 800;
              }
            }
            .smallText{
              font-size: 16px;
              max-width: 85%;
              margin: auto;
              line-height: 22px;
              padding-top: 5px;
            }
          }
        }
        @media only screen and (max-width: 1199px) {
          .blerbWrapper{
            padding: 10px 0px;
            flex: 1;
            .blerb{
              padding: 5px 7px 20px;
              min-width: 250px;
              .logo{
              }
              .info{
                font-size: 18px;
                strong{
                  font-size: 22px;
                }
              }
              .smallText{
              }
            }
          }
        }
        @media only screen and (max-width: 991px) {
          .blerbWrapper{
            padding: 10px 0px;
            flex: 1;
            .blerb{
              min-width: 0px;
              width: 96%;
              .logo{
                width: 180px;
                margin: auto;
              }
              .info{
                font-size: 20px;
                strong{
                }
              }
              .smallText{
                font-size: 14px;
                line-height: 20px;
                padding-top: 2px;
              }
            }
          }
        }
      }

    }
    #CheckoutPageComponentRightSide{
      padding-bottom: 40px;
    }
  }
  
  @media only screen and (max-width: 991px) {
    #PageCheckout{
      .pageCheckoutHeader{
        h1{
          font-size: 20px;
          padding: 10px 0;
        }
      }
      #SideSummaryColumn{
        > .content{
          border-right: none;    
          background: #fafcff;
        }
      }
      #CheckoutPageComponentLeftSide{
        border-right: none;      
        //max-height: 100px;
        padding-bottom: 0px;
        overflow: hidden;
        position: relative;
        //min-height: 80px;
        &.active{
          //max-height: 100%;
          #CartContentWrapper{
            #CartContent{
              
            }
          }
        }
        #CartContentWrapper{
          transition: margin-top 1s ease;
          //margin-top: calc( -100% );
          height: 100%;
          #CartContent{
            padding-bottom: 0px;
            border: 1px solid $color1;
            border-radius: 0 0 25px 25px;
          }
        }
        .expandButton{
          position: absolute;
          bottom: 0px;
          right: 0px;
          left: 0px;
          z-index: 2;
          display: flex;
          align-items: center;
          justify-content: center;
          .button{
            margin: auto;
            display: block;
            padding-top: 4px;
            padding-bottom: 4px;
            border-radius: .6rem .6rem 0 0;
            //width: 100%;
          }
        }
      }//End of CheckoutPageComponentLeftSide
    }
  }
  @media only screen and (max-width: 600px) {
    #PageCheckout{
      .pageCheckoutHeader{
        h1{
        }
      }
      #CheckoutPageComponent{
        padding: 0;
        #CheckoutPageComponentRightSide{
          padding-bottom: 40px;
          #CheckoutBreadcrumbs{
            padding: 10px 0 30px;
          }
        }
      }
      // #CheckoutPageComponentRightSide{
      //   padding-bottom: 0px;
      // }
    }
  }
}




