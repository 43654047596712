@import '../../global/colors.scss';
#DoctorWrapper{
  #Header{
    position: relative;
    background: transparent;
    //border-bottom: 1px solid #ddd;
    height: 80px;
    min-height: 80px;
    align-items: center;
    justify-content: center;
    display: flex;
    
    .content{
    	.image{
  	  	max-width: 210px;
  	  }
  	  .info{
  	  	float: right;
  	  }
    }
    .cartButton{
      margin: 0;
      padding: 0 12px 0 0;
      .cartLink{
        width: auto;
        height: auto;
        padding: 0;
        &:hover{
          .cartCount{
          }
          .cartIcon{
            color: $color1;
          }
        }
        .cartCount{
          position: absolute;
          color: #FFF;
          right: -12px;
          top: -12px;
          width: 18px;
          height: 18px;
          border-radius: 50px;
          padding-top: 1px;
          font-weight: 500;
          font-size: 10px;
          text-align: center;
          text-justify: auto;
          line-height: 18px;
          background-color: $color5;
        }
        .cartIcon{

        }
      }
    }
    .mobileHeader{
      display: none;
    }
    .mainHeader{
      display: block;
    }
  }
}


@media only screen and (max-width: 850px){
  #DoctorWrapper{
    #Header{
      position: relative;
      background: transparent;
      //border-bottom: 1px solid #ddd;
      height: 80px;
      min-height: 80px;
      align-items: center;
      justify-content: center;
      display: flex;
      .content{
        .image{
          max-width: 210px;
        }
        .info{
          float: right;
        }
      }
      .mobileHeader{
        display: block;
        .content{
          display: flex;
          align-items: center;
          justify-content: center;
          .siteLogo{
            flex: 1;
            display: block;
            img{
              max-width: 90%;
              width: 150px;
            }
          }
          .mobileButtonBox{
            font-size: 20px;
            padding-left:20px;
          }
          .cartButton{
            margin: 0;
            padding: 0;
            .cartLink{
              .cartCount{
              }
              .cartIcon{
              }
            }
          }
        }
      }
      .mainHeader{
        display: none;
      }
    }
  }
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 480px) {


}