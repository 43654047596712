@import '../../global/colors.scss';

$textColor1: #FFF;
$textColor2: #ffd940;
$brightPink: #ff2dc5;
#GiveawayBanner{
  display: flex;
  flex-wrap: wrap;
  border: 1px solid green;
  //background-color: $color5;
  //background: url("../../../global/img/background/background4.jpg");
  // background-repeat: no-repeat;
  // background-size: cover;
  // background: url("../../../global/img/background/STPATBACKGROUND.jpg");
  // background-repeat: repeat;
  // background-size: 500px;
  // background-position: center;
  background-color: #05123e;
  position: relative;
  &:before{
    content: " ";
    filter: blur(1.1px);
    opacity: .3;
    position: absolute;
    //background: url("../../../global/img/background/pattern1.png");
    background-repeat: repeat;
    background-size: 350px;
    background-position: center;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  .sections{
    z-index: 2;
    min-width: 200px;
    flex: 1;
    padding: 10px 10px;
    align-items: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .section1{
    justify-content: flex-start;
    .text1{      
      font-weight: 700;
      font-size: 22px;
      line-height: 1.4rem;
      color: #fff;
    }
    .text2{    
      font-weight: 400;
      color: $brightPink;//lighten($color5, 20%);
      .emph{
        font-weight: 600;
        font-style: italic;
        border-bottom: 1px solid #000;
        padding-bottom: 2px;
        color: #fff;
      }  
    }
  }
  .section2{
    .section2Content{
      width: 200px;
      .text1{      
        font-weight: 600;
        color: $textColor1;
        text-align: center;
        text-transform: uppercase;
      }
    }
  }
  .section3{
    justify-content: flex-end;
    .section3Content{
      .button.button2{
        margin: auto;
        font-weight: 600;
        border-color: $brightPink;
        background-color: $brightPink;
        color: #000;
        padding: 0;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 120px;
        width: 120px;
        &:hover{
          background-color: transparent;
          color: #FFF;
          border-color: $brightPink;
          //border-color: #FFF;
        }
      }
    }
  }
  .countdownCards{
    display: flex;
    width: 160px;
    margin: auto;
    .cardZone{
      //flex: 1;
      width: 40px;
      &.redBackground{
        .card{
          transition: all 0s ease;
          background-color: $color5;
          .number{
            color: #FFF;
          }
          .line{
            background-color: rgba(255,255,255,.3);
          }
        } 
      }
      .card{
        transition: all .5s ease;
        border-radius: 4px;
        border: 1px solid #ddd;
        background-color: #FFF;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 35px;
        height: 40px;
        margin: auto;
        .number{
          color: #000;
          font-weight: 600;
        }
        .line{
          height: 1px;
          background-color: rgba(0,0,0,.08);
          width: 100%;
          position: absolute;
          top: 50%;
          left: 0;
          right: 0;
        }
      }
      .title{
        text-align: center;
        text-transform: uppercase;
        font-size: 12px;
        color: $textColor1;
        font-weight: 400;
      }
    }
  }
}



@media only screen and (max-width: 800px) {
  #GiveawayBanner{
    padding: 15px 0;
    .sections{
      min-width: 100%;
      text-align: center;
      .section1Content{
        width: 100%;
        .text1{
          font-size: 18px;
        }
        //color: #FFF;
      }
      .section3Content{
        margin: auto;
      }
    }
  }
}

@media only screen and (max-width: 480px) {


}