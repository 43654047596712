@import './colors.scss';
.accordionComponent{
  .faq-box{
    padding-bottom: 1px;
    &.active{
      .faq-title{
        i.fa-minus{
          opacity: 1;
        }
        i.fa-plus{
          opacity: 0;
        }
      }
    }
    .faq-title{
      text-align: left;
      display: flex;
      background-color: #f2f2f2;
      padding: 10px;
      align-items: center;
      cursor: pointer;
      .text{
        flex: 1;
        font-weight: 500;
        font-size: 18px;
      }
      .fa{
        width: 40px;
        position: relative;
        margin-right: -10px;
        height: 15px;
      }
      i{
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        font-size: 18px;
        text-align: center;
        transition: opacity .3s ease;
        &.fa-minus{
          opacity: 0;
        }
      }
    }
    .faq-text{
      text-align: left;
      max-height: 0px;
      overflow: hidden;
      transition: max-height .5s ease;
      .faq-text-wrapper{
        padding: 20px;
        //border: 1px solid #efefef;
        //font-size: 14px;
        p{

        }
        ul{
          li{
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) and (min-width: 576px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 480px) {
}