@import '../../global/colors.scss';
#Website{
    #PageAbout{
        strong{
            font-weight: 600;
        }
        .textColumn{
            .content{
                display: flex;
                align-items: center;
                justify-content: center;
                height: 100%;
            }
        }
        .floatingImageRight{
            margin: auto;
            max-width: 400px;
            border: 2px solid $color1;
            border-radius: 10px;
            padding: 5px;
            box-shadow: 2px 2px 15px #00000054;
            transform: rotate(-13deg);
            img{
                border-radius: 10px;
                transform: rotate(8deg);
                box-shadow: 1px 1px 13px #00000040;
            }
        }
        .floatingImageLeft{
            margin: auto;
            max-width: 400px;
            border: 2px solid $color5;
            border-radius: 10px;
            padding: 5px;
            box-shadow: 2px 2px 15px #00000054;
            transform: rotate(6deg);
            img{
                border-radius: 10px;
                transform: rotate(-8deg);
                box-shadow: 1px 1px 13px #00000040;
            }
        }
        #Section1{
            h5{
                line-height: 1.4;
            }
            > .container{
                max-width: 1500px;
                
            }
            #MainImage{
                img{
                    border-radius: 20px;
                }
            }
        }
        #Section2{
        }
        #Section3{
        }
        #Section4{
        }
        #Section5{
        }
        #Section6{
        }
        .imageCenter .content{
            max-width: 300px;
            margin: 10px auto 60px;
        }
        .sectionRows{
            .sectionTitle2{
                padding-bottom: 10px;
            }
            .textSection{
                padding: 0 40px 0px;
                margin: auto;
                //max-width: 500px;
            }
            .floatingImage{
                margin: auto;
                max-width: 400px;
            }
            .col{
                 
            }
        }
        .sectionRowsSmaller{
            padding: 0 20px 40px;
        }
    }
    @media only screen and (max-width: 991px){
        #PageAbout{
            text-align: center;
            .imageCenterMD .content{
                max-width: 300px;
                margin: 10px auto 60px;
            }
            .imageCenterMDMain .content{
                max-width: 500px;
                margin: 10px auto 40px;
            }
        }
    }
}

