@import '../../global/colors.scss';
#SlideInRightContent.treatmentPlanOffer.frontEndSlideInRight{
  #Body{
    padding: 0px !important;
    .iconWhite{
      color: #FFF
    }
    .dialogCustomZone{
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      //overflow: auto;
      hr{
        border-bottom: 2px solid $color5;
        margin: 25px 0 35px;
        opacity: 1;
      }
      .previewForm{
        .previewFormTitle{
          font-size: 24px;
          font-weight: 600;
        }
        .previewFormSubTitle{
          font-size: 16px;
          color: #545454;
          padding-bottom: 10px;
          margin-bottom: 30px;
          border-bottom: 4px solid $color1;
        }
      }

      form.FormBuilder .form-group{
      }
      .editPatient{
        .form-group{
          padding: 0 10px;
        }
      }
      #LabEditTreatmentPlan{
        flex: 1;
        display: flex;
        flex-direction: column;
        height: 100%;

        .body{
          flex: 1;
          padding: 20px 30px;
          overflow: auto;
        }
        .footer{
          padding-right: 10px;
          .buttonGroup{
            justify-content: flex-end;
          }
        }
        padding-top: 0px;
        form.FormBuilder .form-group{
          border-bottom: 2px solid $color5;
          margin-bottom: 20px;
          padding-bottom: 20px;
          > div {
            padding: 10px;
          }
        }
        .currentInfoZone{
          border-bottom: 2px solid $color4;
          padding-bottom: 40px;
          margin-bottom: 40px;
          .sectionTitle{
            font-weight: 500;
            font-size: 16px;
          }
          .notes{
            padding: 15px 10px;
            background-color: #f9f9f9;
            margin-top: 10px;
            font-size: 14px;
          }
        }
      }
    }
  }
}