@import '../../global/colors.scss';
#Website{
    #PageFAQ{
        .mainImage{
            padding-right: 30px;
        }
        .container:first-child{
             padding-bottom: 0;
        }
    }
}


@media only screen and (max-width: 991px){
    #Website{
        #PageFAQ{
            .mainImage{
                padding-right: 0px;
            }
        }
    }
  }