@import '../../global/colors.scss';
#UserAffiliate{
  #HeaderPaymentInfo{
    display: flex;
    margin: 0 -10px;
    flex-wrap: wrap;
    .comWrapper{
      padding: 0 10px;
      &.comWrapper1{
        min-width: 400px;
      }
      .component{
        padding: 25px;
        border: 2px solid $color5;
        &.borderColor1{
          border: 2px solid $color1;
        }
        &.borderColor2{
          border: 2px solid $color2;
        }
        &.iconAndHeaderComp{
          display: flex; 
          align-items: center;
          overflow: hidden;
          .iconAndHeader{
           display: flex; 
           flex: 1;
           align-items: center;
           min-width: 350px;
           .icon{
            font-weight: 600;
            font-size: 16px;
            width: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 35px;
            border-radius: 5px;
            background-color: #df7ab62b;
            color: #000;
            &.color1{
              background-color: #7acbdf2b;
              color: darken($color1, 10%);
            }
            &.color2{
              background-color: #7adf7a2b;
              color: $color2;
            }
           }
           .text{
            padding-left: 10px;
            font-weight: 600;
            font-size: 20px;
           }
          }
          .number{
            background-color: $color5;
            margin: -25px -25px -25px 0;
            padding: 0 20px;
            color: #fff;
            display: flex;
            font-size: 35px;
            height: 85px;
            font-weight: 700;
            align-items: center;
            justify-content: center;
            min-width: 80px;
            &.color1{
              background-color: $color1;
            }
            &.color2{
              background-color: $color2;
            }
          }
        }
      }
    }
    .comWrapperLonger{
      flex: 1;
    }
  }
  .nothingFoundMessage{
    color: #5c5c5c;
    font-weight: 300;
  }
  .itemHeader{
    .itemHeaderTextZone{
      .itemHeaderText.withIcon.itemHeaderTextCombo{
        display: flex; 
         align-items: center;
        .icon{
          font-weight: 600;
          font-size: 16px;
          width: 35px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 35px;
          border-radius: 5px;
          background-color: #94949420;
          color: #000;
        }
        .text{
          padding-left: 10px;
          font-weight: 600;
          font-size: 20px;
        }
      }
    }
  }
  .componentSummary{
    font-size: 14px;
    padding-bottom: 10px;
  }
  .tableWrapper{
    .table{
      thead{
        tr{
          th{
            border-color: #ededed;
            &.buttonSectionHeader{
              width: 30px;
              //text-align: right;
            }
          }
        }
      }
      tbody{
        tr{
          &:hover{
            background-color: #03a9f414;
          }
          td{
            padding-top: 5px;
            padding-bottom: 5px;
            border: none;
            font-size: 13px;
            font-weight: 500;
            &:last-child{
              text-align: right;
            }
            &.buttonSection{
              text-align: center;
              .iconButtonWrapper{
                color: #969696;
                &:hover{
                  color: $color1;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
  }
  .couponColumn{
    padding-bottom: 20px;
  }
  #JoinAffiliatesComponent{
    #JoinAffiliates{
      .joinAffiliatesWrapper{
        text-align: center;
        .joinAffiliatesText{
          padding: 10px;
          max-width: 800px;
          margin: auto;
          .title{
            font-weight: 600;
            font-size: 30px;
          }
          .process{
            font-weight: 800;
            font-size: 16px;
            color: $color5;
            text-transform: uppercase;
            padding-bottom: 5px;
            i{
              font-size: 14px;
              padding: 0 14px;
              color: #c3c3c3;
            }
          }
          .info{
            font-size: 14px;
            color: rgb(84 84 84);
            font-weight: 500;
          }
          button.button.button1{
            position: relative;
            padding-right: 40px;
            padding-left: 40px;
            i{
              position: absolute;
              bottom: -5px;
              right: -5px;
              font-size: 35px;
            }
          }
          .requestSent{
            color: $color4;
            font-weight: 600;
            padding-top: 15px;
            padding-bottom: 0;
            margin-bottom: 0;
            font-size: 20px;
          }
        }
        .affiliateIcon{
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          font-size: 70px;
          background-color: $color1;
          color: #fff;
        }
      }
    }
    #AffiliatesWhy{
      padding: 10px;
      .affiliatesWhy{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        .personImage{
          margin-bottom: 20px;
          img{
            border-radius: 5%;
            max-width: 400px;
            width: 100%;
            margin: auto;
          }
        }
        .infoGroups{
          flex: 1;
          padding-left: 40px;
          min-width: 500px;
          margin-bottom: 20px;
          .infoTitle{
            font-size: 28px;
            font-weight: 700;
          }
          .infoGroup{
            padding-top: 15px;
            display: flex;
            flex-wrap: wrap;
            .icon{
              i{
                color: $color2;
                font-size: 33px;
              }
            }
            .text{
              flex: 1;
              padding-left: 20px;
              .infoGroupTitle{
                font-size: 18px;
                font-weight: 600;
                padding-bottom: 2px;
                line-height: 24px;
              }
              .infoGroupText{
                font-size: 14px;
                color: rgb(84 84 84);
                font-weight: 500;
              }
            }
          }
        }
        
      }
    }
  }
  @media only screen and (max-width: 600px){
    #JoinAffiliatesComponent{
      #JoinAffiliates{
        .joinAffiliatesWrapper{
          text-align: center;
          .joinAffiliatesText{
            padding: 10px;
            max-width: 100%;
            margin: auto;
            .title{
              font-size: 25px;
              line-height: 32px;
              padding-bottom: 10px;
            }
            .process{
              font-size: 12px;
              padding-bottom: 5px;
              i{
                font-size: 14px;
                padding: 0 14px;
                color: #c3c3c3;
              }
            }
            .info{
              font-size: 14px;
            }
            button.button.button1{
              i{
                font-size: 35px;
              }
            }
          }
          .affiliateIcon{
          }
        }
      }
      #AffiliatesWhy{
        .affiliatesWhy{
          .personImage{
            text-align: center;
            img{
              border-radius: 5%;
              max-width: 80%;
              width: 100%;
              margin: auto;
            }
          }
          .infoGroups{
            flex: 1;
            padding-left: 0px;
            min-width: 100px;
            margin-bottom: 10px;
            .infoTitle{
              text-align: center;
              font-size: 22px;
            }
            .infoGroup{
              .icon{
                i{
                  font-size: 30px;
                }
              }
              .text{
                padding-left: 15px;
                .infoGroupTitle{
                }
                .infoGroupText{
                }
              }
            }
          }
          
        }
      }
    }
  }
  #PromoCodes{
    min-height: 150px;
    &.loading{
      position: relative;
    }
    #LoadingScreen{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba(255, 255, 255, 0.7);
      display: flex;
      align-items: center;
      justify-content: center;
      .loadingScreen{
        .loadingIcon{
          max-width: 50px;
          img{

          }
        }
        .loadingHeader{
          margin-bottom: 5px;
        }
        .loadingBody{
          margin-bottom: 0px;
        }
      }
    }
  }
  #Chart{
    .chart{
      position: relative;
      max-width: 200px;
      margin: 0 auto 20px;
      .numApproved{
        position: absolute;
        left: 0;
        right: 0;
        margin: auto;
        text-align: center;
        top: 50%;
        -webkit-transform: translate(0px, -50%);
        transform: translate(0px, -50%);
        background: white;
        width: 150px;
        height: 150px;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 100px;
        flex-direction: column;
        .title{
          font-weight: 600;
          width: 100px;
          font-size: 14px;
          text-align: center;
          padding-bottom: 5px;
          line-height: 14px;
        }
        .number{
          font-weight: 700;
          font-size: 44px;
          line-height: 34px;
        }
      }
    }
    .labels{
      .label{
        display: flex;
        padding-top: 10px;
        .colorBox{
          width: 20px;
          border-radius: 5px;
        }
        .title{
          padding-left: 10px;
          font-weight: 600;
          flex: 1;
          font-size: 13px;
        }
        .earned{
          font-weight: 700;
          font-size: 13px;
        }
      }
      .labelTotal{
        display: flex;
        .title{
          flex: 1;
          font-size: 16px;
        }
        .earned{
          font-size: 16px;
        }
      }
    }
  }
}

@media only screen and (min-width: 1392px){
  #UserAffiliate #CurAlignerColumn > .content {
    height: calc( 100% - 15px );
    #CurAlignerComponent2 {
      margin-bottom: 0px;
    }
  }
}

@media only screen and (max-width: 1391px){
  #UserAffiliate .alignerScheduleColumn > .content {
    height: calc( 100% - 30px );
  }
}

@media only screen and (max-width: 1280px){
  #UserAffiliate{
    .container-fluid  .col{
      flex: 1;
    }
    .alignerScheduleColumn{
      min-width: 100%;
      flex: 1;
    }
  }
}
