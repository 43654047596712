@import '../../global/colors.scss';
#Website{
	#PagePrice{
		.bigIcon {
			font-size: 140px;
			color: $color1;
		}
		#HowToPay{
			border-top: 1px solid #ddd;
			border-bottom: 1px solid #ddd;
			h3.sectionTitle3{
				font-weight: 500;
				padding-bottom: 20px;
			}
			.paymentTypes{
				.paymentType{
					.typeTitle{
						margin-bottom: 0px;
					font-weight: 700;
						//font-size: 30px;
					}
					.typeTitle{
						font-weight: 700;
						font-size: 35px;
					}
					.paymentAmmount{
						color: #0070e0;
						margin-bottom: 20px;
						font-weight: 600;
						font-size: 30px;
						.paymentAmmountText{
							color: inherit;
							font-weight: 600;
							font-size: 30px;
							&.smallerText{
								color: #333;
								font-size: 25px;
							}
							.discountPrice{
								font-weight: 700;
							}
							.crossOut{
								color: #787878;
								font-weight: 500;
								text-decoration: line-through;
							}
						}
					}
					.summary{
						min-height: 110px;
					}
					.buttonGroup{
						.button{
							max-width: 100%;
							width: 70%;
							margin: 50px 5px 0px;
							font-size: 20px;
						}
					}
				}
			}
		}
		#PaymentOptions{
			border: 4px solid #4f46f5;
    		padding: 20px;
			border-radius: 10px;
			.separatorColumn{
				max-width: 4px;
				background-color: #4f46f5;
				.content{
					width: 4px;
					height: 100%;
					.separator{
						//border: 4px solid #4f46f5;
					}
				}
			}
			.paymentLogo{
				.affirmLogoBig{
					max-width: 300px;
					padding-top: 40px;
					padding-bottom: 36px;
				}
			}
		}
		.paymentLogos{
			display: flex;
			flex-wrap: wrap;
			justify-content: flex-end;
			padding-top: 30px;
			.paymentLogo{
				max-width: 100px;
				box-shadow: 0 0 5px 0px rgba(0, 0, 0, .2);
				margin: 0 0px 20px 20px;
				border-radius: 10px;
			}
		}
		.smallPrint{
			max-width: 850px;
			margin: auto;
			font-size: 13px;
		}
		.summary{
			font-size: 16px;
		}
		#PaymentTypes{
			.subHeader{
				font-size: 18px;
			}
		}
	  #ImpressionKitBarRow{
		> .container  {
		  padding-bottom: 30px;
		  padding-top: 50px;
		}
	  }
	}
	
	
	@media only screen and (max-width: 1450px){
	  #PagePrice{
		.bigIcon {
		  font-size: 100px;
		}
		#PaymentOptions{
			.paymentLogo{
				.affirmLogoBig{
					max-width: 260px;
					padding-top: 20px;
					padding-bottom: 10px;
				}
			}
		}
	  }
	}
	
	@media only screen and (max-width: 991px){
		#PagePrice{
		  #PaymentOptions{
			  border: 4px solid #4f46f5;
			  padding: 20px;
			  border-radius: 10px;
			  .separatorColumn{
				  max-width: 4px;
				  background-color: #4f46f5;
				  min-width: 100%;
				  height: 8px;
				  margin: 40px 0;
				  .content{
					  width: 100%;
					  height: 8px;
					  .separator{
						  //border: 4px solid #4f46f5;
					  }
				  }
			  }
			  .paymentLogo{
				  .affirmLogoBig{
				  }
			  }
		  }
		}
	}
	@media only screen and (max-width: 575px){
		#PagePrice{
		  #PaymentOptions{
			  border: 4px solid #4f46f5;
			  padding: 20px;
			  border-radius: 10px;
			  max-width: 96%;
			  margin: 0 auto;
			  .separatorColumn{
				  max-width: 4px;
				  background-color: #4f46f5;
				  min-width: 100%;
				  height: 8px;
				  margin: 40px 0;
				  .content{
					  width: 100%;
					  height: 8px;
					  .separator{
						  //border: 4px solid #4f46f5;
					  }
				  }
			  }
			  .paymentLogo{
				  .affirmLogoBig{
					  max-width: 70%;
					  padding-top: 20px;
					  padding-bottom: 10px;
				  }
			  }
		  }
		}
	}
	
	@media only screen and (max-width: 800px){
	  #PagePrice{
		#paymentTypes {
		  .row{
			flex-wrap: wrap;
			.col{
			  min-width: 100%;
			  .paymentLogos{
				margin: 0 -2%;
				align-items: center;
				justify-content: center;
				.paymentLogo{
				  margin: 0 2% 20px;
				  max-width: 46%;
				  width: 100%;
				}
			  }
			}
		  }
		}
	  }
	}  
}
