//$color1: #1ab0c3;
$color1: #00bae3;
$color1Dark: darken($color1, 10%);
$color1Light: lighten($color1, 10%);

// $color1: #48bfee;
// $color1Dark: darken($color1, 10%);
// $color1Light: lighten($color1, 10%);

$color2: #38b64b;
$color2Dark: darken($color2, 10%);
$color2Light: lighten($color2, 10%);

$color3: #2196f3; //#0b5980;
$color3Dark: darken($color3, 10%);
$color3Light: lighten($color3, 10%);

$color4: #6c41fe;
$color4Dark: darken($color4, 10%);
$color4Light: lighten($color4, 10%);

$color5: #eb008b;
$color5Dark: darken($color5, 10%);
$color5Light: lighten($color5, 10%);



$color6: #233c4e; //ADMIN SIDEMENU COLOR
$color6Dark: darken($color6, 10%);
$color6Light: lighten($color6, 10%);


$color7: #FDD205; //ADMIN SIDEMENU COLOR
$color7Dark: darken($color7, 10%);
$color7Light: lighten($color7, 10%);

$color8: #222222; //ADMIN SIDEMENU COLOR
$color8Dark: darken($color8, 10%);
$color8Light: lighten($color8, 10%);

$border: 1px solid #ddd;
$lightText: #8c8c8c;

$borderColor: #efefef;


$buttonBlue: #2196f3;
$buttonBlueDark: #1e85d9;