@import '../../global/colors.scss';
#AdminProgressLogs{
  height: 100%;
  display: flex;
  flex-direction: column;
  .body{
    flex: 1;
    overflow: auto;
    .startText{
      height: 100%;
      .FormBuilder{
        height: 100%;
        .form-group{
          height: 100%;
        }
      }
    }
  }
  .footer{
    .buttonGroup{
      
    }
  }
}