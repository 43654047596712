@import '../../global/colors.scss';
#UserTreatmentPlan{
	.planSummary{
		.itemHeader{
			.fas{
				display: block;
				font-size: 40px;
			}
		}
	}
  .planFiles{
    display: flex;
    flex-wrap: wrap;
    .planFile{
      flex: 1;
      max-width: 250px;
      min-width: 200px;
      text-align: center;
      padding-bottom: 20px;
      min-height: 260px;
      display: flex;
      flex-direction: column;
      .icon{
        font-size: 60px;
      }
      .name{
        font-weight: 600;
        padding-bottom: 0px;
        font-size: 16px;
        overflow: hidden;
        height: 45px;
        line-height: 1.4;
        //white-space: nowrap;
        //text-overflow: ellipsis;
        padding: 0 15px;
      }
      .type{
        padding-bottom: 10px;
        font-size: 14px;
      }
      .buttonGroup{
        align-items: center;
        flex: 1;
        .button{
          margin: auto;
        }
      }
    }
  }
}