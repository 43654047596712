@import '../../global/colors.scss';
#SlideInRightContent.newOrderDialog.frontEndSlideInRight{
  #Body{
    // height: 100%;
    
    // display: flex;
    padding: 20px 30px;
    .dialogCustomZone{
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      //overflow: auto;
      hr{
        border-bottom: 2px solid $color5;
        margin: 25px 0 35px;
        opacity: 1;
      }
      .previewForm{
        .previewFormTitle{
          font-size: 24px;
          font-weight: 600;
        }
        .previewFormSubTitle{
          font-size: 16px;
          color: #545454;
          padding-bottom: 10px;
          margin-bottom: 30px;
          border-bottom: 4px solid $color1;
        }
      }
      .footer{
        padding-right: 10px;
        .buttonGroup{
          justify-content: flex-end;
        }
      }
      form.FormBuilder .form-group{
      }
      .editPatient{
        .form-group{
          padding: 0 10px;
        }
      }
      .editTreatmentPlan{
        padding-top: 40px;
        form.FormBuilder .form-group{
          border-bottom: 2px solid $color5;
          margin-bottom: 20px;
          padding-bottom: 20px;
          > div {
            padding: 10px;
          }
        }
      }
    }
  }
}