@import '../../global/colors.scss';

#PaymentComplete{
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
  #PaymentStatus{
    text-align: center;
    &.loading{
      #StatusIcon i {
        color: $color1;
      }
    }
    &.not_found{
      #StatusIcon i {
        color: $color5;
      }
    }
    &.succeeded{
      #StatusIcon i {
        color: $color4;
      }
    }
    &.processing{
      #StatusIcon i {
        color: $color4;
      }
    }
    &.requires_payment_method{
      #StatusIcon i {
        color: $color5;
      }
    }
    &.failed{
      #StatusIcon i {
        color: $color6;
      }
    }
    #StatusIcon{
      font-size: 120px;
      i{
        -webkit-animation-name: spin;
        -webkit-animation-duration: 4000ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;

        -moz-animation-name: spin;
        -moz-animation-duration: 4000ms;
        -moz-animation-iteration-count: infinite;
        -moz-animation-timing-function: linear;

        -ms-animation-name: spin;
        -ms-animation-duration: 4000ms;
        -ms-animation-iteration-count: infinite;
        -ms-animation-timing-function: linear;

        animation-name: spin;
        animation-duration: 4000ms;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
      }
    }
    #StatusText{
      #StatusTitle{
        font-size: 30px;
      }
      #StatusDes{
        font-size: 16px;
        max-width: 600px;
      }
    }
    #ButtonGroup{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}


@-ms-keyframes spin { 
  from { 
      -ms-transform: rotate(0deg); 
  } to { 
      -ms-transform: rotate(360deg); 
  }
}
@-moz-keyframes spin { 
  from { 
      -moz-transform: rotate(0deg); 
  } to { 
      -moz-transform: rotate(360deg); 
  }
}
@-webkit-keyframes spin { 
  from { 
      -webkit-transform: rotate(0deg); 
  } to { 
      -webkit-transform: rotate(360deg); 
  }
}
@keyframes spin { 
  from { 
      transform: rotate(0deg); 
  } to { 
      transform: rotate(360deg); 
  }
}


@media only screen and (max-width: 600px) {
  #PaymentComplete{
    .pageCheckoutHeader{
      h1.sectionTitle{
        font-size: 25px;
        padding: 10px 0;
      }
    }
    #CheckoutPageComponent{
      padding: 0;
      #CheckoutPageComponentRightSide{
        padding-bottom: 40px;
        #CheckoutBreadcrumbs{
          padding: 10px 0 30px;
        }
      }
    }
    #CheckoutPageComponentLeftSide{
      padding-bottom: 40px;
    }
  }
}
