@import '../../global/colors.scss';
#TreatmentPlanProduct{
  align-items: center;
  align-items: center;
  border-top: 10px solid #e91e63;
  border-bottom: 10px solid #e91e63 !important;
  margin-top: -1px;
  padding-bottom: 40px;
  .productColumnWrapper{
    margin: auto;
  }
  .productLayout1{
    display: flex;
    .productImage{
      &.largeScreen{
        padding: 40px 40px 0px 0;
        max-width: 500px;
      }
      padding: 0 40px 40px 0;
      max-width: 500px;
      .activeImage{
        padding: 0 0 10px 0;
        img{
          max-width: 100%;
        }
      }
      .smollImages{
        display: flex;
        margin: 0 -5px;
        .smollImage{
          flex: 1;
          padding: 5px;
          &.active{
            img{
              border: 2px solid $color5;
            }
          }
          cursor: pointer;
          &:hover{
            opacity: .5;
          }
          img{
            max-width: 100%;
          }
        }
      }
    }
    .productText{
      padding-bottom: 0px;
      flex: 1;
      .productTextPreHeader{
        padding-bottom: 10px;
        .productTextPreHeaderCategories{
          color:#000;
        }
        //font-weight: 600;
        color:#686868;
        //font-size: 18px;
      }
      .productTextName{
        padding-top: 0px;
        font-weight: 800;
        font-size: 28px;
        line-height: 32px;
      }
      @media only screen and (max-width: 767px){
        .productTextName{
          font-size: 28px;
        }
      }
      .productTextDesc{
        padding-top: 15px;
        padding-bottom: 15px;
        div, span, p, ul, li, ol{
          font-weight: 400;
          strong{
            font-weight: 600;
          }
        }
      }
      .productTextCost{
        padding-top: 15px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        div{
          font-size: 24px;
          font-weight: 800;
        }
        .cost{
          color: $color3;
          padding-left: 10px;
        }
        .originalPrice{
          padding: 0 10px;
          font-weight: 600;
          color: $lightText;
          text-decoration: line-through;
        }
        .savings{
          background-color: $color4;
          font-weight: 600;
          font-size: 13px;
          color: #fff;
          border-radius: 10px;
          width: 180px;
          padding: 2px;
          text-align: center;
          margin-bottom: 5px;
          strong{
            font-weight: 800;
          }
        }
      }
      @media only screen and (max-width: 780px){
        .productTextCost{
          display: block !important;
          div{
            display: inline-block !important;;
          }
        }
      }
      .productTextSave{
        font-size: 18px;
        padding-bottom: 15px;
      }
      .subProductTitle{
        font-weight: 500;
        font-size: 20px;
        padding: 2px 0 10px;
        line-height: 26px;
      }
      .hightLightAffirmText{
        font-size: 16px;
        padding-bottom: 15px;
        font-weight: 600;
        margin-top: -3px;
        strong{
          font-weight: 800;
          color: $color4;
          font-size: 18px;
        }
      }
      .buttonGroup{
        .dontCloseCart{
          width: 200px;
        }
      }
    }
  }
  .productImageWrapper{
    max-width: 450px;
    min-width: 330px;
    padding-right: 30px;
    padding-bottom: 20px;
    .productImage{
      padding: 0;
      img{
        border-radius: 10px;
        overflow: hidden;
        border: 1px solid #ddd;
      }
    }
  }
  .productText{
    padding-bottom: 20px;
    .buttonGroup{
      flex-wrap: wrap;
      .button{
        margin: 20px 5px 10px;
        &.inputNumber{
          border: 1px solid #ddd;
          color: #000;
          pointer-events: none;
          width: 60px;
          max-width: 60px;
          min-width: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &.dontCloseCart{
          width: 200px;
          padding: 14px 5px;
        }
      }
      .orSpacer{
        margin: 20px 5px 10px; 
      }
    }
  }

  .stars{
    padding: 5px 0 10px;
    .star{
      display: inline-block;
      color: #ffb600;
      font-size: 18px;
      padding-right: 2px;
    }
  }
  .riskFree{
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: 2px solid #f2f2f2;
    border-radius: 10px;
    margin-top: 20px;
    margin-bottom: 20px;
    .award{
      color: #ffb600;
      font-size: 60px;
      width: 60px;
    }
    .text{
      flex: 1;
      padding-left: 10px;
      .header{
        font-weight: 600;
        font-size: 20px;
      }
      .guarantee{
        .emph{
          font-weight: 500;
        }
        .emph2{
          font-weight: 600;
          color: $color4;
        }
      }
    }
  }
  .container.impressionKitContainer{
    padding-bottom: 40px;
    .smallPrint{
      font-size: 12px;
      color: #6e6e6e;
      padding-top: 40px;
      max-width: 100%;
    }
  }
}




@media only screen and (max-width: 990px){
  #TreatmentPlanProduct{
    flex-wrap: wrap;
    .productImageWrapper{
      max-width: 400px;
      min-width: 0px;
      width: 90%;
      padding-right: 0px;
      padding-bottom: 30px;
      margin: auto;
      .productImage{
        padding: 0;
      }
    }
    .productText{
      padding-bottom: 20px;
      width: 100%;
    }
  }
}





@media only screen and (max-width: 767px){
  #TreatmentPlanProduct{
    .productImageWrapper{
      .productImage{
      }
    }
    .productText{
      text-align: center;
      .productTextCost{
        justify-content: center;
      }
      .buttonGroup{
        justify-content: center;
      }
      h2{
        max-width: 400px;
        margin: 10px auto 0;
        padding-bottom: 10px;
      }
    }
    .riskFree{
      flex-wrap: wrap;
      .award{
        width: 100%;
        min-width: 100%;
        font-size: 45px;
      }
      .text{
        flex: 1;
        padding: 0px 0px 0px;
        .header{
          font-weight: 600;
          font-size: 18px;
        }
        .guarantee{
          font-size: 14px;
          .emph{
            font-weight: 500;
          }
          .emph2{
            font-weight: 600;
            color: $color4;
          }
        }
      }
    }
  }
}
