@import '../../global/colors.scss';

#TodoList{
  background-color: #FFF;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 765px;
  .lists{
    flex: 1;
    overflow: scroll;
    .activeNotes{
      padding: 10px;
      background-color: rgba(229,234,246,.20);
      .clickToAddNew{
        border: 1px solid #ddd;
        text-align: center;
        background: #fff;
        font-weight: 700;
        text-transform: uppercase;
        padding: 10px;
        border-radius: 5px;
        cursor: pointer;
        &:hover{
          background-color: $color1;
          color: #FFF;
          border-color: $color1;
          box-shadow: 0 0 4px rgba(0,0,0,.2);
        }
      }
    }
    .inactiveNotes{
      padding: 10px;
      opacity: .7;
      .inactiveNotesTitle{
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #ddd;
        padding: 0 10px 10px;
        margin-bottom: 10px;
        .text{
          flex: 1;
          padding-right: 10px;
          font-weight: 700;
          text-transform: uppercase;
        }
        .fas{
          //transform: rotate(90deg);
          transition-duration: .2s;
          &.active{
            transform: rotate(90deg);
          }
        }
      }
      .quickNote{
        .timeAndDate{
          .title{
          }
        }
      }
    }
  }
  .newNoteButtonWrapper{
    padding: 20px 10px 15px 10px;
    .newNoteButton{
      margin: 0;
      width: 100%;
      font-weight: 700;
      text-transform: uppercase;
      i{
        padding-right: 5px;
      }
    }
  }
  .quickNote{
    display: flex;
    padding: 8px;
    align-items: flex-start;
    justify-content: flex-start;
    //border-bottom: 1px solid #eee;
    //margin-bottom: 10px;
    &:hover{
      background: white;
    }
    .icon{
      .fas{
        display: none;
      }
      .far{
        display: block;
      }
      &:hover{
        .fas{
        display: block;
        }
        .far{
          display: none;
        }
      }
      i{
        padding: 4px 10px 4px 4px;
        cursor: pointer;
        &:hover{
          color: $color5;
        }
        &:active{
          color: $color5Dark;
        }
      }
    }
    .timeAndDate{
      flex: 1;
      .title{
        padding-bottom: 8px;
        line-height: 1.4;
        font-weight: 600;
        display: flex;
        .text{
          font-size: 14px;
          width: 100%;
          flex: 1;
          textarea{
            width: 100%;
          }
        }
        .trash{
          cursor: pointer;
          &:hover{
            color: red;
          }
        }
      }
      .date{
        font-size: 11px;
        color: rgb(134, 134, 134);
        font-weight: 500;
        .fas{
          padding-right: 5px;
          color: rgba(134, 134, 134, .7);
        }
      }
    }
  }
}


@media only screen and (max-width: 800px) and (min-width: 576px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 480px) {


}