@import "./colors.scss";
#Root {
  .fileDropZone {
    &.active .dropZoneWrapper .dropZone {
      background-color: $color1;
    }
    .dropZoneWrapper {
      display: flex;
      flex-direction: column;
      font-family: sans-serif;
      .dropZone {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 20px;
        border-width: 2px;
        border-radius: 2px;
        border-color: $color1;
        font-weight: 500;
        font-size: 16px;
        border-style: dashed;
        background-color: #fafafa;
        color: $color1;
        outline: none;
        transition: border 0.24s ease-in-out;
        &:hover {
          border-color: #fff;
          background-color: $color1;
          color: #fff;
          cursor: pointer;
        }
        input {
          display: none;
        }
        p {
          margin: auto;
        }
      }
    }
    .currentFiles {
      padding-top: 20px;
      div.header {
        font-size: 16px;
        font-weight: 600;
        padding-bottom: 10px;
      }
      .files {
        .file {
          position: relative;
          padding-bottom: 10px;
          border-bottom: 1px solid #ddd;
          margin-bottom: 10px;
          //padding-right: 20px;
          overflow: hidden;
          display: flex;
          align-items: center;
          img {
            max-width: 100%;
          }
          .fileName {
            font-size: 14px;
          }
          .loading {
            position: absolute;
            cursor: pointer;
            top: 0;
            right: 0;
            display: flex;
            align-items: center;
            .text {
              font-size: 12px;
              padding-right: 8px;
            }
            //.iconSpin{}
          }
          .actionButtons {
            flex: 1;
            display: flex;
            justify-content: flex-end;
            .download {
              background-color: #222;
              border-radius: 5px;
              font-size: 13px;
              width: 30px;
              height: 25px;
              margin-left: 4px;
              display: flex;
              align-items: center;
              justify-content: center;
              a {
                color: #fff;
                cursor: pointer;
                padding: 0 5px;
              }
              &:hover {
                background-color: $color2;
              }
            }
            .trash {
              //position: absolute;
              color: #fff;
              background-color: #525252;
              border-radius: 5px;
              font-size: 13px;
              width: 30px;
              height: 25px;
              cursor: pointer;
              padding: 0 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              &:hover {
                background-color: red;
              }
            }
          }
        }
      }
    }
  }
}
