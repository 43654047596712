@import '../../global/colors.scss';
#Website{
  #PageLogin{
    #TopMainSection{
      //min-height: 1000px;
      .loginForm{
        &.hasFailed{
          .failedMessage{
            color: red;
            font-weight: 600;
            text-transform: uppercase;
          }
          input{
            background-color: #FFF;
            box-shadow: 0 0 5px rgba(255, 0, 0, .5);
          }
        }
        
      }
    }
  }
}