@import '../../global/colors.scss';
#Website.customLandingPages{
  @media only screen and (max-width: 800px){
    #Content .container {
        padding-top: 30px;
        padding-bottom: 30px;
    }
  } 
  #Content{
    .containerSection{
      border: none;
    }
    .container .container{
      padding: 40px 0;
    }
  }
  #CustomLandingPage{
    .container.simpleStepsHeader{
      padding-bottom: 0 !important;
      margin-bottom: -40px;
    }
    .impressionKitContainer{
      padding-bottom: 10px;
    }
    .stepHeader{
      line-height: 20px;
      min-height: 50px;
      padding-bottom: 10px;
      .stepHeaderStep{
        padding-top: 15px;
        padding-bottom: 6px;
        font-weight: 800;
        font-size: 18px;
        min-width: 80px;
        padding-right: 10px;
        display: inline;
      }
      .stepHeaderTitle{
        padding-top: 15px;
        padding-bottom: 6px;
        font-size: 18px;
        font-weight: 600;
        color: $color5;
        flex: 1;
        min-width: 160px;
        display: inline;
      }
    }
    .stepPostHeader{
      min-height: 100px;
  
    }
    .stepContent{
      font-weight: 500;
      padding-top: 10px;
    }
    .fullSizeImage{
      flex: 1;
      width: 100%;
      img{
        max-width: 100%;
      }
    }
    .imageGroup{
      display: flex;
      margin: 0 -2% 0px;
      position: relative;
      .floatingImage{
        position: absolute;
        top: -30px;
        right: -0px;
        width: 100px;
        img{
          max-width: 100%;
        }
      }
      .image{
        padding: 2%;
        width: 48%;
        img{
          max-width: 100%;
        }
      }
    }
    .iconImage{
      max-width: 120px;
    }
    .smallImageGroup{
      display: flex;
      flex-wrap: wrap;
      margin: 0px -10px 0px;
      img{
        width: 100px;
        padding: 10px;
        margin-bottom: 10px;
      }
    }
    #TreatmentPlanBar{
      padding-bottom: 50px;
    }

    #StepsZone{
      #MiddleImage{
        margin-top: -80px;
      }
      @media only screen and (max-width: 991px) {
        #MiddleImage{
          margin: 40px 0px;
        }
      }
      .step{
        .text{
          text-align: center;
          .textHeader{
            .textHeaderStep{
              font-size: 30px;
              line-height: 32px;
              font-weight: 700;
            }
            .textHeaderTitle{
              font-size: 20px;
              font-weight: 600;
              color: $color5;
            }
          }
          .textPostHeader{
            padding-top: 8px;
            font-size: 16px;
          }
        }
      }
    }
    .headerZone{
      background-color: $color5;
    }
    #BuyItToday{
      min-width: 1600px;
      .topTextZone{
        max-width: 770px;
        .textZoneWrapper{
          text-align: right;
          color: #FFF;
          padding-right: 40px;
          .preHeader{
            color: #000;
            font-weight: 600;
          }
          .sectionTitle{
            strong{
              color: #000;
            }
          }
          .sectionText{
            color: #000;
            font-size: 18px;
            font-weight: 500;
          }
        }
      }
      .stepImages{
        margin: -70px 0px -20px 0px;
      }
    }
    @media only screen and (max-width: 1600px){
      #BuyItToday{
        min-width: 1200px;
        .topTextZone{
          max-width: none;
          .textZoneWrapper{
            .preHeader{
            }
            .sectionTitle{
              strong{
              }
            }
            .sectionText{
            }
          }
        }
        .stepImages{
          margin: 0px 0px 0px 0px;
        }
      }
    }
    @media only screen and (max-width: 1199px){
      #BuyItToday{
        min-width: inherit;
        .topTextZone{
          max-width: none;
          .textZoneWrapper{
            text-align: right;
            color: #FFF;
            padding-right: 40px;
            .preHeader{
              color: #000;
              font-weight: 600;
            }
            .sectionTitle{
              strong{
                color: #000;
              }
            }
            .sectionText{
              color: #000;
              font-size: 18px;
              font-weight: 500;
            }
          }
        }
        .stepImages{
          width: 100%;
        }
      }
    }
    #BuyNowButton{
      margin: 20px 5px;
      display: block;
      border: 2px solid $color1;
      //max-width: 200px;
      //padding: 15px;
    }
  }
  //CUSTOMLANDING PAGE
  @media only screen and (max-width: 991px){
    #CustomLandingPage #BuyItToday {
      .topTextZone .textZoneWrapper{
        text-align: center;
        padding-right: 0;
      }
    }
  }

  @media only screen and (max-width: 800px){
    #Content{
      > div > div:first-child{
        padding: 0;
      }
      #UsVsThem{
        .contentCenter > .content {
          overflow: auto;
          margin: 0 -28px;
          .usVsThemCompare{
            .compare{
              overflow: visible;
            }
          }
        }
      }
    }
    #CustomLandingPage #BuyItToday {
      .stepImages{
        padding-bottom: 20px;
      }
    }
  }


  
  
  
  @media only screen and (max-width: 550px) {
    #PageImpressionKit{
      .step{
        border-bottom: 1px solid #ddd;
        padding-bottom: 40px;
      }
      .buttonGroup{
        justify-content: center;
      }
      .stepHeader{
        align-items: flex-start;
        .stepHeaderStep{
          text-align: center;
          flex: 1;
          min-width: 100%;
          padding-top: 5px;
          padding-bottom: 6px;
          font-weight: 800;
          font-size: 18px;
          padding-right: 10px;
        }
        .stepHeaderTitle{
          text-align: center;
          padding-top: 5px;
          padding-bottom: 6px;
          font-size: 18px;
          font-weight: 600;
          color: $color5;
          flex: 1;
          min-width: 100%;
        }
      }
      .stepPostHeader{
        text-align: center;
      }
      .stepContent{
        text-align: center;
      }
      .fullSizeImage{
      }
      .imageGroup{
        display: flex;
        margin: 0 -2% 0px;
        position: relative;
        .floatingImage{
          position: absolute;
          top: -30px;
          right: -0px;
          width: 60px;
          img{
            max-width: 100%;
          }
        }
        .image{
          padding: 2%;
          width: 48%;
          img{
            max-width: 100%;
          }
        }
      }
      .iconImageContent{
        text-align: center;
      }
      .iconImage{
        max-width: 100%;
        min-width: 100%;
        .content {
          padding-bottom: 30px;
          align-items: center;
          justify-content: center;
          display: flex;
          img{
            width: 80%;
            max-width: 120px;
          }
        }
      }
      .smallImageGroup{
        display: flex;
        flex-wrap: wrap;
        margin: 0px -10px 0px;
        img{
          width: 100px;
          padding: 10px;
          margin-bottom: 10px;
        }
      }
    }
  }
  
  @media only screen and (max-width: 600px) {
    #SimpleStepsList{
      .stepWrapper{
        .step{
          display: block;
          text-align: center;
          .image{
            margin: auto;
            
          }
          .textWrapper{
            width: 100%;
            .text{
              .textHeader{
                .textHeaderStep{
                  display: block;
                }
                .textHeaderTitle{
    
                }
              } 
            } 
          } 
        }
      }
    } 
  }

  @media only screen and (max-width: 700px) { //THIS SHOULD SOLVE THE PROBLEM OF ON APPLE PHONES THE LOGO BEING WAY TOO BIG.
    #Header .image-column .content .logo-image img{
      max-width: 180px;
    }
  }
  

  #Header{
    .preHeaderWrapper{
      min-height: 20px;
    }

  }




  #Footer{
    border-top: 1px solid #ddd;
    background-color: #FFFFFF;
    padding: 60px 0;
    .footerSection1{
      padding-bottom: 90px;
      .title{
        font-size: 40px;
        font-weight: 500;
        padding-bottom: 15px;
      }
      .text{
        max-width: 500px;
        font-size: 16px;
      }
      .buttonRight{
        .content{
          align-items: center;
          justify-content: center;
          justify-items: center;
          height: 100%;
          display: flex;
          .button{
            margin: 0;
          }
        }
      }
    }
    .footerSection2{
      .footerSlogan{
        .logoImage{
          max-width: 200px;
          a{
            padding: 0;
          }
          img{
            max-width: 100%;
          }
        }
        .slogan{
          padding-top: 20px;
          max-width: 540px;
          padding-bottom: 30px;
          font-size: 14px;
        }
        .copyright{
          display: flex;
          font-size: 14px;
          font-weight: 500;
        }
        .header{
          font-weight: 600;
          padding-bottom: 20px;
          font-size: 20px;
        }
        .menu{
          display: block;
          .menu-item{
            margin: 0;
            padding: 0;
            a.nav-link{
              color: #333;
              padding: 0px 0px 8px;
              font-size: 14px;
              &:hover, &.active{
                color: $color1Dark;
              }
            }
          }
        }
      }
      .socialColumn{
        max-width: 300px;
        .socialLinks{
          display: block;
          margin: 0 -10px;
          .socialItem{
            display: inline-block;
            flex: none;
            max-width: none;
            padding: 10px;
            font-size: 35px;
            margin-top: -10px;
            a{
              color: $color1;
              &:hover{
                color: $color1Dark;
              }
            }
          }
        }

      }
    }
    .footerContact{
      display: flex;
      padding-top: 0px;
      padding-bottom: 10px;
      .icon{
        font-size: 16px;
        background-color: #eb008b;
        color: #fff;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        border-radius: 100px;
        i{
          padding: 0;
        }
      }
      .text{
        font-weight: 400;
        padding-left: 10px;
        font-size: 14px;
        flex: 1 1;
        display: flex;
        align-items: center;
        a{
          text-decoration: none;
          color: #414141;
          &:hover{
            cursor: pointer;
            color: $color1;
          }
        }
      }
      // .phone{
      //   font-weight: 600;
      //   padding-left: 10px; 
      //   font-size: 16px;
      //   flex: 1;
      // }
      
    }
  }
  @media only screen and (max-width: 991px) {
    #Footer{
      .footerSection2 .footerSlogan .slogan{
        max-width: 100%;
        padding: 0;
      }
      .socialColumn{
        display: none;
      }
    }
  }
}
