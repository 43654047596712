@import './colors.scss';
#CustomToasters{
  right: 20px;
  top: 25px;
  position: fixed;
	.toasterWrapper{
		animation-duration: .5s;
		box-shadow: 0 5px 10px rgba(0,0,0,.2);
		overflow: hidden;
    width: 300px;
    height: auto;
    display: flex;
    flex-direction: column;
    background: rgb(255, 255, 255);
    border: 1px solid;
    border-radius: 5px;
    margin-bottom: 20px;
		&.color-1{
			border-color: #38b64b;
			.toasterTitleWrapper{
				background-color: #38b64b;
			}
		}
		&.color-2{
			border-color: #cc513e;
			.toasterTitleWrapper{
				background-color: #cc513e;
			}
		}
		&.color-3{
			border-color: #ffc107;
			.toasterTitleWrapper{
				background-color: #ffc107;
			}
		}
		&.color-4{
			border: none;
			.toasterTitleWrapper{
				background-color: #2196f3;
			}
		}
		.toasterTitleWrapper{
			display: flex;
			padding: 5px 5px;
			align-items: center;
	    justify-content: center;
			.toasterTitle{
		    color: #FFF;
		    flex: 1 1;
		    padding: 0 10px;
		    font-size: 14px;
		    text-transform: uppercase;
		    font-weight: 600;
			}
			.toasterClose{
				color: #FFF;
				padding: 5px;
				cursor: pointer;
				&:hover{
					color: #000;
				}
				i{

				}
			}
		}
		.toasterContent{
			padding: 10px;
			min-height: 50px;
		}
	}
}


@media only screen and (max-width: 800px) and (min-width: 576px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 480px) {
}