@import '../../global/colors.scss';
.productWrapper{
  .products{
   .product.productLayout2{
      display: flex;
      flex-wrap: wrap;
      .productImages{
        padding: 0 40px 40px 0;
        max-width: 350px;
        .productImage{
          padding: 0 0 10px 0;
          img{
            max-width: 100%;
          }
        }
        .smollImages{
          .smollImage{
            max-width: 25%;
            img{
              max-width: 100%;
            }
          }
        }
      }
      .productText{
        padding-bottom: 40px;
        flex: 1;
        .productTextName{
          font-weight: 500;
          font-size: 24px;
        }
        .productTextDesc{
          padding-bottom: 15px;
        }
        .productSubPrice, .productSubPrice div{
          font-size: 18px;
          font-weight: 500;
          line-height: 1.4em;
          .small{
            font-size: 14px;
            color: #666;
            padding-top: 2px;
            padding-left: 5px;
          }
        }
        .productTextCost{
          font-size: 22px;
          font-weight: 600;
          small{
            padding-left: 10px;
            text-decoration: line-through;
          }
        }
        .productTextSave{
          font-size: 18px;
          padding-bottom: 15px;
        }
        .buttonGroup{
          padding-top: 20px;
        }
      }
    } 
  }
}


@media only screen and (max-width: 850px){
  .productWrapper{
    .products{
      .product.productLayout2{
        .productImages{
          padding: 0 40px 40px 0;
          max-width: 100%;
          width: 100%;
          .productImage{
            padding: 0 0 10px 0;
            img{
              max-width: 100%;
            }
          }
          .smollImages{
            .smollImage{
              max-width: 25%;
              img{
                max-width: 100%;
              }
            }
          }
        }
        .productText{
          padding-bottom: 40px;
          flex: 1;
          .productTextName{
            font-weight: 500;
            font-size: 24px;
          }
          .productTextDesc{
            padding-bottom: 15px;
          }
          .productSubPrice, .productSubPrice div{
            font-size: 18px;
            font-weight: 500;
            line-height: 1.4em;
            .small{
              font-size: 14px;
              color: #666;
              padding-top: 2px;
              padding-left: 5px;
            }
          }
          .productTextCost{
            font-size: 22px;
            font-weight: 600;
            small{
              padding-left: 10px;
              text-decoration: line-through;
            }
          }
          .productTextSave{
            font-size: 18px;
            padding-bottom: 15px;
          }
          .buttonGroup{
            padding-top: 20px;
          }
        }
      } 
    }
  }
}



@media only screen and (max-width: 400px){
  .productWrapper{
    .products{
      .product.productLayout2{
        .productImages{
          padding: 0;
          max-width: 100%;
          width: 100%;
          margin: auto;
          .productImage{
            padding: 0 0 10px 0;
            img{
              max-width: 100%;
            }
          }
          .smollImages{
            .smollImage{
              max-width: 25%;
              img{
                max-width: 100%;
              }
            }
          }
        }
        .productText{
          padding-bottom: 20px;
          .productTextName{
            font-size: 20px;
          }
          .productTextDesc{
            padding-bottom: 12px;
          }
          .productSubPrice, .productSubPrice div{
            font-size: 14px;
            .small{
              font-size: 14px;
              color: #666;
              padding-top: 2px;
              padding-left: 5px;
            }
          }
          .productTextCost{
            font-size: 20px;
            small{
              padding-left: 10px;
              text-decoration: line-through;
            }
          }
          .productTextSave{
            font-size: 18px;
            padding-bottom: 15px;
          }
          .buttonGroup{
            padding-top: 20px;
          }
        }
      } 
    }
  }
}