@import '../global/colors.scss';
@import './components/index.scss';
@import './pages/checkout.scss';


#Website{
	font-weight: 400;
  	height: 100%;
  .bigPicture{
    text-align: center;
  }
  .buttonGroup{
  	.button{
  		margin-top: 20px;
  		margin-left: 5px;
  		margin-right: 5px;
  	}
  }
  h1{
		//font-weight: 600;
		font-size: 40px;
		&.sectionTitle{
			font-weight: 700;
		}
	}
	h2{
		&.sectionTitle2{
			font-weight: 700;
			margin-bottom: 5px;
		}
		&.limitWidth{
	    max-width: 800px;
	    margin-left: auto;
	    margin-right: auto;
		}
	}
  h3{
    &.sectionTitle3{
      font-weight: 700;
      margin-bottom: 5px;
      font-size: 1.6rem;
    }
  }
	.textTemplate{
		h2.sectionTitle2{
			padding-bottom: 10px;
			padding-top: 20px;
		}
		div.basicText{
			padding-bottom: 15px;
			font-size: 16px;
			font-weight: 400;
		}
		li{
			padding-bottom: 10px;
		}
		.weight500{
			font-weight: 500;
		}
		strong{
			font-weight: 600;
		}
	}
  .headerSize1{
    font-size: 35px;
    font-weight: 600;
    line-height: 1.2em;
    padding-bottom: 20px;
    .emph{
      font-weight: 800;
    }
  }
  .headerSize2{
    font-size: 26px;
    line-height: 30px;
    margin-bottom: 15px;
    font-weight: 500;
  }
  .headerSize3{
    font-size: 20px;
    font-weight: 600;
  }
	.preHeader{
		color: $color1Dark;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
	}
	.preHeader2{
		color: $color5;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
	}
  .bodySize1, .bodySize1 p, .bodySize1 div{ 
    font-size: 16px;
    line-height: 1.5rem;
    .emph{
      font-weight: 500;
    }
  }
  .bodySize2, .bodySize2 p, .bodySize2 div{ 
    font-size: 14px;
    color: #555;
    line-height: 1.3rem;
    .emph{
      font-weight: 500;
    }
  }
	small{
		&.responsiveSmall{
	    line-height: 120%;
		  display: block;
		  padding-top: 10px;
		}
	}
	#Content{
		min-height: 100%;
	  min-height: calc(100% - 350px);
    > div > div:first-child {
      padding: 50px 0 0;
    }
	  &.noMinHeight{
	  	min-height: 0;
	  }
	  .contentCenter{
	  	> .content{
		    display: flex;
		    align-items: center;
		    height: 100%;
	  	}
	  }
	  .container{
	  	padding-top: 70px;
	  	padding-bottom: 70px;
	  	img{
	  		max-width: 100%;
	  	}
	  }
	  .containerSize2{
	  	max-width: 2000px;
	  }
	  	.backgroundColor1{
			background-color: #f8feff;
		}
		.backgroundColor2{
			background-color: #f9f9f9;
		}
		.backgroundImage1{
			background-image: url("../../../global/img/banner/banner2.jpg");
		}
		.backgroundImage2{
			background-image: url("../../../global/img/background/background2.jpg");
			background-repeat: no-repeat;
	    background-size: cover;
	    background-attachment: fixed;
		}
		.backgroundImage3{
			background-image: url("../../../global/img/background/background3.jpg");
			background-repeat: no-repeat;
	    background-size: cover;
	    background-attachment: fixed;
		}



		.backgroundSVG1{
			background-image: url("../../../global/img/background/background_SVG_1.svg");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: bottom right;
		}
		.backgroundSVG2{
			background-image: url("../../../global/img/background/background_SVG_2.svg");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: bottom left;
		}
		.backgroundSVG3{
			background-image: url("../../../global/img/background/background_SVG_3.svg");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: bottom right;
		}
		.backgroundSVG4{
			background-image: url("../../../global/img/background/background_SVG_4.svg");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: bottom left;
		}
		.backgroundSVG5{
			background-image: url("../../../global/img/background/background_SVG_5.svg");
			background-repeat: no-repeat;
			background-size: cover;
			background-position: bottom right;
		}


		.containerSection{
			border-bottom: 1px solid $color1;
		}
	}
  //@import './pages/index.scss';
	#TestimonialsList{
		.testimonial{
			padding-bottom: 40px;
			text-align: center;
			.title{
				padding: 15px 0 10px; 
				font-size: 20px;
				font-weight: 500;
		    line-height: 1.2;
				text-transform: uppercase;
			}
			.name{
				//padding-bottom: 10px;
			}
			.message{
				.quoteLeft{
					font-size: 25px;
					text-align: left;
				}
				.quoteRight{
					font-size: 25px;
					text-align: right;
				}
				.messageText{
					text-align: center;
			    	color: #555;
				}
			}
		}
	}
}




@media only screen and (min-width: 1400px) {
  #Website{
    .topTextZone{
      p,div{
        font-size: 16px;
        font-weight: 400;
        .preHeader{
          font-weight: 500;
        }
      }
    }
  }
}

@media only screen and (max-width: 1440px) {
  #Website{
    h1{
      font-size: 40px;
    }
    h2{
      font-size: 1.8rem;
    }
  }
}
@media only screen and (max-width: 1199px) {
	#Website{
	  .buttonGroup{
	  	.button{
	  		margin-top: 20px;
	  	}
	  }
    .headerSize1{
      font-size: 25px;
    }
    .headerSize2{
      font-size: 22px;
    }
	  h1{
			//font-weight: 600;
			//font-size: 40px;
	    font-size: 40px;
			&.sectionTitle{
			}
		}
		h2{
			font-size: 1.8rem;
			&.sectionTitle2{
			}
			&.limitWidth{
		    max-width: 800px;
			}
		}
		.textTemplate{
			h2.sectionTitle2{
				padding-bottom: 10px;
				padding-top: 20px;
			}
			div.basicText{
				padding-bottom: 15px;
				font-size: 15px;
				font-weight: 400;
			}
			li{
				padding-bottom: 10px;
			}
			.weight500{
				font-weight: 500;
			}
			strong{
				font-weight: 600;
			}
		}
		.preHeader{
			color: $color1Dark;
	    font-size: 16px;
		}
		.preHeader2{
	    font-size: 14px;
		}
		small{
			&.responsiveSmall{
		    line-height: 120%;
			  display: block;
			  padding-top: 10px;
			}
		}
		#Content{
			min-height: 100%;
		  min-height: calc(100% - 350px);
      > div > div:first-child {
        padding: 50px 0 0;
      }
		  &.noMinHeight{
		  	min-height: 0;
		  }
		  .contentCenter{
		  	> .content{
			    display: flex;
			    align-items: center;
			    height: 100%;
		  	}
		  }
		  .container{
		  	padding-top: 70px;
		  	padding-bottom: 70px;
		  	img{
		  		max-width: 100%;
		  	}
		  }
		  .containerSize2{
		  	max-width: 2000px;
		  }
		}
		#TestimonialsList{
			.testimonial{
				padding-bottom: 40px;
				text-align: center;
				.title{
					padding: 15px 0 10px; 
					font-size: 20px;
					font-weight: 500;
			    line-height: 1.2;
					text-transform: uppercase;
				}
				.name{
					//padding-bottom: 10px;
				}
				.message{
					.quoteLeft{
						font-size: 25px;
						text-align: left;
					}
					.quoteRight{
						font-size: 25px;
						text-align: right;
					}
					.messageText{
						text-align: center;
				    color: #555;
					}
				}
			}
		}
	}
}


@media only screen and (max-width: 800px) {
	#Website{
    .bodySize1, .bodySize1 p, .bodySize1 div{ 
      font-size: 14px;
      line-height: 1.4rem;
      .emph{
        font-weight: 500;
      }
    }
    .bodySize2, .bodySize2 p, .bodySize2 div{ 
      font-size: 12px;
      color: #555;
      line-height: 1.2rem;
      .emph{
        font-weight: 500;
      }
    }
    .col, .col-12 {
      padding-left: 30px;
      padding-right: 30px;
    }
    .topTextZone{
      padding-top: 20px;
      text-align: center;
      ul,ol{
        text-align: left;
        display: inline-block;
      }
    }
  	.button{
      font-size: 12px;
      min-width: 115px;
      padding: 0;
      align-items: center;
      justify-content: center;
      display: flex;
      height: 35px;
  		margin-top: 20px;
  		margin-left: 5px;
  		margin-right: 5px;
  	}
	  h1{
			//font-weight: 600;
			//font-size: 40px;
	    font-size: 26px !important;
			*, &.sectionTitle{
        font-size: 26px !important;
			}
		}
		h2{
			font-size: 1.3rem;
			&.sectionTitle2{
			}
			&.limitWidth{
		    max-width: 800px;
			}
		}
		.textTemplate{
			.sectionTitle2{
        font-size: 25px;
				padding-bottom: 10px;
				padding-top: 20px;
			}
			div.basicText{
				padding-bottom: 15px;
				font-size: 15px;
				font-weight: 400;
			}
			li{
				padding-bottom: 10px;
			}
			.weight500{
				font-weight: 500;
			}
			strong{
				font-weight: 600;
			}
		}
		.preHeader{
			color: $color1Dark;
	    font-size: 16px;
		}
		.preHeader2{
	    font-size: 14px;
		}
		small{
			&.responsiveSmall{
		    line-height: 120%;
			  display: block;
			  padding-top: 10px;
			}
		}
		#Content{
			min-height: 100%;
		  min-height: calc(100% - 350px);
      > div > div:first-child {
        padding: 30px 0 0;
      }
		  &.noMinHeight{
		  	min-height: 0;
		  }
		  .contentCenter{
		  	> .content{
			    display: flex;
			    align-items: center;
			    height: 100%;
		  	}
		  }
		  .container{
		  	padding-top: 70px;
		  	padding-bottom: 70px;
		  	img{
		  		max-width: 100%;
		  	}
		  }
		  .containerSize2{
		  	max-width: 2000px;
		  }
		}
		#TestimonialsList{
			.testimonial{
				padding-bottom: 40px;
				text-align: center;
				.title{
					padding: 15px 0 10px; 
					font-size: 20px;
					font-weight: 500;
			    line-height: 1.2;
					text-transform: uppercase;
				}
				.name{
					//padding-bottom: 10px;
				}
				.message{
					.quoteLeft{
						font-size: 25px;
						text-align: left;
					}
					.quoteRight{
						font-size: 25px;
						text-align: right;
					}
					.messageText{
						text-align: center;
				    color: #555;
					}
				}
			}
		}
	}
}


// @media only screen and (max-width: 480px) {


// }
