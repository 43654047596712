@import './Accordion.scss';
@import './AreYouSurePopup.scss';
@import './Buttons.scss';
// @import './CandidateQuiz.scss';
@import './Cart.scss';
@import './CheckoutPage.scss';
@import './ContactDialog.scss';
@import './ContactForm.scss';
@import './CustomToaster.scss';
@import './DialogWrapper.scss';
@import './EditNotes.scss';
@import './EditableImage.scss';
@import './FileDropZone.scss';
@import './FormBuilder.scss';
@import './FormPreview.scss';
@import './LanguageToggle.scss';
@import './Logo.scss';
@import './NotesList.scss';
@import './SlideInRight.scss';
// @import './TreatmentPreviewSlider.scss';
@import './Wyziwyg.scss';
@import './colors.scss';








// html{
//   scroll-behavior: unset;
//   * {
//     scroll-behavior: unset;
//   }
//   &.smoothScroll{
//     scroll-behavior: smooth;
//     * {
//       scroll-behavior: smooth;
//     }
//   }
// // }
// #Botsonic-wrapper {
// //   // max-height: 600px !important;
// left: 0;
// right: unset !important;
// //   // div#Botsonic-wrapper {
// //     max-width: 470px !important;
// //     max-height: 700px !important;
//   z-index: 5 !important;
//   //max-height: calc(100% - 60px)!important;
// }

* {
  //scroll-behavior: smooth;
  font-family: 'Montserrat', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  margin-bottom: 20px; }

h1, h2.largest {
  font-size: 50px; }

p {
  line-height: 1.8rem; }

.hidden {
  display: none;
}
.disabled{
  opacity: .4;
  pointer-events: none;
}
html, body{ 
  min-height: 100%;
  height: 100%;
}
div, p{
  font-size: 15px;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 1.6;
  font-weight: 400;
  font-size: 16px;
}

.phoneNumber{
  text-decoration: none;
  color: #000;
  &:hover{
    color: $color5;
  }
}
.inline-list{
  display: block;
  > div{
    display: inline-block;
  }
  > li {
    display: inline-block;
  }
}

.flex-between{
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.flex-1{
  flex: 1;
}

.row{
  .col{
    &.matchHeight{
      .content, .fullHeight{
        height: 100%;
      }
      padding-bottom: 30px;
    }
  }
}



#Root{
  height: 100%;
  #SmilesClub{
    height: 100%;
  }
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-control{
  &.error{
    border-color: $color5;
  }
}
.formTitle{
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  .orange-text{
    color: $color5;
    &.requiredStar{
      font-size: 8px;
      padding-left: 10px;
    }
  }

}
.light{
  opacity: .5;
}



.blinking{
  animation: blinkingText .8s infinite;
}
@keyframes blinkingText{
    0%{     opacity: 1;    }
    49%{    opacity: 1; }
    60%{    opacity: 0; }
    99%{    opacity: 0;  }
    100%{   opacity: 1;    }
}




#OrderPreview{
  padding: 30px 20px;
  .sectionHeader{
    font-weight: 700;
    font-size: 18px;
    text-transform: uppercase;
  }
  .orderInfo{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .infoList{
      padding: 15px 5px 10px;
      width: 300px;
      //display: flex;
      .infoName{
        font-weight: 500;
      }
      .infoValue{
        font-size: 12px;
      }
    }
  }
  .orderProducts{
    thead{
      tr{
        th{
          //text-align: center;
          &:last-child{
            text-align: right;
          }
          &:first-child{
            text-align: left;
          }
        }
      }
    }
    tbody{
      tr{
        th{
          align-items: center;
          //text-align: center;
          justify-content: center;
          font-weight: 500;
          font-size: 12px;
          &:first-child{
            text-align: left;
          }
          &:last-child{
            text-align: right;
          }
        }
      }
    }
  }
  .reciptZone{
    margin: 0 0 0 auto;
    width: 500px;
    max-width: 100%;
    .recipt{
      .reciptItem{
        display: flex;
        border-top: 1px solid #eee;
        padding-top: 5px;
        margin-top: 5px;
        &.total{
          border-top: 4px solid #333;
          padding-top: 10px;
          margin-top: 10px;
          div {
            font-size: 20px;
            font-weight: 600;
          }
        }
        .name{
          font-weight: 500;
          flex: 1;
        }
        .value{

        }
      }
    }
  }
}


#BasicDialog{
  background-color: #FFF;
  h1{
    font-size: 30px;
  }
}

.basic-dialog{
  border: 1px solid #333;
  background: #FFF;
  border-radius: 10px;
  overflow: hidden;
  &.friendlyContactForm{
    border: 1px solid $color1Dark;
    .header{
      background-color: $color1;
      font-weight: 600;
      font-size: 20px;
    }
  }
  .header{
    position: relative;
    background-color: #000;
    color: #FFF;
    padding: 10px;
    font-weight: 500;
    display: flex;
    .headerText{
      flex: 1;
    }
    i{
      padding: 5px;
      cursor: pointer;
    }
  }
  .dialog-body{
    padding: 20px;
    overflow: auto;
    .dialogMessageTitle{
      font-size: 28px;
      font-weight: 500;
      padding-bottom: 10px;
      line-height: 1.2;
    }
    .dialogMessageText{
      font-weight: 400;
      font-size: 20px;
    }
  }
  .footer{
    padding: 10px;
    border-top: 1px solid #ddd;
    button{
      margin: 0 5px 0 auto;
    }
  }
}


#ResetPassword{
  .icon{
    text-align: center;
    padding: 20px 0;
    font-size: 100px;
    color: $color1;
  }
  .text{
    text-align: center;
  }
  .finishedMessage{
    text-align: center;
    .title{
      font-size: 22px;
      padding-bottom: 10px;
      font-weight: 600;
    }
    .text{

    }
    .last{
      padding-top: 5px;
      font-size: 16px;
    }
  }
  button.button.button1{
    width: 100%;
  }
}

#LoadingScreen{
  flex: 1;
  position: relative;
  height: 100%;
  &.floating{
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    .loadingScreen{
      position: absolute;
      left: 0;
      right: 0;
      top: 50%;
      transform: translate(0,-50%);
    }
  }
  .overlay{
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    background-color: rgba(255,255,255,.5);
  }
  .loadingScreen{
    text-align: center;
    position: relative;
    z-index: 2;
    .loadingIcon{
      max-width: 150px;
      margin: auto;
      img{
        width: 100%;
        -webkit-animation-name: spin;
        -webkit-animation-duration: 4000ms;
        -webkit-animation-iteration-count: infinite;
        -webkit-animation-timing-function: linear;
        -moz-animation-name: spin;
        -moz-animation-duration: 4000ms;
        -moz-animation-iteration-count: infinite;
        -moz-animation-timing-function: linear;
        -ms-animation-name: spin;
        -ms-animation-duration: 4000ms;
        -ms-animation-iteration-count: infinite;
        -ms-animation-timing-function: linear;
        animation: spin 4000ms linear infinite, colorFlow 8000ms alternate infinite;
        font-size: 100px;
      }
    }
  }
}

.loginForm{
  .resetPWButton{
    padding: 20px 0;
  }
}

.product{
  &.productLayout2{
    display: flex;
    .productImages{
      padding: 0 40px 40px 0;
      max-width: 450px;
      .productImage{
        padding: 0 0 10px 0;
        img{
          max-width: 100%;
        }
      }
      .smollImages{
        .smollImage{
          max-width: 25%;
          img{
            max-width: 100%;
          }
        }
      }
    }
    .productText{
      padding-bottom: 40px;
      flex: 1;
      .productTextName{
        font-weight: 500;
        font-size: 24px;
      }
      .productTextDesc{
        padding-bottom: 15px;
      }
      .productTextCost{
        font-size: 20px;
        font-weight: 500;
        small{
          padding-left: 10px;
          text-decoration: line-through;
        }
      }
      .productTextSave{
        font-size: 18px;
        padding-bottom: 15px;
      }
      .buttonGroup{
      }
    }
  }
}

.SortableItemContainer{
  background: #f3f3f3;
  padding: 10px; 
  min-height: 50px;
  border: 1px solid #333;
  border-radius: 4px;
  overflow: auto;
}
.SortableItem{
  &.blue-background-class{
    background: $color1Light;
  }
  font-weight: 500;
  border: 1px solid $color1;
  border-radius: 4px;
  background-color: #FFF;
  cursor: move;
  margin-bottom: 5px;
  padding: 10px;
}



.iconSpin{
  color: $color1;
  -webkit-animation-name: spin;
  -webkit-animation-duration: 1500ms;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  -moz-animation-name: spin;
  -moz-animation-duration: 1500ms;
  -moz-animation-iteration-count: infinite;
  -moz-animation-timing-function: linear;
  -ms-animation-name: spin;
  -ms-animation-duration: 1500ms;
  -ms-animation-iteration-count: infinite;
  -ms-animation-timing-function: linear;
  animation: spin 1500ms linear infinite, colorFlow 8000ms alternate infinite;
}
@-ms-keyframes spin {
  from {
    -ms-transform: rotate(0deg); }
  to {
    -ms-transform: rotate(360deg); } }

@-moz-keyframes spin {
  from {
    -moz-transform: rotate(0deg); }
  to {
    -moz-transform: rotate(360deg); } }

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(360deg); } }




.color1{
  color: $color1;
}
.color1Dark{
  color: $color1Dark;
}
.color1Light{
  color: $color1Light;
}
.color2{
  color: $color2;
}
.color2Dark{
  color: $color2Dark;
}
.color2Light{
  color: $color2Light;
}
.color3{
  color: $color3;
}
.color3Dark{
  color: $color3Dark;
}
.color3Light{
  color: $color3Light;
}
.color4{
  color: $color4;
}
.color4Dark{
  color: $color4Dark;
}
.color4Light{
  color: $color4Light;
}
.color5{
  color: $color5;
}
.color5Dark{
  color: $color5Dark;
}
.color5Light{
  color: $color5Light;
}
.color6{
  color: $color6;
}
.color6Dark{
  color: $color6Dark;
}
.color6Light{
  color: $color6Light;
}


.backgroundColor1{
  background-color: $color1;
}
.backgroundColor1Dark{
  background-color: $color1Dark;
}
.backgroundColor1Light{
  background-color: $color1Light;
}
.backgroundColor2{
  background-color: $color2;
}
.backgroundColor2Dark{
  background-color: $color2Dark;
}
.backgroundColor2Light{
  background-color: $color2Light;
}
.backgroundColor3{
  background-color: $color3;
}
.backgroundColor3Dark{
  background-color: $color3Dark;
}
.backgroundColor3Light{
  background-color: $color3Light;
}
.backgroundColor4{
  background-color: $color4;
}
.backgroundColor4Dark{
  background-color: $color4Dark;
}
.backgroundColor4Light{
  background-color: $color4Light;
}
.backgroundColor5{
  background-color: $color5;
}
.backgroundColor5Dark{
  background-color: $color5Dark;
}
.backgroundColor5Light{
  background-color: $color5Light;
}
.backgroundColor6{
  background-color: $color6;
}
.backgroundColor6Dark{
  background-color: $color6Dark;
}
.backgroundColor6Light{
  background-color: $color6Light;
}

.backgroundColor7{
  background-color: $color7;
}
.backgroundColor7Dark{
  background-color: $color7Dark;
}
.backgroundColor7Light{
  background-color: $color7Light;
}







@media only screen and (max-width: 1450px){
  h1, h2.largest {
    font-size: 40px; 
  }
  h2{
    font-size: 1.8rem;
  }
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 480px) {
}


