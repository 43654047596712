@import '../../global/colors.scss';
#Website{
    #PageContact{
        .infoItems{
            .infoItem{
                padding-top: 20px;
                .iconTitle{
                    h4{
                        margin-bottom: 5px;
                        font-size: 17px;
                        font-weight: 600;
                        i{
    
                        }
                        .title{
                            padding-left: 8px;
                        }
                    }
                }
                .infoContent{
                    
                }
            }
        }
    }
}