@import '../../global/colors.scss';
#Emails{
	.emailList{
    .emailObjWrapper{
      border-top: 1px solid #ddd;
      padding-top: 10px;
      margin-top: 10px;
      &:hover{
        .emailObj{
          border-color: $color1;
        }
      }
      .emailObj{
        border-radius: 5px;
        padding: 10px;
        display: flex;
        border: 1px solid transparent;
        align-items: center;
        .emailText{
          flex: 1;
          .emailName{
            font-size: 18px;
            font-weight: 600;
          }
          .emailDesc{
          }
        }
        .emailButton{
          button.button.button1{
            margin: 0;
          }
        }
      }
    }
  }
}
