@import '../../global/colors.scss';
#SlideInRightContent.newOrderDialog.frontEndSlideInRight{
  #Body{
    // height: 100%;
    
    // display: flex;
    padding: 20px 30px;
    .dialogCustomZone{
      flex: 1;
      display: flex;
      flex-direction: column;
      height: 100%;
      //overflow: auto;
      hr{
        border-bottom: 2px solid $color5;
        margin: 25px 0 35px;
        opacity: 1;
      }
      .previewForm{
        .previewFormTitle{
          font-size: 24px;
          font-weight: 600;
        }
        .previewFormSubTitle{
          font-size: 16px;
          color: #545454;
          padding-bottom: 10px;
          margin-bottom: 30px;
          border-bottom: 4px solid $color1;
        }
      }
      .footer{
        padding-right: 10px;
        .buttonGroup{
          justify-content: flex-end;
        }
      }
      form.FormBuilder .form-group{
      }
      .editPatient{
        .form-group{
          padding: 0 10px;
        }
      }
      #LabViewTreatmentPlanRequest{
        padding-top: 0px;
        .sectionValueWrapper{
          min-width: 300px;
          .sectionValue{
            
            //position: relative;
            // &:after{
            //  border-top: 1px solid #eaeaea;
            //  content: " ";
            //  width: 50%;
            //  margin: auto;
            //  position: absolute;
            //  left: 0;
            //  right: 0;
            //  top: 0px;
            // }
            border-top: 1px solid $borderColor;
            margin-top: 8px;
            padding-top: 8px;
            display: flex;
            //align-items: center;
            flex-wrap: wrap;
            //justify-content: center;
            .section{
              font-weight: 600;
              text-align: left;
              min-width: 150px;
              max-width: 150px;
              padding-right: 20px;
              font-size: 13px;
            }
            .value{
              flex: 1;
              font-size: 13px;
            }
          }
        }
        .sectionDivider {
          .sectionDividerBasic {
            padding: 0 0 15px;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 20px;
            display: flex;
            align-items: center;
            .sectionDividerBorder {
              flex: 1 1;
              border-top: 4px solid $color4;
              height: 4px;
              display: flex;
              align-items: center;
              font-size: 0px;
              margin-left: 30px;
            }
          }
        }
        .currentInfoZones{
          padding: 0 20px;
          .currentInfoZone{
            border-bottom: 2px solid $color4;
            padding-bottom: 40px;
            margin-bottom: 40px;
            a.button.downloadButton{
              width: 100%;
              min-width: 0px;
              max-width: 100%;
              font-size: 12px;
              text-align: center;
              padding: 0;
              height: 30px;
              display: flex;
              align-items: center;
              justify-content: center;;
            }
            .downloadAll{
              a.button.downloadButton{
                font-size: 14px;
                height: 40px;
                text-transform: uppercase;
              } 
            }
            .sectionTitle{
              font-weight: 500;
              font-size: 16px;
            }
            .initalPictures{
              display: flex;
              flex-wrap: wrap;
              margin: 0 -15px;
              .initalPicture{
                width: 100%;
                max-width: 140px;
                padding: 15px;
                img{
                  width: 100%;
                  max-width: 100%;
                  &:hover{
                    cursor: pointer;
                    opacity: .8;
                  }
                }
              }
            }
            .mouthScanFiles{
              .downloadFile{
                display: flex;
                align-items: center;
                justify-content: center;
                border-bottom: 1px solid #ddd;
                padding-top: 5px;
                padding-bottom: 5px;
                margin-bottom: 5px;
                &:hover{
                  background-color: #eee;
                }
                .fileName{
                  flex: 1;
                  font-size: 12px;
                }
                a.button.downloadButton{
                  width: 100px;
                  height: 30px;
                  font-size: 10px;
                  margin: 0;
                }
              }
            }
            .notes{
              padding: 15px 10px;
              background-color: #f9f9f9;
              margin-top: 10px;
              font-size: 14px;
            }
          }
        }
      }
    }
  }
}