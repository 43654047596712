@import '../../global/colors.scss';
#Settings{
	.siteLogos{
		.content{
			height: 100%;
		}
		.siteLogo{
			h4{

			}
			.logoImage{
				height: 150px;
		    align-items: center;
		    justify-content: center;
		    display: flex;
		    border: 1px solid #eee;
		    border-radius: 10px;
		    padding: 15px;
		    margin-bottom: 40px;
		    background-color: white;
		    position: relative;
		    &.dark{
		    	background-color: black;
		    }
		    .underLay{
		    	position: absolute;
		    	left: 0;
		    	right: 0;
		    	top: 0;
		    	bottom: 0;
		    	background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
				  background-size: 20px 20px;
				  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
				  z-index: 1;
		      opacity: .2;
		    }
		    .editableImage{
		    	flex: 1;
		    	z-index: 2;
    	    position: unset;
		    }
		    .dropZone {
		    	background-color: transparent;
		    	border: none;
		    	> div.image-item{
		    		flex: 1;
						img{

						}
		    	}
		    }
			}
		}
	}
	#PartnerDoctors{
		.partnerDoctorWrapper{
			border: 1px solid #ddd;
			border-radius: 5px;
			margin-bottom: 20px;
			.partnerDoctor{
				flex: 1;
				padding: 10px 10px 10px 10px;
				.field{
					display: flex;
					padding: 0 0 5px 0;
					border-bottom: 1px solid #eee;
					margin-bottom: 5px;
					&:last-child{
						border: none;
						margin: 0;
						padding: 0;
					}
					.title{
						min-width: 100px;
						font-weight: 600;
						margin-right: 10px;
					}
					.value{
						flex: 1;
					}
				}
				.PDName{
				}
				.PDEmail{

				}
				.PDCompany{

				}
			}
			i{
				font-size: 20px;
		    display: flex;
		    align-items: center;
		    width: 50px;
		    justify-content: center;
		    height: 50px;
		    cursor: pointer;
				&:hover{
					color: $color5;
				}
			}
		}

	}
	#ANDButtonWrapper{
		margin-bottom: 40px;
	}
	#AddNewDoctorWrapper{
		> .content{
			border: 2px solid $color1;
			padding: 20px 30px 30px;
			border-radius: 5px;
		}
		.ANDHeader{
			padding-bottom: 20px;
			.ANDText{
				flex: 1;
				font-size: 22px;
				font-weight: 600;
				text-transform: uppercase;
			}
			.ANDClose{
				cursor: pointer;
				&:hover{
					color: $color5;
				}
				i{
					font-size: 25px;
				}		
			}
		}
		.ANDFormWrapper{

		}
	}
}