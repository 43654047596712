@import '../../global/colors.scss';
#UserProfile{
	.pictureProgressZone{
		.pictures{
      margin: 0 -20px;
      display: flex;
      flex-wrap: wrap;
			.picture{
				padding: 0 20px 20px;
        flex: 1;
        width: 100%;
        max-width: 250px;
        .img, .editableImage{
          width: 200px;
          height: 200px;
          max-width: 100%;
          overflow: hidden;
          position: relative;
          border: 1px solid #eee;
          background: #f9f9f9;
          img{
            max-width: none;
            height: 100%;
            max-height: 100%;
            margin: auto;
            position: absolute;
            left: 50%;
            right: 0;
            top: 0;
            transform: translate(-50%, 0px);
          }
        }
				.date{
          padding-top: 5px;
					text-align: center;
          font-size: 12px;
				}
			}
		}
	}
	.newPicture{
		display: flex;
    align-items: center;
    justify-content: center;
    background: #ddd;
    height: 100%;
    font-weight: 600;
    cursor: pointer;
    &:hover{
    	background-color: $color1;
    	color: #fff;
    }
    .newImageIcon{
      font-size: 50px;
      text-align: center;
      margin-bottom: -15px;
    }
    .newImageText{
	    font-size: 15px !important;
	    text-align: center;
	    max-width: 130px;
    }
	}
}