@import './colors.scss';
.wizyWig{
	height: 100%;
	.rsw-editor {
		height: 100%;
	}
}


@media only screen and (max-width: 800px) and (min-width: 576px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 480px) {
}