@import '../../global/colors.scss';
#TemplateHeader{
	padding: 20px 0 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  #SearchBox{
    margin-bottom: 10px;
    .SearchBoxForm{
      border: 2px solid $color4;
      border-radius: 5px;
      margin: 0;
      display: flex;
      //width: 360px;
      .searchArg{
        border: none;
        font-size: 12px;
        padding: 5px 12px;
        outline: none;
      }
      .searchQuery{
        border: none;
        border-left: 2px solid $color4;
        padding: 5px 5px 5px 10px;
        margin: 0;
        outline: none;
        flex: 1;
        //max-width: 200px;
      }
      .searchButton{
        border: none;
        padding: 5px;
        background-color: $color4;
        width: 40px;
        height: 35px;
        margin: 0;
        i{
          color: #FFF;
          font-size: 12px;
        }
      }
    }
  }
	.textZone{
		.textArea{
			.title{
				font-size: 25px;
				font-weight: 500;
			}
			.found{
				&.highlight{
					.totalNumber{
						color: $color1;
						font-weight: 600;
					}
				}
				.totalNumber{
					padding: 3px 6px;
			    color: #333;
			    transition: all .5s ease;
				}
			}
		}
	}



	.buttonZone{
		> div{
			height: 100%;
		}
		.buttonGroupWrapper{
			padding: 0 5px;
			.buttonGroup{
				align-items: center;
	  		justify-content: flex-end;
        flex-wrap: wrap;
	  		.button{
					margin: 0 0 10px 10px;
			    padding: 8px;
			    font-size: 13px;
			    min-width: 120px;
			    max-width: 120px;
			    i{
			    	padding-left: 5px;
			    }
          &.deleteButton{
            min-width: 40px;
            background-color: $color5;
            text-align: center;
            i{
              padding: 0;
            }
            &:hover{
              background-color: $color5Dark;
            }
          }
          &.cloneButton{
            min-width: 40px;
            background-color: $color3;
            text-align: center;
            i{
              padding: 0;
            }
            &:hover{
              background-color: $color3Dark;
            }
          }
	  		}
        .editButton{
          min-width: 40px;
          background-color: rgb(52,58,64);
          &:hover{
            background-color: darken(rgb(52,58,64), 10%);
          }
        }
			}
		}
	}
}




@media only screen and (max-width: 1000px){
  #TemplateHeader{
    .textZone{
      min-width: 100%;
      .textArea{
        .title{
          font-size: 25px;
          font-weight: 500;
        }
        .found{
          &.highlight{
            .totalNumber{
              color: $color1;
              font-weight: 600;
            }
          }
          .totalNumber{
            padding: 3px 6px;
            color: #333;
            transition: all .5s ease;
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 500px){
  #TemplateHeader{
    #SearchBox{
      margin-bottom: 10px;
      width: 100%;
      .SearchBoxForm{
        border: 2px solid $color4;
        margin: 0;
        margin-top: 10px;
        border-radius: 5px;
        display: flex;
        width: 100%;
        flex-wrap: wrap;
        .searchArg{
          border: none;
          font-size: 12px;
          padding: 5px 12px;
          outline: none;
          min-width: 100%;
          border-bottom: 2px solid $color4;
          border-radius: 5px 5px 0 0;
        }
        .searchQuery{
          border: none;
          padding: 5px 5px 5px 10px;
          border-radius: 5px 0 0 5px;
          margin: 0;
          min-width: 0;
        }
        .searchButton{
          border: none;
          padding: 5px;
          background-color: $color4;
          width: 40px;
          min-width: 40px;
          height: 35px;
          i{
            color: #FFF;
            font-size: 12px;
          }
        }
      }
    }
    .buttonZone{
      > div{
        height: 100%;
      }
      .buttonGroupWrapper{
        padding: 0 5px;
        .buttonGroup{
          align-items: center;
          justify-content: flex-end;
          flex-wrap: wrap;
          .button{
            margin: 0 0 10px 10px;
            padding: 6px 10px;
            font-size: 11px;
            min-width: 100px;
            max-width: 100px;
            i{
              padding-left: 5px;
            }
            &.deleteButton{
              min-width: 40px;
              background-color: $color5;
              text-align: center;
              i{
                padding: 0;
              }
              &:hover{
                background-color: $color5Dark;
              }
            }
            &.cloneButton{
              min-width: 40px;
              background-color: $color3;
              text-align: center;
              i{
                padding: 0;
              }
              &:hover{
                background-color: $color3Dark;
              }
            }
          }
          .editButton{
            min-width: 40px;
            background-color: rgb(52,58,64);
            &:hover{
              background-color: darken(rgb(52,58,64), 10%);
            }
          }
        }
      }
    }
  }
}