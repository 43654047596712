@import '../../global/colors.scss';
#UserHome{
  .status{
    background: #f5f5f5;
    padding: 15px 20px;
    min-height: 220px;
    .statusText{
      .statusTextContainer{
        display: flex;
        border-bottom: 2px solid $color1;
        padding: 15px 0;
        margin-bottom: 10px;
        .statusIcon{
          display: flex;
          align-items: center;
          justify-content: center;
          i{
            font-size: 50px;
          }
        }
        .statusText{
          padding-left: 20px;
          flex: 1;
          .statusTextTitle{
            font-size: 18px;
            margin-bottom: -5px;
          }
          .statusTextStatus{
            font-size: 22px;
            font-weight: 700;
            color: $color1;
            text-transform: uppercase;
          }
        }
      }
      .statusTextDesc{
        padding-top: 10px;
      }
    }
  }
  #RequestPlan{
    .initialPictures{
      display: flex;
      flex-wrap: wrap;
      margin: 0 -30px;
      .formMouthPicture{
        flex: 1;
        width: 100%;
        max-width: 400px;
        min-width: 300px;
        padding: 20px 30px 40px;
        .formTitle div{
          font-size: 18px;
        }
        .formSubTitle{
          min-height: 75px;
        }
        .imageUpload{
          .editableImage{
            *{
              max-height: 100%;
            }
            .dropZone{
              border: 2px dashed #c4c4c4;
            }
            .placeholderText{
              position: absolute;
              bottom: 0;
              right: 0;
              left: 0;
              background: $color1; //rgba(255,255,255,.7)
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 20px;
              text-transform: uppercase;
              font-weight: 700;
              pointer-events: none;
            }
          }
          &:hover{
            opacity: .9;
            .dropZone{
              border: 2px dashed $color1;
            }
          }
        }
      }
    }
  }

  #CurAlignerColumn > .content{
    display: flex;
    flex-direction: column;
    height: calc( 100% - 30px );
    #CurAlignerComponent1{
      border: 2px solid $color1;
      margin-bottom: 30px;
    }
    #CurAlignerComponent2{
      flex: 1;
      margin-bottom: 30px;
    }
  }
  #AlignerScheduleComponent{
    height: 100%;
    .calendarIcon{
      font-size: 35px;
      padding-right: 20px;
    }
  }
  .alignerScheduleZone{
    position: relative;
    .curAlignerWrapper{
      .curAligner{
        display: flex;
        align-items: center;
        justify-items: center;
        .curAlignerNum{
          max-width: 80px;
          text-align: center;
          flex: 1;
          margin-left: -10px;
          border-right: 1px solid #ddd;
          padding-right: 20px;
          .step{
            font-size: 20px;
            text-transform: uppercase;
            font-weight: 500;
          }
          .stepNum{
            font-size: 38px;
            line-height: 38px;
            height: 38px;
            font-weight: 600;
          }
        }
        .curAlignerDescWrapper{
          padding-left: 20px;
          flex: 1;
          display: flex;
          align-items: center;
          .curAlignerText{
            flex: 1;
            .curAlignerDesc{
              font-size: 20px;
              font-weight: 500;
            }
            .curAlignerDate{
              
            }
          }
        }
      }
    }
    .alignerScheduleNext{
      .noMoreAligners{
        font-size: 18px;
        font-weight: 600;
        color: $color1;
      }
      .countdown{
        .countdownCards{
          display: flex;
          width: 260px;
          margin: auto;
          .cardZone{
            //flex: 1;
            width: 60px;
            &.redBackground{
              .card{
                transition: all 0s ease;
                background-color: $color5;
                .number{
                  color: #FFF;
                }
                .line{
                  background-color: rgba(255,255,255,.3);
                }
              } 
            }
            .card{
              transition: all .5s ease;
              border-radius: 4px;
              border: 1px solid #ddd;
              background-color: #FFF;
              display: flex;
              align-items: center;
              justify-content: center;
              position: relative;
              width: 50px;
              height: 50px;
              margin: auto;
              .number{
                color: #000;
                font-weight: 600;
                font-size: 20px;
              }
              .line{
                height: 1px;
                background-color: rgba(0,0,0,.08);
                width: 100%;
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
              }
            }
            .title{
              padding-top: 10px;
              text-align: center;
              text-transform: uppercase;
              font-size: 14px;
              color: #444;
              font-weight: 400;
            }
          }
        }
      }
    }
    .startTreatment{
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #03a9f487;
      left: 0;
      right: 0;
      bottom: 0;
      top: -15px;
      border-radius: 15px;
      z-index: 1;
      &.preStart{
        background-color: #ffffffb0;
        .startTreatmentContainer{
          border: 4px solid $color1;
          padding: 50px 30px;
          box-shadow: 5px 5px 17px #0000005c;
        }
      }
      .fas{
        font-size: 50px;
        padding-bottom: 10px;
      }
      .startTreatmentContainer{
        background-color: #FFF;
        text-align: center;
        padding: 30px;
        border-radius: 10px;
        border: 3px solid $color5;
        .startTreatmentHeader{
          font-size: 24px;
          text-transform: uppercase;
          font-weight: 600;
          margin-bottom: 0;
        }
        .startTreatmentTitle{
          margin-bottom: 0;
        }
        button.button.button1{
          font-weight: 700;
          font-size: 14px;
        }
      }
    }
    .alignerSchedule{
      overflow: scroll;
      height: 250px;
      border: 1px solid #ddd;
      border-radius: 10px;
      padding: 15px;
      .pastTitle{
        color: $lightText;
        padding: 0 10px 10px 10px;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 14px;
      }
      .eventWrapper{
        padding-bottom: 10px;
        &.done .event{
          opacity: .4;
        }
        &.next .event{
          border: 2px solid $color5;
        }
        .event{
          border-radius: 10px;
          border: 2px solid $color1;
          display: flex;
          .eventDateWrapper{
            min-width: 70px;
            min-height: 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            .eventDate{
              border-right: 1px solid #EEE;
              text-align: center;
              flex: 1;
              .eventDateMonth{
                text-transform: uppercase;
                height: 20px;
                line-height: 20px;
                font-size: 13px;
                font-weight: 500;
              }
              .eventDateNumber{
                font-size: 20px;
                font-weight: 600;
                height: 20px;
                line-height: 20px;
              }
            }
          }
          .eventText{
            flex: 1 1;
            display: flex;
            align-items: center;
            padding: 0 14px;
            .eventTextTitle{
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1392px){
  #UserHome #CurAlignerColumn > .content {
    height: calc( 100% - 15px );
    #CurAlignerComponent2 {
      margin-bottom: 0px;
    }
  }
}

@media only screen and (max-width: 1391px){
  #UserHome .alignerScheduleColumn > .content {
    height: calc( 100% - 30px );
  }
}

@media only screen and (max-width: 1280px){
  #UserHome{
    .container-fluid  .col{
      flex: 1;
    }
    .alignerScheduleColumn{
      min-width: 100%;
      flex: 1;
    }
  }
}
