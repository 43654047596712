@import './colors.scss';
.googleButton{
  width: 100%;
  background-color: #FFF;
  color: #de5246; //#4285F4;
  cursor: pointer;
  display: flex;
  height: 46px;
  border: 1px solid #de5246; //#4285F4;
  align-items: center;
  justify-content: center;
  margin: 0 0 20px 0;
  border-radius: 4px;
  padding: 2px;
  position: relative;
  overflow: hidden;
  .icon{
    width: 45px;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    margin: auto;
    height: 100%;
    img{
      max-width: 100%;
    }
  }
  .text{
    flex: 1;
    text-align: center;
    font-weight: 600 !important;
  }
  .trueButton{
    position: absolute;
    left: 0;
    //right: 0;
    top: 0;
    bottom: 0;
    z-index: 12;
    opacity: .01;
    width: 100%;
  }
  iframe{
    // position: absolute;
    // left: 0;
    // right: 0;
    // top: 0;
    // bottom: 0;
    transform: scaleX(7);
  }
  &:hover{
    background-color: #de5246; //#4285F4;
    color: #FFF;
  }
}
.facebookButton{
  width: 100%;
  background-color: #FFF;
  color:  #1877F2; //#4267B2;
  cursor: pointer;
  display: flex;
  height: 46px;
  border: 1px solid  #1877F2; //#4267B2;
  align-items: center;
  justify-content: center;
  margin: 0 0 20px 0;
  border-radius: 4px;
  padding: 2px;
  .icon{
    width: 45px;
    background: #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    height: 100%;
    overflow: hidden;
    //border: 1px solid #FFF;
    img{
      width: 28px;
      height: 28px;
      border-radius: 2px;
      overflow: hidden;
    }
  }
  .text{
    flex: 1;
    text-align: center;
    font-weight: 600 !important;
  }
  &:hover{
    background-color:  #1877F2; //#4267B2;
    color: #FFF;
  }
}
.button{
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 8px 18px;
  line-height: 1.5;
  border-radius: .25rem;
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  color: #fff;
  text-decoration: none!important;
  margin-top: 15px;
  outline: none!important;
  min-width: 130px;
  cursor: pointer;
  font-weight: 600;
  font-size: 13px;
  &.buttonSmall{
    padding: 6px 0;
    max-width: 120px;
    min-width: 120px;
    margin: 0;
    flex: 1 1;
  }
}
.buttonFull{
  width: 100%;
}
.buttonGroup{
  display: flex;
  margin-left: -5px;
  margin-right: -5px;
  flex-wrap: wrap;
  &.split{
    justify-content: space-between;
  }
  &.center{
    align-items: center;
    justify-content: center;
  }
  &.right{
    align-items: center;
    justify-content: flex-end;
  }
  button{
    margin: 0 5px 20px;
  }
}

.button1{
  background-color: $buttonBlue;
  color: #FFF;
  &:hover{
    background-color: $buttonBlueDark;
    color: #FFF;
  }
  &.btn-color0{
    background-color: #444;
    border-color: #444;
    color: #fff;
    &:hover{
      background-color: #000;
    }
  }
}

.button2{
  background-color: #FFF;
  color: $buttonBlue;
  border: 2px solid $buttonBlue;
  &:hover{
    background-color: $buttonBlueDark;
    color: #FFF;
  }
  &.btn-color0{
    background-color: #FFF;
    color: #000;
    border-color: #000;
    &:hover{
      background-color: #000;
      border-color: #000;
      color: #fff;
    }
  }
  &.btn-color1{
    background-color: #FFF;
    color: $color1;
    border-color: $color1;
    &:hover{
      background-color: $color1Dark;
      border-color: $color1Dark;
      color: #fff;
    }
  }
  &.btn-color2{
    background-color: #FFF;
    color: $color2;
    border-color: $color2;
    &:hover{
      background-color: $color2Dark;
      border-color: $color2Dark;
      color: #fff;
    }
  }
  &.btn-color3{
    background-color: #FFF;
    color: $color3;
    border-color: $color3;
    &:hover{
      background-color: $color3Dark;
      border-color: $color3Dark;
      color: #fff;
    }
  }
  &.btn-color4{
    background-color: #FFF;
    color: $color4;
    border-color: $color4;
    &:hover{
      background-color: $color4Dark;
      border-color: $color4Dark;
      color: #fff;
    }
  }
  &.btn-color5{
    background-color: #FFF;
    color: $color5;
    border-color: $color5;
    &:hover{
      background-color: $color5Dark;
      border-color: $color5Dark;
      color: #fff;
    }
  }
  &.btn-color6{
    background-color: #FFF;
    color: $color6;
    border-color: $color6;
    &:hover{
      background-color: $color6Dark;
      border-color: $color6Dark;
      color: #fff;
    }
  }
  &.btn-color7{
    background-color: #FFF;
    color: $color7;
    border-color: $color7;
    &:hover{
      background-color: $color7Dark;
      border-color: $color7Dark;
      color: #fff;
    }
  }
}


.btn-color1{
  color: #fff;
  background-color: $color1;
  border-color: $color1;
  &:hover{
    background-color: $color1Dark;
    border-color: $color1Dark;
    color: #fff;
  }
}
.btn-color2{
  color: #fff;
  background-color: $color2;
  border-color: $color2;
  &:hover{
    background-color: $color2Dark;
    border-color: $color2Dark;
    color: #fff;
  }
}
.btn-color3{
  color: #fff;
  background-color: $color3;
  border-color: $color3;
  &:hover{
    background-color: $color3Dark;
    border-color: $color3Dark;
    color: #fff;
  }
}
.btn-color4{
  color: #fff;
  background-color: $color4;
  border-color: $color4;
  &:hover{
    background-color: $color4Dark;
    border-color: $color4Dark;
    color: #fff;
  }
}
.btn-color5{
  color: #fff;
  background-color: $color5;
  border-color: $color5;
  &:hover{
    background-color: $color5Dark;
    border-color: $color5Dark;
    color: #fff;
  }
}

.btn-color6{
  color: #fff;
  background-color: $color6;
  border-color: $color6;
  &:hover{
    background-color: $color6Dark;
    border-color: $color6Dark;
    color: #fff;
  }
}

.btn-color7{
  color: #fff;
  background-color: $color7;
  border-color: $color7;
  &:hover{
    background-color: $color7Dark;
    border-color: $color7Dark;
    color: #fff;
  }
}


.btn-color8{
  color: #fff;
  background-color: $color8;
  border-color: $color8;
  &:hover{
    background-color: $color8Dark;
    border-color: $color8Dark;
    color: #fff;
  }
}