@import '../../global/colors.scss';
@mixin leftMenu{
  height: 100%;
  background: $color6;
  position: relative;
  display: flex;
  transition: margin .5s ease;
  &.hideMenu{
    margin-left: -160px;
  }
  .sideMenuToggle{
    position: absolute;
    left: 100%;
    top: 0;
    background-color: $color5;
    color: #FFF;
    font-size: 25px;
    display: flex;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    z-index: 10;
  }
  .nav-link.flash{
    -webkit-animation: ColorFlash .5s infinite ease-in-out !important;  /* Safari 4+ */
    -moz-animation: ColorFlash .5s infinite ease-in-out !important;  /* Fx 5+ */
    -o-animation: ColorFlash .5s infinite ease-in-out !important;  /* Opera 12+ */
    animation: ColorFlash .5s infinite ease-in-out !important;  /* IE 10+, Fx 29+ */
  }
  .menu{
    overflow: auto;
    flex: 1;
    flex-direction: column;
    > .menu-item{
      flex: unset;
      width: 200px;
      border-bottom: 1px solid rgba(0,0,0,.1);
      //margin-bottom: 4px;
      > a.nav-link{
        padding: 14px 25px;
        .inline-nav-link{
          display: flex;
          .icon{
            font-weight:500;
            color: white;
          }
          .text{
            font-weight: 500;
            padding-left: 10px;
            color: white;
            display: flex;
            flex: 1;
            .texttext{
              flex: 1;
            }
            .todoNumber{
              background-color: #FFF;
              color: #000;
              border-radius: 30px;
              overflow: hidden;
              min-width: 25px;
              max-width: 25px;
              min-height: 25px;
              max-height: 25px;
              text-align: center;
              border: 1px solid #000;
            }
          }
        }
        &:hover{
          background: rgba(0,0,0,.4);
          .inline-nav-link{
            .icon,.text{
              color: white;
            }
          }
        }
        &.active{
          background: rgba(0,0,0,.3);
          .inline-nav-link{
            .icon,.text{
              color: white;
            }
          }
          &:hover{
            background: rgba(0,0,0,.5);
            .inline-nav-link{
              .icon,.text{
                //color: $color1;
              }
            }
          }
        }
      }
      .sub-menu-wrapper{
        overflow: hidden;
        transition: max-height .3s ease-out;
        &.no-trasition{
          transition: none;
        }
        .sub-menu-item-wrapper{
          //padding: 5px 0 0 15px;
          > .sub-menu-item{
            overflow: hidden;
            > a.nav-link{
              padding: 8px 13px 8px 30px;
              font-weight: 400;
              &.active{
                background: rgba(0,0,0,.2);
              }
              .inline-nav-link{
                display: flex;
                .icon,.text{
                  font-weight: 400;
                  color: white;
                }
                .text{
                  padding-left: 10px;
                  flex: 1;
                  align-items: center;
                  .texttext{
                    font-size: 12px;
                    flex: 1;
                  }
                  .todoNumber{
                    padding-top: 1px;
                    font-size: 10px;
                    font-weight: 600;
                    background-color: #FFF;
                    color: #000;
                    border-radius: 30px;
                    overflow: hidden;
                    min-width: 20px;
                    max-width: 20px;
                    min-height: 20px;
                    max-height: 20px;
                    text-align: center;
                    border: 1px solid #000;
                  }
                  .userNumber{
                    background-color: rgba(0,0,0,.7);
                    border: 1px solid rgba(255,255,255,.3);
                    color: #FFF;
                  }
                }
              }
              &:hover{
                background: rgba(0,0,0,.4);
                .inline-nav-link{
                  .icon,.text{
                    color: white;
                  }
                }
              }
              &.active{
                .inline-nav-link{
                  .icon,.text{
                    color: white;
                  }
                }
              }
            }
          }
        }
        .sub-sub-menu-wrapper{
          overflow: hidden;
          transition: max-height .3s ease-out;
          background-color: #fff;
          margin-right: 1px;
          &.no-trasition{
            transition: none;
          }
          .sub-sub-menu-item-wrapper{
            //padding: 5px 0 0 15px;
            > .sub-sub-menu-item{
              overflow: hidden;
              > a.nav-link{
                padding: 4px 13px 4px 40px;
                font-weight: 400;
                &.active{
                  //background: rgba(0,0,0,.2);
                }
                .inline-nav-link{
                  display: flex;
                  .icon,.text{
                    font-weight: 500;
                    color: #222;
                  }
                  .text{
                    padding-left: 10px;
                    flex: 1;
                    align-items: center;
                    .texttext{
                      font-size: 11px;
                      flex: 1;
                    }
                    .todoNumber{
                      padding-top: 1px;
                      font-size: 10px;
                      font-weight: 600;
                      background-color: #FFF;
                      color: #000;
                      border-radius: 30px;
                      overflow: hidden;
                      min-width: 20px;
                      max-width: 20px;
                      min-height: 20px;
                      max-height: 20px;
                      text-align: center;
                      border: 1px solid #000;
                    }
                    .userNumber{
                      color: rgba(0,0,0,.7);
                      border: 1px solid rgba(255,255,255,.3);
                      color: #FFF;
                    }
                  }
                }
                &.active{
                  .inline-nav-link{
                    .icon,.text{
                      color: $color5;
                      font-weight: 500;
                    }
                  }
                }
                &:hover{
                  background: rgba(0,0,0,.05);
                  .inline-nav-link{
                    .icon,.text{
                      color: $color1;
                      font-weight: 500;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}