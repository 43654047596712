@import '../../global/colors.scss';

#Header{
  background: #FFF;
  // #OfferBanner{
  //   background-color: orange;
  //   color: #FFF;
  //   .banner-offer{
  //     text-align: center;
  //     padding: 5px;
  //     font-weight: 500;
  //     a{
  //       color: #000;
  //       padding: 2px 10px;
  //       display: inline-block;
  //       border: 1px solid #000;
  //       margin-left: 15px;
  //       border-radius: 5px;
  //       font-size: 12px;
  //       font-weight: 700;
  //       text-transform: uppercase;
  //       &:hover{
  //         background-color: #FFF;
  //         color: #333;
  //       }
  //     }
  //   }
  // }
  .floatingFixed{
    background: #FFF;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    transition: background .5s ease, box-shadow .5s ease;
  }
  &.offtop{
    .floatingFixed{
      position: fixed;
      box-shadow: 0 0 10px rgba(0,0,0,.1);
    }
  }
  .preHeaderWrapper{
    background-color: $color1;
    .preHeaderRow{
      .loginMenu{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 38px;
        .nav-link{
          font-size: 12px;
          color: #FFF;
          padding: 0 1rem 0 0;
          font-weight: 500;
           cursor: pointer;
          &:hover{
            color: #000;
          }
        }
      }
    }
  }
  .headerContainer{
    min-height: 54px;
    height: 54px;
    position: relative;
  }
  .image-column{
    height: 54px;
    width: 240px;
    max-width: 240px;
    min-width: 240px;
    .content{
      margin: 0;
      height: 100%;
      display: flex;
      .logo-image{
      	display: flex;
        align-items: center;
        height: 100%;
        min-height: 0px;
        min-width: 0px;
        width: 100%;
      	.nav-link{
          display: flex;
          flex: 1;
          cursor: pointer;
          padding-left: 0;
      		img{
  			    width: 100%;
            max-width: 100%;
            height: auto;
      		}
          &.a{
            margin: 0;
            display: flex;
            justify-self: center;
            align-items: center;
            line-height: normal;
          }
      	}
      }
    }
  }
  .menu-col{
    .content{
      flex: 1;
    }
  }
  .menu-nav{
    display: flex;
    align-items: center;
    min-height: 54px;
    justify-content: flex-end;
    flex-direction: row;
    .nav-link{    
      color: #000;
      font-weight: 500;
      &:hover{
        color: $color5Dark;
      }
      &.active{
        color: $color5Dark;
      }
    }
  }
  .mobile-button{
    position: absolute;
    right: 0px;
    top: 0;
    bottom: 0;
    width: 90px;
    align-items: center;
    justify-content: space-between;
    .mobile-button-box{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      i{
        color: #000;
        font-size: 25px;
      }
    }
  }
}


@media only screen and (max-width: 1450px){
  #Header{
    .preHeaderWrapper{
      .preHeaderRow{
        .loginMenu{
          height: 30px;
          .nav-link{
            font-size: 12px;
            padding: 0 1rem 0 0;
          }
        }
        .languageMenu{
          .languageMenuItem{
            padding-left: 15px;
            height: 30px;
            div{
              padding-right: 5px;
              font-size: 12px;
            }
            img{
              width: 15px;
              height: 15px;
            }
          }
        }
      }
    }
    .headerContainer{
      min-height: 54px;
      height: 54px;
    }
    .image-column{
      height: 54px;
      width: 240px;
      max-width: 240px;
      min-width: 240px;
      .content{
        .logo-image{
          .nav-link{
            img{
            }
            &.a{
            }
          }
        }
      }
    }
    .menu-col{
      .content{
      }
    }
    .menu-nav{
      min-height: 54px;
      .nav-link{    
        font-size: 14px;
      }
      .cartButton .cartLink{
        .cartCount{
          padding-top: 3px;
          font-size: 14px;
        }
        .cartIcon{
          font-size: 18px;
        }
      }
    }
    .mobile-button{
      width: 90px;
      .mobile-button-box{
        i{
          font-size: 25px;
        }
      }
    }
  }

}


@media only screen and (max-width: 800px) and (min-width: 576px) {
}

@media only screen and (max-width: 575px) {
  #Header{
    .preHeaderWrapper{
      .preHeaderRow{
        margin: auto;
        max-width: 95%;
        .col{
          padding: 0;
        }
      }
    }
  }
}

@media only screen and (max-width: 480px) {


}