@import './colors.scss';
.SlideInRight{
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  *{
  	animation-duration: .6s;
  }
  #SlideInRightOverlay{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 100;
    z-index: 101;
    background-color: rgba(0,0,0,.5);
  }
  #SlideInRightContent{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    z-index: 102;
    background-color: #FFF;
    display: flex;
    flex-direction: column;
    &.frontEndSlideInRight{
      #Header{
        background-color: white;
        padding: 20px 20px 20px 20px;
        border-bottom: 2px solid #e1e1e1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .title{
          display: flex;
          align-items: center;
          padding: 0px;
          font-size: 18px;
          text-transform: capitalize;
          font-weight: 600;
          color: #000;
          .icon{
            padding-right: 5px;
            color: #4f4f4f;
            font-size: 18px;
          }
        }
        .close{
          color: #6e6e6e;
          width: 50px;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 50px;
          margin: -20px;
          font-size: 16px;
          &:hover{
            color: $color1;
            cursor: pointer;
          }
        }
      }
      #Body{
        padding: 20px;
        
      }

      #EditPromoCode{
        .couponInfoTitle{
          font-size: 16px;
          font-weight: 600;
          padding-bottom: 5px;
        }
        .couponWrapper{
          display: flex;
          background: #d6edf75c;
          border-radius: 10px;
          padding: 15px;
          border: 2px solid $color1;
          //align-items: center;
          justify-content: space-between;
          .couponIcon{
            .icon{
              border-radius: 10px;
              width: 60px;
              height: 60px;
              display: flex;
              justify-content: center;
              background: #53cbff4a;
              font-size: 30px;
              box-shadow: 5px 5px 5px #00000030;
              color: $color1;
              align-items: center;
              .fas{
  
              }
            }
          }
          .couponInfo{
            flex: 1;
            padding-left: 25px;
            .couponInfoText{
              display: flex;
              flex-wrap: wrap;
              .cate{
                font-weight: 600;
                font-size: 13px;
                padding-right: 10px;
              }
              .cont, .cont div{
                flex: 1;
                font-size: 13px;
              }
            }
            .name{
              .cate{
                font-size: 14px;
              }
              .cont{
                font-weight: 600;
                font-size: 16px;
              }
            }
            .desc{
              .cate{}
              .cont{}
            }
            .commissions{
              .cate{}
              .cont{
                font-weight: 700;
                //font-size: 14px;
              }
            }
          }
        }
        .spacerhr{
          height: 3px;
          background: #a0a0a0;
          border: none;
          border-radius: 100px;
          overflow: hidden;
          margin-bottom: 5px;
          margin-top: 30px;
        }
        #FormWrapper{
          padding: 20px;
          form.FormBuilder .form-group{
            margin-bottom: 10px;
            .input-header{
              .formTitle{
                font-weight: 600;
                margin-bottom: 5px;
              }
              .requiredStar.orange-text{
                display: none;
              }
              
            } 
          }
          .warningText{
            color: $color5;
            font-size: 12px;
          }
        }
      }//EditPromoCode


    }
    &.shortSlideInRight{
      width: 450px;
    }
    &.mediumSlideInRight{
      width: 550px;
    }
    &.darkHeader{
      #Header{
        background-color: $color8;
        .title{
        }
      }
    }
    #Header{
    	min-height: 0px;
	    position: relative;
      background-color: $color6;
	    .title{
	    	font-size: 18px;
	      padding: 10px 20px;
	      text-transform: uppercase;
	      font-weight: 600;
	      color: #fff;
	    }
    }
    #Body{
	    overflow-x: hidden;
	    overflow-y: auto;
	    flex: 1;
      .headerWithButton{
        display: flex;
        .previewFormTitle{
          flex: 1;
        }
        .editHeaderButton{
          max-width: 45px;
          min-width: 45px;
          padding-left: 0;
          padding-right: 0;
        }
      }

      #ActionPreviewUserCommission{
        .tableWrapper{
          overflow: scroll;
          width: 100%;
        }
        .commissions{
          .headerCol{
            font-size: 12px;
            min-width: 100px;
          }
          .commission{
            .tableCol{
              font-size: 12px;
            }
          }
        }
      }
    }
    #Footer{
    	min-height: 0px;
	    position: relative;
	    padding: 20px 20px 0;
	    .button.delete{
	    	background-color: #333;
	    	//float: right;
	    	&:hover{
	    		background-color: #000;
	    	}
	    	.fas{
	    		padding-left: 5px;
	    	}
	    }
    }
  }


  .SortableListGroupWrapper{
    padding: 10px;
    .SortableListGroups{
      display: flex;
      .SortableListGroupsLeft{
        flex: 1;
        padding: 10px;
        .SortableListGroup{
          border: 1px solid #9c9c9c;
          border-radius: 4px;
          margin: 0 0 20px;
          padding: 10px;
          .SLGTitle{
            display: flex;
            cursor: pointer;
            padding: 10px;
            margin: -10px;
            .SLGTitleText{
              flex: 1;
              font-weight: 600;
              font-size: 18px;
              text-transform: uppercase;
            }
            .SLGTitleIcon{
              color: $color1;
              padding: 0 10px;
              &:hover{
                color: $color1Dark;
              }
            }
          }
          &.collapse{
            .SLGFields{
              overflow: hidden;
              height: 0px;
            }
          }
          .SLGFields{
            .SLGDate{
              padding-top: 10px;
              .SLGDateTitle{
                padding-bottom: 5px;
              }
            }
            .SLGItems{
              //border: 1px solid $color1;
              padding: 10px 0;
              .SLGItemTitle{
                padding-bottom: 5px;
              }
              .SLGItem{

              }
            }
          }
        }
        button.button{
          width: 100%;
          margin: 0;
        }
      }
      .SortableListGroupsRight{
        flex: 1;
        padding: 10px;
        .SLGRTitle{
          font-weight: 600;
          font-size: 18px;
          border-bottom: 1px solid #ddd;
          text-transform: uppercase;
          padding-bottom: 10px;
          margin-bottom: 15px;
        }
      }
    }
  }
  #TableSettingsSortable{
    .headerBasic{
      margin: 5px 0 0 0;
    }
    .subHeaderBasic{
      margin-bottom: 20px;
    }
    .SortableItem{
      &.withHandle{
        cursor: initial;
        display: flex;
        align-items: center;
        .my-handle{
          cursor: move;
          padding: 20px 10px 20px 10px;
          margin: -10px 5px -10px -10px;
          background: #d0ebf7;
        }
        .FormBuilder{
          flex: 1;
        }
      }
      .FormBuilder{
        .form-group{
          margin: 0;
          padding: 0;
          .form-toggle{
            padding: 0;        
            h5.header{
              padding: 0 !important;
              .text{
                justify-content: center;
                align-items: center;
                .formTitle{
                  margin: 0;
                }
              }
            }
          }
        }
      }
    }
  }
  #InlineCodeList{
    padding: 0 10px;
    .codeItem{
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid #eee;
      padding: 10px;
      &:hover{
        background-color: #03a9f41c;
      }
      .codeItemContainer{
        flex: 1;
        .codeItemText{
          display: flex;
          .title{
            font-size: 12px;
            font-weight: 600;
            padding-right: 10px;
          }
          .value{
            font-size: 12px;
            flex: 1;
          }
        }
        .codeItemCODE{

        }
        .codeItemUID{

        }
      }
      .codeItemLink{
        .button{
          margin: 0;
          padding: 6px 10px;
          font-size: 12px;
        }
      }
    }
  }
  .alignerMakeSteps{
    .col{
      padding-bottom: 0;
      padding-top: 0;
      .content{
        .title{
          padding: 10px;
          margin: 20px -10px 20px -10px;
          border: 1px solid;
          border-radius: 10px;
          border-left: 6px solid;
          font-weight: 700;
          font-size: 16px;
          text-transform: uppercase;
        }
        .alignerMakeStep{
          display: flex;
          align-items: center;
          justify-content: space-between;
          border: 1px solid #ddd;
          border-radius: 8px;
          padding: 0px 12px;
          margin-bottom: 15px;
          cursor: pointer;
          &:hover{
            border-color: $color1;
          }
          .stepName{
            font-weight: 500;
          }
          .checkButton{
            font-size: 30px;
            cursor: pointer;
            &:hover{
              color: $color1;
            }
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 800px) and (min-width: 576px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 480px) {
}