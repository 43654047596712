@import './colors.scss';
#CandidateQuiz{
	display: flex;
	flex-direction: column;
	overflow: hidden;
	height: 100%;
	img{
		max-width: 100%;
	}
	.animated, *{
		animation-duration: .6s;
	}
	.animated {
		-webkit-animation-duration: 400ms !important;
		-moz-animation-duration: 400ms !important;
		-o-animation-duration: 400ms !important;
		animation-duration: 400ms !important;
	}

	#CandidateQuizSteps {
		width: 100%;
		height: 60px;

		.topNav {
			position: relative;
			padding-bottom: 70px;

			.backNavButton {
				background-color: transparent;
				border: none;
				font-size: 20px;
				font-weight: 300;
				padding: 30px;
				cursor: pointer;
				color: $buttonBlue;
				display: flex;
				align-items: center;
				justify-content: center;

				&.hidden {
					display: none;
				}

				span {
					display: block;
					padding-left: 10px;
					height: 33px;
				}

				&:hover {
					color: $color2;
				}
			}

			.logo {
				max-width: 150px;
				position: absolute;
				left: 0;
				top: 0;
				right: 0;
				margin: auto;
			}

			.backHomeButton {
				color: $color1;
				position: absolute;
				right: 0;
				top: 0;
				background-color: transparent;
				border: none;
				font-size: 30px;
				font-weight: 300;
				padding: 28px;
				cursor: pointer;
				display: flex;
				align-items: center;
				justify-content: center;
				text-decoration: none;

				&:hover {
					color: $color2;
				}
			}
		}

		.progressBar {
			position: relative;

			.progressBarBars {
				display: flex;
				z-index: 1;
				margin: 0;
				position: absolute;
				top: 13px;
				left: 50px;
				right: 50px;

				.bar {
					flex: 1;
					z-index: 2;
					.barColor {
						background-color: lighten($buttonBlue, 25%);
						transition: max-width .8s ease;
						max-width: 0px;
						height: 10px;

						&.active {
							max-width: 100%;
						}
					}
				}
				.subBar{
					background-color: #ddd;
					position: absolute;
					left: 0;
					right: 0;
					height: 10px;
					z-index: 1;
				}
			}

			.progressBarSteps {
				display: flex;
				width: 100%;
				justify-content: space-between;
				z-index: 2;
				position: relative;

				.progessStep {
					//flex: 1;
					width: 100px;
					text-align: center;
					.stepName {
						//background-color: white;
					}
					&.active {
						color: $color1;
						font-weight: 500;

						.stepName {
							//background-color: $color1;
							.stepIcon {
								color: $buttonBlueDark; //$buttonBlue;
							}

							.stepText {
								color:  #000;//$color1;

								&.active {
									//font-weight: 400;
								}
							}
						}
					}

					.stepIcon {
						color: #777;
						font-size: 35px;
						background-color: #FFF;
						border-radius: 100px;
						//margin-top: -6px;
						&.active {
							color: $color1;
						}
					}

					.stepText {
						font-weight: 700;
						color: #777;

						&.active2 {
							font-weight: 400;
							color: $color1;
						}
					}
				}
			}
		}
	}
	.buttonGroup.nav {
		justify-content: center;
		.button {
			&.disabled {
				opacity: 0.4 !important;
				pointer-events: none;
			}
			&.active {
			}
		}
	}
	#CandidateQuizOutter{
		transition: min-height .4s ease-in-out;
		width: 100%;
		overflow: hidden;
		position: relative;
	}
	#CandidateQuizWrapper {
		flex: 1;
		overflow: hidden;
		padding-top: 40px;
		padding-bottom: 20px;
		.question {
			width: 100%;
			&.animated {
				position: absolute;
				//width: 100%;
				pointer-events: none;
			}
			&.question9{
				.buttonImage{
					padding: 1.5rem;
					img{
						overflow: hidden;
						border-radius: 20px;
						border: 10px solid #f5f5f5;
						box-shadow: 7px 8px 6px rgba(0, 0, 0, .3);
					}
				}
			}
			
			.questionText {
				text-align: center;
				font-weight: 600;
				font-size: 35px;
				line-height: 50px;
				max-width: 80%;
				padding: 20px 0;
				margin: auto;
				//color: $color1;

				.emph {
					font-weight: 600;
				}
			}
			.questionImageWrapper{
				display: flex;
				flex-wrap: wrap;
				.buttonImage{
					padding: 1.5rem;
					&.full{
						min-width: 100%;
						flex: 1;
					}
					img{
						overflow: hidden;
						border-radius: 20px;
						border: 10px solid #f5f5f5;
						box-shadow: 7px 8px 6px rgba(0, 0, 0, .3);
					}
				}
				.buttonTextZone{
					flex: 1;
					display: flex;
					align-items: center;
					justify-content: center;
					.buttonTextWrapper{
						padding: 20px;
						.questionText{
							text-align: left;
							padding: 0 0 10px 0;
							margin: 0;
							max-width: 100%;
							font-weight: 700;
						}
						.buttons{
							padding: 0;
							.welcomeText{
								text-align: left;
								padding: 0;
							}
							.buttonGroup{
								text-align: left;
								align-items: flex-start;
								justify-content: flex-start;
							}
						}
						@media only screen and (max-width: 991px) {
							.questionText{
								text-align: center;
							}
							.buttons{
								.welcomeText{
									text-align: center;
								}
								.buttonGroup{
									text-align: center;
									align-items: center;
									justify-content: center;
								}
							}
						}
					}
				}
			}
			.successMessage{
				.nextStepText{
					max-width: 800px;
					margin: auto;
					.questionText {
						text-align: center;
						font-weight: 700;
						font-size: 35px;
						line-height: 50px;
						max-width: 80%;
						padding: 20px 0;
						margin: auto;
						//color: $color1;
		
						.emph {
							font-weight: 600;
						}
					}
					div{
						text-align: center;
						font-size: 18px;
					}
					.subTitle{
						font-weight: 600;
						padding-bottom: 20px;
						margin-top: -10px;
						font-size: 22px;
					}
					.instruction{
						//padding: 5px 0;
						text-align: center;
						font-size: 17px;
					}
				}
			}
			#TreatmentPlanBar{
				margin-top: 40px;
				padding: 60px 0 40px;
				border-top: 10px solid #e91e63 !important;
				border-bottom: none !important;
				text-align: left;
				.container.impressionKitContainer{
					padding-bottom: 0;
				}
			}
			.buttons {
				max-width: 820px;
				margin: auto;

				.basicText {
					text-align: center;
					font-size: 18px;
				}

				.buttonGroup {
					display: flex;
					flex-wrap: wrap;
					align-items: center;
					justify-content: center;
					&.getStarted{
						padding-top: 20px;
					}
					&.inline{
						max-width: 550px;
						margin: auto;
						.button{
							min-width: 100%;
							margin: 10px 0;
						}
					}
					.button {
						width: 300px;
						//max-width: 90%;
						margin: 10px;
						max-width: 80%;
						font-size: 18px;
						&.active {
							background-color: $buttonBlueDark;
							color: #FFF;
						}
					}
				}

				.buttonImages {
					display: flex;
					flex-wrap: wrap;
					justify-content: space-between;

					.buttonImage {
						flex: 1;
						padding: 10px;
						max-width: 280px;

						&.active {
							img {
								border-color: $buttonBlueDark;
							}
						}

						img {
							&:hover {
								border-color: $color1;
							}

							cursor: pointer;
							border: 4px solid #dcdcdc;
							border-radius: 10px;
						}
					}
				}
				.FormBuilder {
					margin: auto;

					.form-group {
						margin-bottom: 20px;
					}

					input {
						width: 100%;
						height: 45px;
						background: #eee;
						font-size: 15px;
						padding: 10px;
						border-radius: 5px;
						font-weight: 300;
					}
				}
			}
		}
	}
}

@media only screen and (max-width: 1000px) {
	#CandidateQuiz{
		#CandidateQuizSteps {
			.progressBar {
				.progressBarSteps {
					.progessStep {
						.hideOnSmall {
							display: none;
						}

						.stepIcon {
							font-size: 20px;
						}
					}
				}
			}
		}
		#CandidateQuizWrapper {
			.question {
				.questionText {
					padding: 0 20px;
				}

				.buttons {
					padding: 0 20px;
				}
			}
		}
	}
}


@media only screen and (max-width: 700px) {
    #CandidateQuiz{
		#CandidateQuizSteps {
			.topNav {
				.backNavButton {
					font-size: 20px;

					span {
						font-size: 20px;
						height: 22px;
					}
				}

				.backHomeButton {
					font-size: 24px;
				}
			}

			.progressBar {
				.progressBarBars {
					left: 40px;
					right: 40px;
				}

				.progressBarSteps {
					.progessStep {
						.stepIcon {
							font-size: 20px;
						}
					}
				}
			}
		}

		#CandidateQuizWrapper {
			.question {
				.questionText {
					font-size: 28px;
					line-height: 32px;
				}
			}
		}
		
	}
}


@media only screen and (max-width: 500px) {
	#CandidateQuiz{
		#CandidateQuizSteps {
			height: 80px;
			padding: 20px 0 0px;

			.topNav {
				.backNavButton {
					span {
						height: 20px;
					}
				}
				
				.logo {
					max-width: 100px;
				}
				
				padding-bottom: 20px;
			}
			
			.progressBar {
				padding: 0 20px;

				.progressBarBars {
					top: 6px;
					left: 50px;
					right: 50px;
				}

				.progressBarSteps {
					.progessStep {
						width: auto;

						.stepText {
							min-width: 60px;
							font-size: 16px;
						}

						.stepIcon {
							//display: none;
						}
					}
				}
			}
		}

		#CandidateQuizWrapper {
			flex: 1;
			overflow: auto;
			padding-top: 10px;
			padding-bottom: 70px;

			.question {
				&.animated {
					position: absolute;
					width: 100%;
				}

				.questionText {
					text-align: center;
					font-size: 23px;
					line-height: 30px;
					max-width: 80%;
					padding: 20px 0;
					font-weight: 700;
					margin: auto;
					//color: $color1;

					.emph {
						font-weight: 600;
					}
				}

				.buttons {
					max-width: 820px;
					margin: auto;

					.basicText {
						text-align: center;
						font-size: 18px;
					}

					.buttonGroup {
						display: flex;
						flex-wrap: wrap;
						align-items: center;
						justify-content: center;

						.button {
							width: 300px;
							//max-width: 90%;
							margin: 20px 0 0;
							max-width: 80%;

							&.active {
								background-color: $color1;
								color: #FFF;
							}
						}
					}

					.buttonImages {
						display: flex;
						flex-wrap: wrap;
						justify-content: space-between;

						.buttonImage {
							flex: inherit;
							padding: 10px;
							max-width: 50%;
							width: 50%;

							&.active {
								img {
									border-color: $color1;
								}
							}

							img {
								&:hover {
									border-color: $color1;
								}

								cursor: pointer;
								border: 4px solid #dcdcdc;
								border-radius: 10px;
							}
						}
					}

					.FormBuilder {
						margin: auto;

						.form-group {
							margin-bottom: 20px;
						}

						input {
							width: 100%;
							height: 45px;
							background: #eee;
							font-size: 15px;
							padding: 10px;
							border-radius: 5px;
							font-weight: 300;
						}
					}
				}
			}
		}
		
	}
}