@import '../../global/colors.scss';
#Website{
  #Page404{
    #MainText{
      text-align: center;
      .icon{
        font-size: 50px;
      }
      .message{
        .textSection{
          h1.sectionTitle{

          }
          p{

          }
        }
      }
    }
  }
}