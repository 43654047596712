@import '../../global/colors.scss';
#LabWrapper{
  #MobileMenu{ 
    .sideMenu{
      background-color: #FFF;
      .menuTitle{
        color: #000;
        padding: 15px 20px;
        border-bottom: 1px solid #000;
        .exist{
          color: #000;
        }
      }
      .navigationButtons{
        overflow: auto;
        flex: 1 1;
        display: flex;
        flex-direction: column;
        #LabSideLanguageToggle{
          display: flex;
          align-items: center;
          justify-content: center;
          .languageMenu{
            .languageMenuItem{
              &.active{
                div{
                  color: $color1;
                }
                img{}  
              }
              div{
                color: #000;
              }
              img{}
            }
          }
        }
        .menu {
          padding: 15px;
          flex: 1;
          &.otherButtons{
            flex: none;
            .menu-item{
              a{
                background-color: $color5;
                color: #FFF;
                .inline-nav-link{
                  .icon{
                    background-color: rgba(0,0,0,.5);
                    font-size: 14px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                  }
                  .text{
                  }
                }
                &:hover{
                  background-color: darken($color5Dark, 5%);
                }
              }
            }
            padding-bottom: 20px;
          }
          .menuTitle{
            display: none;
          }
          .menu-item{
            a{
              text-align: center;
              color: #333333;
              //min-height: 90px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              border-radius: 10px;
              margin-bottom: 5px;
              &:hover{
                background-color: lighten($color1, 45%);
              }
              &.active{
                background-color: lighten($color1, 35%);
                .inline-nav-link{
                  .icon{
                  }
                  .text{
                    
                  }
                }
              }
              .inline-nav-link{
                flex: 1;
                display: flex;
                text-align: left;
                align-items: center;
                .icon{
                  width: 30px;
                  font-size: 18px;
                  text-align: center;
                  height: 30px;
                  border-radius: 5px;
                  background-color: #FFF;
                }
                .text{
                  padding-left: 10px;
                  flex: 1;
                  font-size: 14px;
                  font-weight: 500;
                }
              }
            }
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 800px) and (min-width: 576px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 480px) {


}