@import '../../global/colors.scss';

.cartButton{
  padding-left: 3px;
  margin-top: -10px;
  margin-right: -15px;
  .cartLink{
    cursor: pointer;
    position: relative;
    width: 42px;
    height: 42px;
    box-sizing: border-box;
    padding-top: 18px;
    .cartCount{
      position: absolute;
      //background: $color1;
      color: #FFF;
      right: 0px;
      top: 0px;
      width: 25px;
      height: 25px;
      text-align: center;
      border-radius: 50px;
      padding-top: 1px;
      font-weight: 500;
    }
    .cartIcon{
      display: block;
      font-size: 20px;
    }
  }
}


@media only screen and (max-width: 800px) and (min-width: 576px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 480px) {


}