@import '../../global/colors.scss';
#Footer{
  border-top: 1px solid #ddd;
  background-color: #FFFFFF;
  padding: 60px 0;
  .footerSection1{
    padding-bottom: 90px;
    .title{
      font-size: 40px;
      font-weight: 500;
      padding-bottom: 15px;
    }
    .text{
      max-width: 500px;
      font-size: 16px;
    }
    .buttonRight{
      .content{
        align-items: center;
        justify-content: center;
        justify-items: center;
        height: 100%;
        display: flex;
        .button{
          margin: 0;
        }
      }
    }
  }
  .footerSection2{
    .logoImage{
      max-width: 300px;
      a{
        padding: 0;
      }
      img{
        max-width: 100%;
      }
    }
    .slogan{
      padding-top: 20px;
      max-width: 340px;
      padding-bottom: 30px;
      font-size: 14px;
    }
    .copyright{
    	display: flex;
      font-size: 14px;
      font-weight: 500;
    }
    .header{
      font-weight: 600;
      padding-bottom: 20px;
      font-size: 20px;
    }
    .menu{
      display: block;
      .menu-item{
        margin: 0;
        padding: 0;
        a.nav-link{
          color: #333;
          padding: 0px 0px 8px;
          font-size: 14px;
          &:hover, &.active{
            color: $color1Dark;
          }
        }
      }
    }
    .footerContact{
      i{
        padding-right: 8px;
      }
    }
  }
  .socialLinks{
    display: flex;
    flex-wrap: wrap;
    .socialItem{
      flex: 1;
      max-width: 50%;
      font-size: 35px;
      margin-top: -10px;
      a{
        color: $color1;
        &:hover{
          color: $color1Dark;
        }
      }
    }
  }
  .phoneFooter{
    display: flex;
    .icon{
      font-size: 16px;
      color: $color5;
    }
    .phone{
      font-weight: 600;
      padding-left: 10px; 
      font-size: 16px;
      flex: 1;
    }
  }
  .addressFooter{
    padding-top: 10px;
    display: flex;
    .icon{
      font-size: 16px;
      color: $color5;
    }
    .infoContent{
      font-weight: 300;
      padding-left: 10px; 
      font-size: 13px;
      flex: 1;
    }
  }
}

@media only screen and (max-width: 1399px){
  #Footer{
    .socialColumn{
      min-width: 185px;
      max-width: 185px;
    }
    .footerNav1, .footerNav2{
      min-width: 145px;
    }
  }
}

@media only screen and (max-width: 991px){
  #Footer{
    padding: 50px 0;
    .footerSection2 .businessSection{
      text-align: center;
      .logoImage{
        margin: 0 auto 20px;
      }
      .slogan{
        max-width: 100%;
      }
    }
  }
  .smallScreenCopyright{
    padding: 30px 0 0px;
    justify-content: center;
  }
}


@media only screen and (max-width: 767px){
  #Website #Footer {
    .container{
      padding-left: 30px;
      padding-right: 30px;
      .col, .col-12{
        padding-left: 10px;
        padding-right: 10px;
      }
    }
  }
  #Footer{
    padding: 40px 0;
  }
}