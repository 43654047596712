@import '../../global/colors.scss';
#HeaderImage{
  margin-bottom: 20px;
  max-width: 450px;
  &.right{
    .headerImageWrapper{
      .img{
        //margin: 30px 0 0 30px;
        img{
          //margin-top: -15px;
          transform: rotate(5deg);
          box-shadow: -2px -2px 8px rgba(0, 0, 0, .7);
        }
      }
      .behindPicture{
        position: absolute;
        z-index: 1;
        padding: 0;
        //top: -30px;
        left: 0px;
        //bottom: 60px;
        right: 30px;
        transform: rotate(-5deg);
        .behindPictureColor{
          background: linear-gradient(90deg, lighten($color1, 10%) 0%, $color1 15%, rgba(120,91,182,1) 55%, $color5 95%);
        }
      }
    }
  }
  .headerImageWrapper{
    position: relative; 
    width: 90%;
    height: 0;
    padding-bottom: 90%;
    .img{
      border-radius: 30px;
      margin: 0 30px 30px 0;
      //overflow: hidden;
      //max-width: 450px;
      //max-height: 450px;
      //box-shadow: 2px 2px 8px rgba(0, 0, 0, .7);
      position: relative;
      z-index: 2;
      img{
        transform: rotate(-10deg);
        box-shadow: 2px 2px 8px rgba(0,0,0,0.7);
        border-radius: 30px;
        margin-top: 30px;
      }
    }
    .behindPicture{
      position: absolute;
      z-index: 1;
      padding: 0;
      top: 30px;
      left: 30px;
      bottom: 0;
      right: 0;
      .behindPictureColor{
        height: 100%;
        width: 100%;
        background: $color1;
        background: linear-gradient(90deg, lighten($color1, 10%) 0%, $color1 15%, rgba(120,91,182,1) 55%, $color5 95%);
        border-radius: 30px;
        //max-width: 450px;
        //max-height: 450px;
      }
    }
  }
}


@media only screen and (max-width: 1440px) {
  #HeaderImage{
    margin: 0 auto 20px;
    max-width: 350px;
  }
}




@media only screen and (max-width: 800px) and (min-width: 576px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 480px) {


}