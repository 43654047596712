@import '../../global/colors.scss';
#SimpleStepsList{
  border: none;
  display: flex;
  overflow-y: scroll;
  width: calc( 100% );
  scroll-snap-type: x mandatory;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  max-width: 710px;
  margin: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  .stepWrapper{
    min-width: calc( 100% );
    scroll-snap-align: start;
    scroll-snap-stop: always;
    margin: 0 20px;
    padding-bottom: 20px;
    .step{
      display: flex;
      flex-direction: row;
      border: 1px solid $color1;
      border-radius: 10px;
      overflow: hidden;
      padding: 10px;
    }
  }
  .subHeader{
    max-width: 960px;
    margin: auto;
  }
  .image{
    img{
      width: 200px;
    }
    padding-right: 20px;
  }
  .textWrapper{
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    .text{
      flex: 1;
      .textHeader{
        line-height: 20px;
        min-height: 30px;
        padding-bottom: 10px;
        padding-top: 10px;
        .textHeaderStep{
          padding-top: 15px;
          padding-bottom: 6px;
          font-weight: 800;
          font-size: 20px;
          min-width: 80px;
          padding-right: 10px;
          display: inline;
        }
        .textHeaderTitle{
          padding-top: 15px;
          padding-bottom: 6px;
          font-size: 20px;
          font-weight: 600;
          color: $color5;
          flex: 1;
          min-width: 160px;
          display: inline;
        }
      }
      .textPostHeader{
        //min-height: 100px;
        font-size: 16px;
      }
    }
  }
}


@media only screen and (max-width: 550px){
  #PagePromo #SimpleStepsList .stepWrapper .step{
    display: block;
    text-align: center;
  }
}