@import '../../global/colors.scss';
#ScheduleAligners{
  .SortableListGroupWrapper{
    .SortableListGroups{
      .SortableListGroupsLeft  {
        .SortableListGroup{
          margin-bottom: 10px;
        }
      }
      .SortableListGroupsRight{
        .SLGRTitle{
          display: flex;
          .title{
            flex: 1;
          }
          .listNav{
            display: flex;
            .reverse{
              cursor: pointer;
              font-size: 20px;
              padding: 5px 10px;
              &:hover{
                color: $color1;
              }
            }
            .numberInput{

            }
          }
        }
      }
      .SortableItemContainer{
        .SortableItem{
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0;
          .my-handle{
            background-color: $color1;
            color: #FFF;
            cursor: move;
            display: flex;
            height: 44px;
            width: 30px;
            align-items: center;
            justify-content: center;
            &:hover{
              background-color: $color1Dark;
            }
          }
          .name{
            flex: 1;
            font-weight: 500;
            cursor: pointer;
            padding: 10px;
            overflow: hidden;
          }
          .selectBoxSortable{
            cursor: pointer;
            display: flex;
            height: 40px;
            width: 40px;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            &.selected{
              .fas.fa-check-square{
                display: block;
              }
              .fas.fa-square{
                display: none;
              }
              .far.fa-square{
                display: none;
              }
              &:hover{
                .fas.fa-check-square{
                  display: block;
                }
                .fas.fa-square{
                  display: none;
                }
                .far.fa-square{
                  display: none;
                }
              }
            }
            &:hover{
              .fas.fa-check-square{
                display: none;
              }
              .fas.fa-square{
                display: block;
              }
              .far.fa-square{
                display: none;
              }
            }
            .fas.fa-check-square{
              display: none;
            }
            .fas.fa-square{
              display: none;
            }
            .far.fa-square{
              display: block;
            }
          }
        }
      }
    }
  }
}