@import '../../global/colors.scss';
#Website{
  #PageWinners{
    .headerImageSmall{
      //max-width: 300px;
    }
    #WinnersResults{
      .giveawayResults{
        .title{
          font-size: 20px;
        }
        .giveawayList{
          .giveawayPerson{
            display: flex;
            border-bottom: 1px solid #eee;
            padding-bottom: 2px;
            margin-bottom: 2px;
            &.header{
              border-bottom: 2px solid #333;
              padding-bottom: 5px;
              margin-bottom: 5px;
              div{
                font-weight: 700;
                font-size: 14px;
                text-transform: uppercase;
              }
            }
            .won{
              font-weight: 500;
              min-width: 100px;
            }
            .name{
              flex: 1;
            }
            .prize{
              font-weight: 600;
              font-size: 14px;
              min-width: 100px;
            }
            .savings{
              font-weight: 600;
              min-width: 100px;
            }
          }
          .giveawayTotal{
            display: flex;
            justify-content: flex-end;
            align-items: center;
            .desc{
              font-size: 18px;
              font-weight: 600;
              padding-right: 20px;
            }
            .total{
              font-size: 20px;
              font-weight: 700;
              min-width: 100px;
            }
          }
        }
      }
    }
  }
}