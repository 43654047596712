@import '../global/colors.scss';
@import './components/index.scss';
@import './components/_leftMenu';

#Admin{
	height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  #AdminHeader{
    //background: #f7f7f7;
    background: #12222d;
    .adminHeaderWrapper{
      display: flex;
      align-items: center;
      justify-content: center;
      .logo{
        width: 180px;
        img{
          max-width: 200px;
        }
      }
      .otherStuff{
        flex: 1 1;
        align-items: center;
        display: flex;
        justify-content: flex-end;
        .mobile-button{
          display: flex;
          width: 32px;
          align-items: center;
          justify-content: center;
         .mobile-button-box{
          color: #FFF;
          font-size: 20px;
          .i{

          }
         } 
        }
        .userLink{
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 10px 15px;
          .user{
            flex: 1;
            text-align: right;
            padding-right: 10px;
            color: #FFF;
            .welcome{
              font-size: 12px;
              font-weight: 500;
              margin-bottom: -3px;
            }
            .userName{
              font-size: 15px;
              font-weight: 700;
            }
          }
          .userImage{
            overflow: hidden;
            border-radius: 100px;
            img{
              max-width:35px;
            }
          }
        }
      }
    }
  }
  #AdminContentWrapper{
    display: flex;
    flex: 1;
    overflow: hidden;
    #LeftMenu{
      @include leftMenu;
    }
    #AdminContent{
      flex: 1;
      height: 100%;
      overflow: auto;
      //background: #f7f7f7;
      padding: 20px;
      .reportTop{
        border: 1px solid rgb(221, 221, 221);
        border-left-width: 10px;
        border-radius: 5px;
        display: flex;
        background: #FFF;
        box-shadow: 0px 4px 4px #ececec;
        padding: 5px 0px;
        margin-bottom: 15px;
        &.report1{
          border-color: $color1;
          .reportIcon{
            color: $color1;
          }
        }
        &.report2{
          border-color: $color2;
          .reportIcon{
            color: $color2;
          }
        }
        &.report3{
          border-color: $color4;
          .reportIcon{
            color: $color4;
          }
        }
        &.report4{
          border-color: $color5;
          .reportIcon{
            color: $color5;
          }
        }
        .reportIcon{
          font-size: 35px;
          color: #FFF;
          width: 60px;
          height: 60px;
          display: flex;
          align-items: center;
          justify-content: center;
          overflow: hidden;
          border-radius: 100px;
        }
        .reportText{
          flex: 1;
          display: flex;
          align-items: center;
          .reportTitle{
            font-size: 15px;
            flex: 1;
          }
          .reportValue{
            font-size: 22px;
            font-weight: 600;
            line-height: 1.2;
          }
        }
      }
    }
  }

  
	@import './pages/dashboard.scss';
	@import './pages/template1.scss';
  @import './pages/templateHeader.scss';
  @import './pages/templateList.scss';
  @import './pages/templateSlideInRight.scss';
  @import './pages/settings.scss';
  @import './pages/emails.scss';

  .section{
    padding-top: 30px;
    > .title{
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
      padding-bottom: 10px;
    }
    // .card{
    //   padding: 20px;
    //   background: rgba(229,234,246,.4);
    //   border: none;
    // }
  }
  .card{
    padding: 20px;
    background: rgba(229,234,246,.2);
    border-color: rgba(229,234,246,.7);
  }
  .progressCardWithIcon{
    display: flex;
    align-items: center;
    justify-content: center;
    .progressCardIcon{
      .circleBox{
        font-size: 25px;
        color: #FFF;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100px;
        overflow: hidden;
        width: 60px;
        height: 60px;
      }

    }
    .subCardProgress{
      flex: 1;
      padding-left: 10px;
      .title{
        font-size: 14px;
        font-weight: 500;
      }
      .subCardContent{
        .numberWrapper{
          font-size: 20px;
          font-weight: 800;
        }
      }
    }
  }
  
  table.table{
    thead.thead-dark{
      tr{
        th{
          padding: 8px;
          &.tableCheckBoxHeader{
            width: 20px;
          }
          .icon{
            padding-left: 5px;
            height: 7px;
            display: inline-block;
            position: relative;
            .fas.fa-sort-up{
              position: absolute;
            }
            .fas.fa-sort-down{
              top: -5px;
              position: absolute;
            }
          }
          &.formButtonWrapper{
            width: 70px;
            text-align: center;
          }
          .headerColWrapper{
            .headerColText{
              font-weight: 600;
              font-size: 14px;
            }
          }
        }
      }
    }
    tbody{
      tr{
        &.active{
          background: #FFF;
          background: lighten($color1, 45%)
        }
        &:hover{
          th, td{
            background-color: lighten($color1, 50%);
          }
        }
        th{
          //padding: 10px 8px;
          &.formButtonWrapper{
            width: 70px;
            text-align: center;
          }
          div{
            font-weight: 400;
            font-size: 12px !important;
          }
          .rowText{
            .value{
              height: 21px;
              overflow: hidden;
            }
          }
          .NONE{
            display: flex;
            align-items: center;
            //justify-content: center;
            height: 40px;
          }
          .tableListImage{
            margin: auto;
            width: auto;
            max-width: 40px;
            height: 100%;
            padding: 2px;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            img{
              height: auto;
              width: auto;
              max-width: 100%;
              max-height: 100%;
            }
          }
          .formButton{
            i{
              color: $color1;
              cursor: pointer;
              &:hover{
                color: $color1Dark;
              }
            }
          }
          i{
            cursor: pointer;
            &:hover{
              color: $color1;
            }
          }
          &.tableCheckBox{
            &.active{
              color: $color1;
            }
            .tableCheckBoxDiv{
              display: flex;
              align-items: center;
              justify-content: center;
              margin: auto;
              i{
                margin: auto;
                font-size: 14px;
                padding: 14px 2px;
              }
            }
          }
        }
      }
    }
  }
}



@-webkit-keyframes ColorFlash {
  0% {
    background-color: $color5;
  }
  50%{
    background-color: transparent;
  }
  100% {
    background-color: $color5;
  }
}

.backendPageTemplateComponent{
  padding: 0 10px;

}


@media only screen and (min-width: 1399px){
  #Admin{
    #AdminContentWrapper{
      #LeftMenu{
        .menu{
          > .menu-item{
            width: 160px;
            //margin-bottom: 4px;
            > a.nav-link{
              padding: 14px 18px;
              .inline-nav-link{
                .icon{
                }
                .text{
                  font-weight: 500;
                  padding-left: 10px;
                  color: white;
                  display: flex;
                  flex: 1;
                  font-size: 12px;
                  .texttext{
                    flex: 1;
                    font-size: 12px;
                  }
                  .todoNumber{
                    background-color: #FFF;
                    color: #000;
                    border-radius: 30px;
                    overflow: hidden;
                    min-width: 20px;
                    max-width: 20px;
                    min-height: 20px;
                    max-height: 20px;
                    font-size: 12px;
                    font-weight: 700;
                  }
                }
              }
            }
            .sub-menu-wrapper{
              .sub-menu-item-wrapper{
                //padding: 5px 0 0 15px;
                > .sub-menu-item{
                  > a.nav-link{
                    .inline-nav-link{
                      .icon,.text{
                      }
                      .text{
                        .texttext{
                          font-size: 12px;
                        }
                        .todoNumber{
                        }
                        .userNumber{
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}



@media only screen and (max-width: 1300px){
  #Admin{
    .container-fluid .container-fluid{
    }
    #AdminHeader{
      .adminHeaderWrapper{
        .logo{
          img{
          }
        }
        .otherStuff{
          .mobile-button{
            .i{}
          } 
        }
        .userLink{
          .user{
            .welcome{
            }
            .userName{
            }
          }
          .userImage{
            img{
            }
          }
        }
      }
    }
    #AdminContentWrapper{
      #AdminContent{
        .templateReports{
          .row{
            .col{
              min-width: 50%;
            }
          }
        }
        .reportTop{
          border: 1px solid rgb(221, 221, 221);
          border-left-width: 10px;
          border-radius: 5px;
          display: flex;
          background: #FFF;
          box-shadow: 0px 4px 4px #ececec;
          padding: 5px 0px;
          margin-bottom: 15px;
          align-items: center;
          justify-content: center;
          .reportIcon{
            font-size: 25px;
            color: #FFF;
            width: 40px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .reportText{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            > div{
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .reportTitle{
              font-size: 15px;
              flex: 1;
            }
            .reportValue{
              font-size: 25px;
              padding-right: 10px;
            }
          }
        }
      }
    }
    .card{
      overflow: auto;
    }
    .table.reportTable{
      .tableHeaders{
        height: 90px;
      }
      .tableBody{
        th.tableValues.tableValuesMain{
          .tableValue{
            line-height: 12px;
            strong {
              font-size: 10px;
            }
          }
        } 
      }
    }
  }
}



@media only screen and (max-width: 800px){
  #Admin{
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .container-fluid .container-fluid{
      padding: 0;
    }
    #AdminHeader{
      .adminHeaderWrapper{
        .logo{
          img{
          }
        }
        .otherStuff{
          .mobile-button{
            .i{}
          } 
        }
        .userLink{
          .user{
            .welcome{
            }
            .userName{
            }
          }
          .userImage{
            img{
            }
          }
        }
      }
    }
    #AdminContentWrapper{
      #AdminContent{
        .templateListWrapper.container-fluid{
          > .row{
            > .col{
              min-width: 100%;
            }
          }
        }
        .templateReports{
          .row{
            .col{
              min-width: 100%;
            }
          }
        }
        .reportTop{
          border: 1px solid rgb(221, 221, 221);
          border-left-width: 10px;
          border-radius: 5px;
          display: flex;
          background: #FFF;
          box-shadow: 0px 4px 4px #ececec;
          padding: 5px 0px;
          margin-bottom: 15px;
          align-items: center;
          justify-content: center;
          .reportIcon{
            font-size: 25px;
            color: #FFF;
            width: 40px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .reportText{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            > div{
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: center;
            }
            .reportTitle{
              font-size: 15px;
              flex: 1;
            }
            .reportValue{
              font-size: 25px;
              padding-right: 10px;
            }
          }
        }
      }
    }
    .card{
      overflow: auto;
    }
    .table.reportTable{
      .tableHeaders{
        height: 90px;
      }
      .tableBody{
        th.tableValues.tableValuesMain{
          .tableValue{
            line-height: 12px;
            strong {
              font-size: 10px;
            }
          }
        } 
      }
    }
  }
}



@media only screen and (max-width: 500px){
  #Admin{
    height: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .container-fluid .container-fluid{
      padding: 0;
    }
    #AdminHeader{
      .adminHeaderWrapper{
        .logo{
          img{
          }
        }
        .otherStuff{
          .mobile-button{
            .i{}
          } 
        }
        .userLink{
          .user{
            .welcome{
            }
            .userName{
            }
          }
          .userImage{
            img{
            }
          }
        }
      }
    }
    #AdminContentWrapper{
      #AdminContent{
        padding: 20px 5px;
        .templateListWrapper.container-fluid{
          &.navigationList{
            > .row{
              > .col{
                min-width: 0px;
              }
            }  
          }
          > .row{
            > .col{
              min-width: 100%;
            }
          }
        }
        .reportTop{
          border: 1px solid rgb(221, 221, 221);
          border-left-width: 10px;
          border-radius: 5px;
          display: flex;
          background: #FFF;
          box-shadow: 0px 4px 4px #ececec;
          padding: 5px 0px;
          margin-bottom: 15px;
          align-items: center;
          justify-content: center;
          .reportIcon{
            font-size: 20px;
            color: #FFF;
            width: 40px;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .reportText{
            > div {
            }
            .reportTitle{
              font-size: 13px;
              flex: 1;
            }
            .reportValue{
              font-size: 20px;
              padding-right: 10px;
            }
          }
        }
      }
    }
    .card{
      overflow: auto;
    }
    .table.reportTable{
      .tableHeaders{
        height: 90px;
      }
      .tableBody{
        th.tableValues.tableValuesMain{
          .tableValue{
            line-height: 12px;
            strong {
              font-size: 10px;
            }
          }
        } 
      }
    }
  }
}
