@import '../../global/colors.scss';
#Website{
  #PageGetStarted{
    #BuyNow{
      
    }
    #LetsBegin{
      > .container  {
        padding-bottom: 30px;
        padding-top: 50px;
      }
    }
    #FullSteps{
      .d-flex-800{
        display: none;
      }
      .d-none-800{
        display: flex;
      }
      .stepRow{
        justify-content: center;
        margin: 20px auto 40px;
        .imageColumn{
          max-width: 650px;
          .content{
            align-items: center;
            justify-content: center;
          }
        }
        .textZone{
          max-width: 500px;
          .preHeader2{
            background-color: $color5;
            color: #FFF;
            font-weight: 700;
            width: 100px;
            text-align: center;
            border-radius: 7px;
            padding: 5px;
            margin-bottom: 10px;
          }
          h4{
            font-size: 1.6rem;
            font-weight: 600;
          }
          &.textZoneLeft{
            text-align: right;
            .preHeader2{
              margin: 0 0 10px auto;
            }
          }
        }
      }
      .smallCenterText{
        margin: auto;
        max-width: 600px;
      }
    }
  }
  
  
  
  
  @media only screen and (max-width: 991px){
    #PageGetStarted{
      #FullSteps{
        .d-flex-800{
          display: flex;
        }
        .noPaddingContainer{
          padding-top: 40px;
          padding-bottom: 40px;
        }
        .d-none-800{
          display: none;
        }
        .stepRow{
          justify-content: center;
          margin: 20px auto 20px;
          .imageColumn{
            max-width: 100%;
            width: 100%;
            min-width: 90%;
            padding-bottom: 30px;
          }
          .textZone{
            max-width: 100%;
            text-align: center;
            .preHeader2{
              background-color: $color5;
              color: #FFF;
              font-weight: 700;
              width: 100px;
              text-align: center;
              border-radius: 7px;
              padding: 5px;
              margin: 0 auto 10px;
            }
            h4{
              font-size: 1.6rem;
              font-weight: 600;
            }
            &.textZoneLeft{
              text-align: center;
              .preHeader2{
                margin: 0 auto 10px;
              }
            }
          }
        }
        button.button.button1{
          margin: auto;
        }
        .smallCenterText{
          margin: auto;
          max-width: 600px;
        }
      }
    }
  }
  
  
  @media only screen and (max-width: 801px){
    #Content #PageGetStarted{
      .containerSection{
        padding-top: 30px;
        padding-bottom: 30px;
      }
      .container{
        padding-top: 20px;
        padding-bottom: 20px;
      }
      #FullSteps{
        br{
          display: none;
        }
      }
    }
  }
}