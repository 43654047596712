@import './colors.scss';
.editableImage{
	position: relative;
	* {
    max-height: 204px;
	}
	.fas.fa-trash-alt{
		z-index: 1;
		cursor: pointer;
    position: absolute;
    top: -8px;
    right: -8px;
    background-color: #FFF;
    width: 25px;
    height: 25px;
    border-radius: 100px;
    text-align: center;
    padding: 6px;
    border: 1px solid;
    font-size: 12px;
		&:hover{
			color: red;
		}
	}
	.upload__image-wrapper{
		position: relative;
		z-index: 2;
	}
	.underLay{
  	position: absolute;
  	left: 0;
  	right: 0;
  	top: 0;
  	bottom: 0;
  	background-image: linear-gradient(45deg, #808080 25%, transparent 25%), linear-gradient(-45deg, #808080 25%, transparent 25%), linear-gradient(45deg, transparent 75%, #808080 75%), linear-gradient(-45deg, transparent 75%, #808080 75%);
	  background-size: 20px 20px;
	  background-position: 0 0, 0 10px, 10px -10px, -10px 0px;
	  z-index: 1;
    opacity: .2;
  }
}
.dropZone{
	border: 1px solid #ddd;
  min-height: 202px;
  display: flex;
  align-items: center;
  justify-content: center;
  //background-color: #FFF;
  > div {
  	flex: 1;
  	&.image-item{
  		flex: inherit;
  	}
  }
	.newPicture {
    min-height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #ddd;
    cursor: pointer;
    &:hover{
    	background-color: $color1;
    	color: #FFF;
    }
    .newImageText{
	    pointer-events: none;
	    font-size: 20px !important;
	    font-weight: 500;
	    max-width: 130px;
	    text-align: center;
    }
  }
  .dropZoneText{
  	position: absolute;
		background: rgba(26, 176, 195,.79);
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		font-weight: 500;
		font-size: 20px !important;
		pointer-events: none;
		//opacity: .5;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		z-index: 10;
  }
}