@import '../../global/colors.scss';
@import '_leftMenu';
#MobileMenuAdmin{
  position: fixed;
  z-index: 20;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .animated{
    animation-duration: .4s;
  }
  .overlay{
    background-color: rgba(0,0,0,0.4);
    position: absolute;
    z-index: 21;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .sideMenu{
    position: absolute;
    z-index: 22;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    width: 90%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    .menuTitle{
      color: #FFF;
      font-size: 18px;
      padding: 4px 20px;
      font-weight: 500;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      display: flex;
      .title{
        flex: 1;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 600;
      }
      .exist{
        width: 20px;
        min-width: 20px;
        color: #FFF;
        font-size: 20px;
      }
    }
    .menus{
      @include leftMenu;
      .menu{
        .menu-item{
          width: 100%;
        }
      }
      // flex: 1;
      // flex-direction: column;
      // .menu-item{
      //   flex: unset;
      //   .nav-link{
      //     padding: 14px 25px;
      //     border-bottom: 1px solid rgba(255, 255, 255, .2);
      //     color: #FFF;
      //     font-weight: 500;
      //     &.active{
      //       color: $color1;
      //     }
      //   }
      // }
    }
  }
}


#MobileMenuSoft{
  position: absolute;
  z-index: 20;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .animated{
    animation-duration: .4s;
  }
  .overlay{
    background-color: rgba(0,0,0,0.4);
    position: absolute;
    z-index: 21;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .sideMenu{
    position: absolute;
    z-index: 22;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    //width: 90%;
    //max-width: 350px;
    display: flex;
    flex-direction: column;
    .menuTitle{
      color: #FFF;
      font-size: 18px;
      padding: 20px;
      font-weight: 500;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      display: flex;
      .title{
        flex: 1;
      }
      .exist{
        width: 20px;
        min-width: 20px;
        color: #FFF;
        font-size: 20px;
      }
    }
    .menu{
      flex: 1;
      flex-direction: column;
      .menu-item{
        flex: unset;
        .nav-link{
          padding: 14px 25px;
          border-bottom: 1px solid rgba(255, 255, 255, .2);
          color: #FFF;
          &.active{
            color: orange;
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 800px) and (min-width: 576px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 500px) {
  #MobileMenuAdmin{
    .animated{
    }
    .overlay{
    }
    .sideMenu{
      flex-direction: column;
      .menuTitle{
        color: #FFF;
        font-size: 14px;
        padding: 4px 20px;
        font-weight: 500;
        border-bottom: 1px solid rgba(255, 255, 255, 0.5);
        display: flex;
        .title{
          font-size: 18px;
        }
        .exist{
          width: 18px;
          min-width: 18px;
          font-size: 18px;
        }
      }
      .menus{
        height: 100%;
        background: $color6;
        position: relative;
        display: flex;
        transition: margin .5s ease;
        &.hideMenu{
          margin-left: -160px;
        }
        .sideMenuToggle{
          position: absolute;
          left: 100%;
          top: 0;
          background-color: $color5;
          color: #FFF;
          font-size: 25px;
          display: flex;
          width: 40px;
          height: 40px;
          align-items: center;
          justify-content: center;
          z-index: 10;
        }
        .nav-link.flash{
          -webkit-animation: ColorFlash .5s infinite ease-in-out !important;  /* Safari 4+ */
          -moz-animation: ColorFlash .5s infinite ease-in-out !important;  /* Fx 5+ */
          -o-animation: ColorFlash .5s infinite ease-in-out !important;  /* Opera 12+ */
          animation: ColorFlash .5s infinite ease-in-out !important;  /* IE 10+, Fx 29+ */
        }
        .menu{
          > .menu-item{
            //margin-bottom: 4px;
            > a.nav-link{
              padding: 14px 25px;
              .inline-nav-link{
                .icon{
                  font-size: 12px;
                }
                .text{
                  padding-left: 10px;
                  font-size: 12px;
                  .texttext{
                    font-size: 12px;
                  }
                  .todoNumber{
                    background-color: #FFF;
                    color: #000;
                    border-radius: 30px;
                    overflow: hidden;
                    min-width: 25px;
                    max-width: 25px;
                    min-height: 25px;
                    max-height: 25px;
                    text-align: center;
                    border: 1px solid #000;
                  }
                }
              }
            }
            .sub-menu-wrapper{
              &.no-trasition{
              }
              .sub-menu-item-wrapper{
                //padding: 5px 0 0 15px;
                > .sub-menu-item{
                  > a.nav-link{
                    padding: 8px 13px 8px 30px;
                    &.active{
                    }
                    .inline-nav-link{
                      .icon,.text{
                        font-size: 12px;
                      }
                      .text{
                        padding-left: 10px;
                        font-size: 12px;
                        .texttext{
                          font-size: 12px;
                          flex: 1;
                        }
                        .todoNumber{
                          padding-top: 1px;
                          font-size: 10px;
                          font-weight: 600;
                          background-color: #FFF;
                          color: #000;
                          border-radius: 30px;
                          overflow: hidden;
                          min-width: 20px;
                          max-width: 20px;
                          min-height: 20px;
                          max-height: 20px;
                          text-align: center;
                          border: 1px solid #000;
                        }
                        .userNumber{
                          background-color: rgba(0,0,0,.7);
                          border: 1px solid rgba(255,255,255,.3);
                          color: #FFF;
                        }
                      }
                    }
                  }
                }
              }
              .sub-sub-menu-wrapper{
                &.no-trasition{
                }
                .sub-sub-menu-item-wrapper{
                  //padding: 5px 0 0 15px;
                  > .sub-sub-menu-item{
                    > a.nav-link{
                      padding: 4px 13px 4px 40px;
                      font-weight: 400;
                      .inline-nav-link{
                        .icon,.text{
                        }
                        .text{
                          padding-left: 10px;
                          .texttext{
                            font-size: 11px;
                            flex: 1;
                          }
                          .todoNumber{
                            padding-top: 1px;
                            font-size: 10px;
                            font-weight: 600;
                            background-color: #FFF;
                            color: #000;
                            border-radius: 30px;
                            overflow: hidden;
                            min-width: 20px;
                            max-width: 20px;
                            min-height: 20px;
                            max-height: 20px;
                            text-align: center;
                            border: 1px solid #000;
                          }
                          .userNumber{
                            color: rgba(0,0,0,.7);
                            border: 1px solid rgba(255,255,255,.3);
                            color: #FFF;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}