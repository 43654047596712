@import '../../global/colors.scss';
.templateList{
	.title{
		.addNewButton{
			display: flex;
	    align-items: center;
	    justify-content: flex-end;
			button{
				margin: 0;
			}
		}
	}
	table{
		thead{
			&.thead-dark{
				tr{
					th{
						font-size: 12px;
						.headerColWrapper{
							&.active{
								color: $color2Light;
							}
              //min-width: 95px;
							cursor: pointer;
					    display: flex;
					    align-items: center;
              width: 100%;
							.headerColText{
                display: flex;
                .text{
  								//padding-right: 15px;
  								font-size: 12px;
                  flex: 1;
                  padding-right: 8px;
                }
								.icon{
						      position: relative;
                  margin: auto;
                  width: 10px;
                  height: 10px;
                  padding: 0;
                  display: block;
									i{
                    position: absolute;
                    top: 0;
                    bottom: 0;
                    right: 0;
                    left: 0;
                    margin: auto;
								    &.fa-sort-down{
						    	    margin-top: -5px;
								    }
								    &.fa-sort-up{
								    }
									}
								}//icon
							}

						}
					}
				}
			}
		}
		tbody{
			tr{
				th{
					padding: 0 5px;
					.rowText{
						display: flex;
				    align-items: center;
				    height: 42px;
					}
					div{
						font-size: 12px !important;
					}
				}
			}
		}
		.buttonCategory{
			width: 160px;
		}
		.buttonColumn{
	    align-items: flex-end;
	    justify-content: flex-end;
	    display: flex;
			.button{
		    padding: 6px 0;
		    max-width: 120px;
		    min-width: 120px;
		    justify-content: center;
		    align-items: center;
		    margin: 0;
		    flex: 1;
		    &:hover{
		    	.buttonIcon{
		    		i{
			    		color: #FFF;

		    		}
		    	}	
		    }
		    .buttonText{
		    	font-size: 12px;
		    	padding-right: 8px;
		    	font-weight: 600;
		    }
		    .buttonIcon{
		    	font-size: 12px;
		    }
			}
		}
	}
	.navigationList{
		.navButtons{
			&.prevButton{
				
			}
			&.nextButton{
		    display: flex;
		    justify-content: flex-end;
			}
		}
		.curNumber{
			//margin-top: 15px;
			.navigationTracker{
				text-align: center;
				display: flex;
		    align-items: center;
		    height: 65px;
		    justify-content: center;
			}
		}
	}
}


@media only screen and (max-width: 500px){
  .templateList{
    .title{
      .addNewButton{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        button{
          margin: 0;
        }
      }
    }
    table{
      thead{
        &.thead-dark{
          tr{
            th{
              font-size: 12px;
              .headerColWrapper{
                &.active{
                  color: $color2Light;
                }
                //min-width: 95px;
                cursor: pointer;
                display: flex;
                align-items: center;
                width: 100%;
                .headerColText{
                  display: flex;
                  .text{
                    //padding-right: 15px;
                    font-size: 12px;
                    flex: 1;
                    padding-right: 8px;
                  }
                  .icon{
                    position: relative;
                    margin: auto;
                    width: 10px;
                    height: 10px;
                    padding: 0;
                    display: block;
                    i{
                      position: absolute;
                      top: 0;
                      bottom: 0;
                      right: 0;
                      left: 0;
                      margin: auto;
                      &.fa-sort-down{
                        margin-top: -5px;
                      }
                      &.fa-sort-up{
                      }
                    }
                  }//icon
                }

              }
            }
          }
        }
      }
      tbody{
        tr{
          th{
            padding: 0 5px;
            .rowText{
              display: flex;
              align-items: center;
              height: 42px;
            }
            div{
              font-size: 12px !important;
            }
          }
        }
      }
      .buttonCategory{
        width: 160px;
      }
      .buttonColumn{
        align-items: flex-end;
        justify-content: flex-end;
        display: flex;
        .button{
          padding: 6px 0;
          &:hover{
            .buttonIcon{
              i{
                color: #FFF;

              }
            } 
          }
          .buttonText{
            font-size: 12px;
            padding-right: 8px;
            font-weight: 600;
          }
          .buttonIcon{
            font-size: 12px;
          }
        }
      }
    }
    .navigationList{
      .navButtons{
        button.button{
          min-width: 80px;
          font-size: 12px;
          padding: 5px 10px;
        }
        &.prevButton{
          
        }
        &.nextButton{
          display: flex;
          justify-content: flex-end;
        }
      }
      .curNumber{
        //margin-top: 15px;
        .navigationTracker{
          div{
            font-size: 12px;
          }
          height: 65px;
        }
      }
    }
  }
}

