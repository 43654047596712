@import './colors.scss';
///Users/danielezaldivar/Sites/sonrisas/src/global/components/FormBuilder/comp-notesList.js
.noteList{
  display: flex;
  height: 100%;
  flex-direction: column;
  .noteListTitle{

  }
  .notesListInputWrapper{
    flex: 1;
    overflow: auto;
    border: 2px solid $color4;
    border-radius: 5px;
    padding: 0 10px;
    .noNoteFound{
      height: 100%;
      width: 100%;
      display: flex;
      .editNoteTrigger{
        flex: 1;
        align-items: center;
        justify-content: center;
        display: flex;
      }
    }
    .notesListForm{
      padding-bottom: 40px;
      hr:last-child{
        display: none;
      }
      .noteForm{
        //padding: 0 10px;
        border: 1px solid $color1;
        border-radius: 5px;
        padding: 10px;
        position: relative;
        margin-top: 10px;
        &.redBorder{
          border-color: $color5;
          .noteDates .noteDate, .noteStatus{
            color: $color5;
          }
          .noteEdit{
            border-color: $color5;
            background-color: $color5;
            &:hover{
              background-color: $color5Dark;
            }
          }
        }
        &.INACTIVE{
          opacity: .6;
          border-color: #ccc;
        }
        .emph{
          font-weight: 500;
          color: #000;
        }
        .noteTitle{
          font-weight: 600;
          text-transform: uppercase;
        }
        .noteMessage{
          padding-bottom: 5px;
          padding-right: 20px;
          font-size: 13px;
        }
        .noteDates{
          display: flex;
          flex-wrap: wrap;
          font-weight: 500;
          *{
            font-size: 10px;
          }
          .noteDateFirst{
            flex: 1;
          }
          .noteDate{
            color: $color1Dark;
            flex: 1;
          }
          .noteStatus{
            flex: 1;
          }
        }
        .noteStatus{
          color: $color1Dark;
         //font-size: 12px; 
        }
        .noteEdit{
          border: 1px solid $color1;
          background-color: $color1;
          color: #FFF;
          width: 25px;
          border-top: none;
          border-right: none;
          border-radius: 0 0 0 4px;
          position: absolute;
          top: 0px;
          right: 0px;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          i{
            font-size: 12px;
          }
          &:hover{
            background-color: $color1Dark;
          }
        }
      }
    }
  }
  .newNoteButton{
    width: 100%;
    margin-bottom: 10px;
  }
}