@import './colors.scss';
#CheckoutPageComponent{
  img{
    max-width: 100%;
  }
  .content {
    height: 100%;
  }
  .customCheckoutRight{
    max-width: 400px;
    > .content{
      min-height: 100%;
      height: 100%;
      background: #FFF
    }
  }    
  #CartContent{
    #Summary{
      .summary{
        max-width: 400px;
        margin: 0 0 0 auto;
      }
    }
  }
  #CheckoutPageComponentRightSide{
    //min-width: 400px;
    #NothingFound{
      display: flex;
      align-items: center;
      justify-content: center;
      height: 400px;
    }
    .sectionTitle{
      font-size: 20px;
      font-weight: 500;
    }
    .consentMessage{
      display: flex;
      padding: 5px 0 20px 0;
      cursor: pointer;
      &:hover{
        .checkBoxIcon{
          color: $color1;
        }
      }
      .checkBoxIcon{
        font-size: 30px;
        padding-right: 5px;
        
      }
      .consentMessageText{
        padding-left: 10px;
      }
    }
    .paypalButtonsWrapper{
    }

    #CheckoutBreadcrumbs{
      padding: 30px 0 50px;
      .checkoutBreadcrumbSteps{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        .stepBorder{
          border-bottom: 2px solid $color1;
          position: absolute;
          top: 50%;
          left: 40px;
          right: 40px;
          height: 2px;
          transform: translate(0px,-50%);
        }
        .steps{
          display: flex;
          flex: 1;
          justify-content: space-between;
          .step{
            text-align: center;
            cursor: pointer;
            //flex: 1;
            top: 13px;
            width: 90px;
            position: relative;
            &.active{
              color: $color1;
              i{
                color: $color1;
              }
            }
            &.done{
              i{
                box-shadow: 0 0 0px 2px $color1;
              }
            }
            i{
              border: 1px solid #FFF;
              box-shadow: 0 0 0px 2px #ddd;
              color: #ddd;
              border-radius: 100px;
            }
            div{
              font-weight: 500;
            }
          }
        }
      }
    }
    #CheckoutSteps{
      position: relative;
      overflow: hidden;
      min-height: 700px;
      .stepWrapper{
        animation-duration: .5s;
        &.moving{
          position: absolute;
        }
        top: 0;
      }
    }

  }//End of CheckoutPageComponentRightSide
}


#CheckoutDialogContent{
  .dialog-body{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .BasicDialogContent{
    text-align: center;
    .text1{
      font-size: 45px;
      margin-bottom: -10px;
      font-weight: 500;
    }
    .text2{
      font-size: 28px;
      padding-bottom: 25px;
    }
    .text3{
      font-size: 16px;
    }
  }
  .footer {
    > div{
      text-align: right;
    }
  }
}

@media only screen and (max-width: 991px) {
  #CheckoutPageComponent{
    padding: 0 0 2% 0;
    .customCheckoutRight{
      max-width: 100%;
      min-width: 100%;
    }    
    #CartContent{
      #Summary{
        .summary{
          max-width: 100%;
          margin: 0 0 0 auto;
        }
      }
    }
    #CheckoutPageComponentRightSide{
      //min-width: 400px;
      #NothingFound{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 400px;
      }
      .sectionTitle{
        font-size: 20px;
        font-weight: 500;
      }
      .consentMessage{
        display: flex;
        padding: 5px 0 20px 0;
        cursor: pointer;
        &:hover{
          .checkBoxIcon{
            color: $color1;
          }
        }
        .checkBoxIcon{
          font-size: 35px;
          padding-right: 5px;
          
        }
        .consentMessageText{
        }
      }
      .paypalButtonsWrapper{
      }

      #CheckoutBreadcrumbs{
      }
      #CheckoutSteps{
      }

    }//End of CheckoutPageComponentRightSide
    #SideSummaryColumn{
    }
  }
}

@media only screen and (max-width: 600px) {
  
}

@media only screen and (max-width: 480px) {
}