@import '../../global/colors.scss';
#Website{
  #PagePromo{
    min-height: 1000px;
    display: flex;
    flex-direction: column;
    #TopMainSection{
      flex: 1;
    }
    .mainImage{
      padding-right: 20px;
      //max-width: 150px;
      margin: auto;
      img{
        border-radius: 30px;
        overflow: hidden;
      }
    }
    .messageBox{
      min-height: 300px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #eee;
      text-align: center;
      padding: 20px;
      border-radius: 5px;
      border-width: 2px;
      border-color: $color1;
      .messageText{
        padding: 30px 0;
        width: 100%;
        .icon{
          font-size: 50px;
        }
        h2{
          //font-size: 30px;
          text-align: center;
          text-transform: uppercase;
          margin-bottom: 0px;
          font-weight: 700;
        }
        h3{
          font-size: 24px;
          text-align: center;
          //text-transform: uppercase;
          margin-bottom: 10px;
          font-weight: 600;
        }
        div.messageTextDesc{
          text-align: center;
          font-weight: 400;
          width: 500px;
          margin: auto;
          max-width: 100%;
          padding-bottom: 20px;
        }
        .actionDiv{
          font-weight: 800;
          font-size: 26px;
          text-transform: uppercase;
        }
  
        .downArrow{
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 90px;
          position: relative;
          i{
            cursor: pointer;
            position: absolute;
            left: 0;
            right: 0;
            top: 10px;
            margin: auto;
            animation: pulse 2s ease-out infinite;
            width: 94px;
            text-align: center;
            border: 2px solid $color1;
            border-radius: 130px;
            background: white;
            transition: border .5s ease;
            &:hover{
              border-color: $color5;
            }
          }
        }
      }
    }
    #TreatmentPlanBar.TreatmentPlanUnlocked{
      border: none !important;
      box-shadow: 0px 0px 30px #b3b3b3;
      border-radius: 30px;
      margin: auto;
      .container{
        padding: 40px;
      }
      .PreHeaderText{
        color: $color5;
        font-weight: 600;
        font-size: 18px;
      }
      .productLayout1{
        .productImageWrapper{
          flex: 1;
          max-width: 100%;
          .productImage{
            max-width: 400px;
            margin: auto;
            img{
              border: none;
            }
          }
        }
        .productText{
          max-width: 500px;
          .productTextName{
            line-height: 33px;
            font-size: 30px;
            padding-bottom: 5px;
          }
          .productTextCost{
            padding-bottom: 10px;
          }
          .buttonGroup .button{
            width: 100%;
          }
        }
        @media only screen and (max-width: 991px){
          .productText{
            max-width: 100%;
            .productTextName{
              line-height: 28px;
              font-size: 25px;
              padding-bottom: 5px;
            }
            .productTextCost{
              padding-bottom: 10px;
            }
            .buttonGroup .button{
              width: 100%;
            }
          }
        }
      }
    }
    #StepsZone{
      padding-top: 0;
      .stepsZone{
        text-align: center;
        padding: 50px 0 20px;
        //border-top: 1px solid $color1;
      }
    }
    #SimpleStepsList{
      border: none;
      display: flex;
      overflow-y: scroll;
      width: calc( 100% );
      scroll-snap-type: x mandatory;
      -ms-overflow-style: none;  /* IE and Edge */
      scrollbar-width: none;  /* Firefox */
      max-width: 710px;
      margin: auto;
      &::-webkit-scrollbar {
        display: none;
      }
      .stepWrapper{
        min-width: calc( 100% );
        scroll-snap-align: start;
        scroll-snap-stop: always;
        margin: 0 20px;
        padding-bottom: 20px;
        .step{
          display: flex;
          flex-direction: row;
          border: 1px solid $color1;
          border-radius: 10px;
          overflow: hidden;
          padding: 10px;
        }
      }
      .subHeader{
        max-width: 960px;
        margin: auto;
      }
      .image{
        img{
          width: 200px;
        }
        padding-right: 20px;
      }
      .textWrapper{
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: center;
        .text{
          flex: 1;
          .textHeader{
            line-height: 20px;
            min-height: 30px;
            padding-bottom: 10px;
            padding-top: 10px;
            .textHeaderStep{
              padding-top: 15px;
              padding-bottom: 6px;
              font-weight: 800;
              font-size: 20px;
              min-width: 80px;
              padding-right: 10px;
              display: inline;
            }
            .textHeaderTitle{
              padding-top: 15px;
              padding-bottom: 6px;
              font-size: 20px;
              font-weight: 600;
              color: $color5;
              flex: 1;
              min-width: 160px;
              display: inline;
            }
          }
          .textPostHeader{
            //min-height: 100px;
            font-size: 16px;
          }
        }
      }
    }
    @media only screen and (max-width: 767px){
      .codeZone{
        width: 100%;
      }
    }
    .codeZone{
      .subHeader{
        //max-width: 500px;
        //margin: auto;
      }
      #ReferralCodeSearch{
        //max-width: 900px;
        //margin: auto;
      }
    }
    #BuyKitButton{
      padding-top: 0;
      .content{
        display: flex;
        align-items: center;
        justify-content: center;
        .scrollButton{
          cursor: pointer;
          &:hover{
            .scrollButtonText, .downArrow i, .upArrow i{
              border-color: $color5;
              transition: border .5s ease;
            }
          }
          .scrollButtonText{
            font-weight: 800;
            font-size: 26px;
            text-transform: uppercase;
            border: 2px solid $color1;
            padding: 20px 10px 20px 10px;
            border-radius: 10px;
            margin-bottom: -40px;
            width: 350px;
            max-width: 100%;
            text-align: center;
          }
          .upArrow{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 90px;
            height: 40px;
            position: relative;
            i{
              position: absolute;
              left: 0;
              right: 0;
              bottom: -25px;
              margin: auto;
              animation: pulse 2s ease-out infinite;
              width: 94px;
              text-align: center;
              border: 2px solid $color1;
              border-radius: 130px;
              background: white;
            }
          }
          .downArrow{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 90px;
            height: 120px;
            position: relative;
            i{
              position: absolute;
              left: 0;
              right: 0;
              top: 10px;
              margin: auto;
              animation: pulse 2s ease-out infinite;
              width: 94px;
              text-align: center;
              border: 2px solid $color1;
              border-radius: 130px;
              background: white;
            }
          }
        }
      }
    }
    #WhatsNextHeader{
      text-align: center;
      padding-bottom: 0;
      margin-bottom: -40px;
    }
    #ImpressionKitContainer{
      padding-top: 0;
      padding-bottom: 0;
      //margin-bottom: 100px;
      #TreatmentPlanBar{
        //border-bottom: none !important; 
      }
      .orSpacer{
        text-align: center;
        font-size: 30px;
        font-weight: 600;
        text-transform: uppercase;
        border-top: 5px solid $color5;
        border-bottom: 5px solid $color5;
        padding: 20px 10px 20px 10px;
        margin-bottom: -40px;
        width: 200px;
        max-width: 100%;
        text-align: center;
        margin: 20px auto;
      }
      #ImpressionKitBar{
        border-top: none !important; 
      }
    }
  
  
    .smallReport{
      border: 3px solid $color5;
      border-radius: 15px;
      padding: 20px;
      .header{
        border: 1px solid transparent;
        &:hover{
          border-color: $color1;
        }
        div{
          font-weight: 600;
          font-size: 20px;
        }
      }
      .curReports{
  
      }
      .reportItem{
        padding-left: 20px;
      }
      #DateFormInput{
        display: flex;
        margin: 0 -20px;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        .FormBuilder{
          flex: 1;
          display: flex;
          .form-group{
            flex: 1;
            padding: 0 20px;
          }
        }
        button.button{
          margin: 10px 20px 0;
          width: 250px;
          height: 37px;
        }
      }
    }
    h3{
      font-weight: 600;
      font-size: 1.55rem;
      padding-top: 10px;
    }
    .monthlyReport{
      .chart{
        border: 3px solid $color4;
        border-radius: 15px;
        padding: 20px;
      }
    }
    .summaryBox{
      border: 3px solid $color1;
      background-color: lighten($color1, 50%);
      text-align: center;
      border-radius: 15px;
      padding: 20px;
      .title{
        font-size: 25px;
        font-weight: 600;
        text-transform: uppercase;
      }
      .summaryBoxNumbers{
        display: flex;
        .summaryBoxNumber{
          flex: 1;
          .summaryBoxNumberNumber{
            font-size: 50px;
            font-weight: 600;
          }
          .summaryBoxNumberTitle{
            font-size: 16px;
            font-weight: 400;
          }
        }
      }
    }
  
  }
  
  
  @keyframes pulse {
    0% {
      transform: scale(.8); 
      //opacity: 0;
    }
    50% {
      transform: scale(1);
      //color: blue;
    }
    100% { 
      transform: scale(.8); 
      //opacity: 1;
    }
  }
  
  
  
  @media only screen and (max-width: 550px){
    #PagePromo #SimpleStepsList .stepWrapper .step{
      display: block;
      text-align: center;
    }
  }
}