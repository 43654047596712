@import '../../global/colors.scss';
#Website{
  #PageBestAligners{
    .subHeaderBasic{
      font-size: 17px;
      font-weight: 500;
    }
    .bottomImages{
      padding-bottom: 20px;
    }
    #FullSteps{
      .text{
        font-size: 16px;
        div{
          padding-bottom: 10px;
        }
        // *{
        // 	font-size: inherit;
        // }
      }
    }
  
  }
  
  
  #UsVsThem{
    .usVsThemCompare{
      display: flex;
      width: 100%;
      position: relative;
      .handNotification{
        position: absolute;
        top: 50%;
        margin: auto;
        font-size: 50px;
        z-index: 10;
        opacity: 0;
        pointer-events: none;
        i{
          position: absolute;
          &.far{
            z-index: 2;
          }
          &.fas{
            z-index: 1;
            color: #FFF;
          }
        }
        &.firstLoop{
          right: 100px;
          animation: movehandLeft 1.2s ease-out infinite;
        }
        &.swipeLeft{
          right: 100px;
          animation: movehandRight 1.2s ease-out infinite;
          animation-iteration-count: 4;
        }
        &.swipeRight{
          right:  100px;
          animation: movehandLeft 1.2s ease-out infinite;
          animation-iteration-count: 4;
        }
        
      }
      .sections{
        flex: 1;
        min-width: 200px;
        border-bottom: 1px solid #DDD;
        .sectionRow{
          padding: 10px;
          font-size: 16px;
          font-weight: 600;
          height: 70px;
          border-top: 1px solid rgba(0,0,0,.2);
          display: flex;
          align-items: center;
          background-color: #f5f5f5; 
          border-left: 1px solid #DDD;
          //justify-content: center;
          &.first{
            height: 100px;
            background-color: transparent;
            border: none;
          }
        }
      }
      .compare{
        display: flex;
        overflow: auto;
        border: 1px solid #DDD;
        //border-left: none;
        .compareColumn{
          background-color: #fff;
          border-left: 1px solid #DDD;
          .logo-image{
            flex: 1;
          }
          &.active{
            border-left: 1px solid $color1;
            border-right: 1px solid $color1;
            margin-right: -1px;
            z-index: 1;
            box-shadow: 0 0 0 3px $color1 inset;
            .sectionRow{
              font-weight: 700;
              font-size: 16px;
              color: #000;
              &.first{
                background-color: lighten($color1, 50%);
                box-shadow: 0 0 0 3px $color1 inset;
                color: #fff;
                font-size: 25px;
                font-weight: 700;
              }
            }
          }
          .sectionRow{
            font-weight: 600;
            padding: 10px;
            font-size: 14px;
            height: 70px;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 210px;
            border-top: 1px solid #ddd;
            color: #616161;
            text-transform: uppercase;
            &.color5 {
              color: #eb008b;
            }
            &.first{
              color: #000;
              height: 100px;
              border-top: none;
              .titleText{
                font-weight: 700;
                font-size: 18px;
                text-align: center;
                max-width: 160px;
                margin: auto;
                line-height: 1.3;
              }
            }
            .checkBox{
              color: orange;
              font-size: 25px;
            }
          }
        }
      }
    }
  }//usVsThemCompare
  
  @keyframes movehandLeft {
    0% {
      transform: translateX(50px); 
      opacity: 0;
    }
    50% {
      opacity: 1;
      //color: blue;
    }
    100% { 
      transform: translateX(-50px); 
      opacity: 1;
    }
  }
  
  @keyframes movehandRight{
    0% {
      transform: translateX(-50px); 
      opacity: 0;
    }
    50% {
      opacity: 1;
    }
    100% { 
      transform: translateX(50px); 
      opacity: 1;
    }
  }
  
  
  @media only screen and (max-width: 500px){
    #UsVsThem{
      .usVsThemCompare{
        display: flex;
        width: 100%;
        .sections{
          flex: 1;
          min-width: 120px;
          .sectionRow{
            padding: 10px;
            font-size: 14px;
            font-weight: 600;
            height: 70px;
            border-top: 1px solid rgba(0,0,0,.2);
            display: flex;
            align-items: center;
            background-color: #f5f5f5; 
            border-left: 1px solid #DDD;
            //justify-content: center;
            &.first{
              height: 100px;
              background-color: transparent;
              border: none;
            }
          }
        }
        .compare{
          .compareColumn{
            .sectionRow{
              width: 175px;
            }
          }
        }
      }//usVsThemCompare
    }
  }
}