@import './colors.scss';
#ContactDialog{
	.formWrapper{
		min-height: 100%;
	}
	#SuccessMessage{
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 100%;
		.messageText{
			.logoHeader{
				max-width: 400px;
				margin: 10px auto 30px;
			}
		}
	}
}


@media only screen and (max-width: 800px) and (min-width: 576px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 480px) {
}