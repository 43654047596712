@import '../../global/colors.scss';
#DoctorWrapper{
  #NavMenu{
    background-color: white;
    width: 210px;
    display: flex;
    flex-direction: column;
    min-height: 0px;
    .siteLogo{
      display: block;
      padding: 35px 25px 0;
    }
    .menu {
      padding: 15px;
      flex: 1;
      &.otherButtons{
        flex: none;
        .menu-item{
          a{
            background-color: $color5;
            color: #FFF;
            .inline-nav-link{
              .icon{
                background-color: rgba(0,0,0,.5);
                font-size: 14px;
                display: flex;
                align-items: center;
                justify-content: center;
              }
              .text{
              }
            }
            &:hover{
              background-color: darken($color5Dark, 5%);
            }
          }
        }
        padding-bottom: 20px;
      }
      .menuTitle{
        font-weight: 600;
        font-size: 18px;
        padding: 20px 0;
      }
      .menu-item{
        a{
          text-align: center;
          color: #333333;
          //min-height: 90px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          border-radius: 10px;
          margin-bottom: 5px;
          &:hover{
            background-color: lighten($color1, 45%);
          }
          &.active{
            background-color: lighten($color1, 35%);
            .inline-nav-link{
              .icon{
              }
              .text{
                
              }
            }
          }
          .inline-nav-link{
            flex: 1;
            display: flex;
            text-align: left;
            align-items: center;
            .icon{
              width: 30px;
              font-size: 18px;
              text-align: center;
              height: 30px;
              border-radius: 5px;
              background-color: #FFF;
            }
            .text{
              padding-left: 10px;
              flex: 1;
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
    }
}
}


@media only screen and (max-width: 850px) {
  #DoctorWrapper{
    #NavMenu{
      display: none;
    }
  }
}