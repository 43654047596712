@import '../../global/colors.scss';
#Dashboard{
	.topDown {
    writing-mode: vertical-lr;
    direction: rtl;
    writing-mode: vertical-rl;
    text-align: right;
    //transform: rotate(225deg);
    //width: 0px;
  }
  tr th.activeCol{
    background-color: lighten($color5, 45%);
  }
  table.table tbody.tableBody tr:hover th.activeCol{
    background-color: lighten($color4, 20%);
  }
  #DeepPageTracking{
    .pageHeader{
      display: flex;
      justify-content: space-between;
      border-bottom: 3px solid $color1;
      padding-top: 10px;
      padding-bottom: 5px;
      strong{padding-right: 10px;}
      .pageName{
        
      }
      .pageURL{
        text-align: right;
        font-size: 12px;
      }
    }
    .reportTable{
      .tableHeaders{
        .tableHeader{
          font-size: 10px;
          text-align: center;
          strong{
            font-size: 10px;
          }
          &.tableHeaderMain{          
          }
          &.tableHeaderOther{    
            width: 0;
            i{
              font-size: 25px;
            }      
          }
          .categoryIcon{
            position: relative;
            display: block;
            width: 25px;
            height: 25px;
            text-align: center;
            .category{
              font-size: 12px;
              position: absolute;
              transform: rotate(310deg);
              text-align: left;
              white-space: nowrap;
              bottom: 25px;
              left: 14px;
              transform-origin: bottom left;
              display: block;
            }
          }
        }
      }
      .tableBody{
        .tableValue{
          &.category{
            strong{
              font-size: 11px;
            }
          }
        }
        .tableValuesNum{
          
        }
        .tableValues{
          &.tableValuesMain{}
          &.tableValuesOthers{}
          .tableValue{
          }
        }
      }
    }
  }
  .reportTable{
    .tableHeaders{
      .tableHeader{
        &.tableHeaderMain{          
        }
        &.tableHeaderOther{    
          width: 0;
          i{
            font-size: 25px;
          }      
        }
        .categoryIcon{
          position: relative;
          display: block;
          width: 25px;
          height: 25px;
          text-align: center;
          .category{
            font-size: 12px;
            position: absolute;
            transform: rotate(310deg);
            text-align: left;
            white-space: nowrap;
            bottom: 25px;
            left: 14px;
            transform-origin: bottom left;
            display: block;
          }
        }
      }
    }
    .tableBody{
      .tableValuesNum .tableValue{
        text-align: center;
      }
      .tableValues{
        &.tableValuesMain{}
        &.tableValuesOthers{}
        .tableValue{
        }
      }
    }
  }
  .newUserButtons{
    button.button{
      //max-width: 250px;
      width: 100%;
      font-weight: 700;
      text-transform: uppercase;
      position: relative;
      i{
        position: absolute;
        top: 2px;
        right: -2px;
        font-size: 38px;
      }
    }
  }
}