@import './colors.scss';
.downloadButtonComponent{
  width: 100%;
  min-width: 0;
  max-width: 100%;
  background-color: $color1;
  color: #FFF;
  &:hover{
    background-color: $color1Dark;
    color: #FFF;
  }
	&.new{

  }
  &.downloading{
    opacity: .7;
    pointer-events: none;
  }
  &.downloaded{
    background-color: #FFF;
    border-color: $color5;
    color: $color5;
    &:hover{
      background-color: $color5Dark;
      color: #FFF;
    }
  }
}


@media only screen and (max-width: 800px) and (min-width: 576px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 480px) {
}