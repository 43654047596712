@import '../../global/colors.scss';

#MobileMenu{
  position: fixed;
  z-index: 20;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .animated{
    animation-duration: .4s;
  }
  .overlay{
    background-color: rgba(0,0,0,0.4);
    position: absolute;
    z-index: 21;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .sideMenu{
    position: absolute;
    z-index: 22;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #000;
    width: 90%;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    .menuTitle{
      color: #FFF;
      font-size: 18px;
      padding: 20px;
      font-weight: 500;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      display: flex;
      .title{
        flex: 1;
        font-size: 20px;
        text-transform: uppercase;
        font-weight: 600;
      }
      .exist{
        width: 20px;
        min-width: 20px;
        color: #FFF;
        font-size: 20px;
      }
    }
    .menu{
      flex: 1;
      flex-direction: column;
      .menu-item{
        flex: unset;
        .nav-link{
          padding: 14px 25px;
          border-bottom: 1px solid rgba(255, 255, 255, .2);
          color: #FFF;
          font-weight: 500;
          &.active{
            color: $color1;
          }
        }
      }
    }
  }
}


#MobileMenuSoft{
  position: absolute;
  z-index: 20;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  .animated{
    animation-duration: .4s;
  }
  .overlay{
    background-color: rgba(0,0,0,0.4);
    position: absolute;
    z-index: 21;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  .sideMenu{
    position: absolute;
    z-index: 22;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    //width: 90%;
    //max-width: 350px;
    display: flex;
    flex-direction: column;
    .menuTitle{
      color: #FFF;
      font-size: 18px;
      padding: 20px;
      font-weight: 500;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      display: flex;
      .title{
        flex: 1;
      }
      .exist{
        width: 20px;
        min-width: 20px;
        color: #FFF;
        font-size: 20px;
      }
    }
    .menu{
      flex: 1;
      flex-direction: column;
      .menu-item{
        flex: unset;
        .nav-link{
          padding: 14px 25px;
          border-bottom: 1px solid rgba(255, 255, 255, .2);
          color: #FFF;
          &.active{
            color: orange;
          }
        }
      }
    }
  }
}


@media only screen and (max-width: 800px) and (min-width: 576px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 480px) {


}