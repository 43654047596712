@import './colors.scss';
.PreviewForm{
  padding: 10px 20px;
  #ErrorNotes{
    border: 4px solid red;
    h2{
      font-size: 23px;
      font-weight: 600;
      margin: 0 0 10px 0;
    }
    padding: 20px;
    font-size: 16px;
  }
  .col{
    &.noPadding{
      padding-bottom: 0;
    }
    padding-bottom: 40px;
    > .content{
      padding: 10px;
    }
    &.borderColor1 .previewFormFieldTitles2{
      border-color: $color1;
    }
    &.borderColor2 .previewFormFieldTitles2{
      border-color: $color2;
    }
    &.borderColor3 .previewFormFieldTitles2{
      border-color: $color3;
    }
    &.borderColor4 .previewFormFieldTitles2{
      border-color: $color4;
    }
    &.borderColor5 .previewFormFieldTitles2{
      border-color: $color5;
    }
  }
  .previewFormTitle{
    font-size: 24px;
    font-weight: 600;
  }
  .previewFormSubTitle{
    font-size: 16px;
    color: #545454;
    padding-bottom: 10px;
    margin-bottom: 30px;
    border-bottom: 4px solid $color1;
  }
  .previewFormFieldTitles2{
    padding: 10px;
    margin: 0 -10px 10px -10px;
    border: 1px solid;
    border-radius: 10px;
    border-left: 6px solid;
    border-color: $color4;
    .previewFormFieldTitle{
      font-weight: 700;
      font-size: 16px;
      text-transform: uppercase;
    }
    .previewFormFieldSubTitle{
      font-size: 12px;
    }
  }
  .previewFormFieldTitles{
    padding: 10px 0;
    border-bottom: 3px solid;
    border-color: #333;
    margin-bottom: 20px;
    .previewFormFieldTitle{
      font-weight: 700;
      font-size: 16px !important;
      text-transform: uppercase;
    }
    .previewFormFieldSubTitle{
      font-size: 12px;
    }
    .viewFullButtonWrapper{
      padding-left: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      .viewFullButton{
        margin: 0;
        font-size: 12px;
        min-width: 110px;
        padding: 6px 10px;
      }
    }
  }
  .previewFormField, .previewFormGroup{
    padding: 10px 0;
    .previewFormFieldName{
      font-weight: 600;
      font-size: 13px;
    }
    .previewFormFieldValue{
      border-bottom: 1px solid $borderColor;
      padding: 6px 0;
      //color: #545454;
      font-size: 13px;
      a, div{
        font-size: 13px;
      }

      .productsList{
        display: flex;
        flex-wrap: wrap;
        .image{
          max-width: 200px;
          img{
            max-width: 100%;
          }
        }
        .textWrapper{
          flex: 1;
          padding-left: 10px;
          .text{
            padding: 5px 0;
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            .titleText{
              font-weight: 600;
              width: 100px;
              font-size: 12px;
              color: #333;
              padding: 4px 10px 4px 0;
            }
            .value{
              flex: 1 1;
              border: 1px solid $borderColor;
              font-size: 12px;
              padding: 4px 10px;
              border-radius: 4px;
              min-width: 200px;
            }
          }
        }
      }
    }
    &.previewFormFieldInline{
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid #ddd;
      .previewFormFieldValue{
        border: none;
        padding: 0;
      }
    }
  }
  .previewFormGroup{
    flex-wrap: wrap;
    > .previewFormFieldName{
      width: 100%;
    }
  }
  .previewFormFieldArray{
    display: flex;
    flex-wrap: wrap;
    .previewFormFieldArrayItem{
      flex: 1;
      max-width: 50%;
      min-width: 500px;
    }
  }
  .image{
    img{
      max-width: 100%;
    }
  }
  .downloadLinksList{
    .downloadLink{
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #ddd;
      border-radius: 8px;
      padding: 8px 12px;
      .downloadLinkTextWrapper{
        flex: 1;
        .downloadLinkText{
          font-size: 14px;
        }
      }
      .downloadLinkButtons{
        .button{
          &.downloaded{
            color: $color1;
            background-color: #FFF;
            border-color: $color1;
          }
          margin: 0;
        }
      }
    }
  }
  .keyValueListPreview{
    width: 100%;
    padding: 10px 10px;
    .keyValueListPreviewItem{
      display: flex;
      border: 1px solid #ddd;
      border-left: 5px solid $color1;
      margin-bottom: 10px;
      padding: 5px 10px;
      .keyValueListPreviewItemKey{
        margin-right: 20px;
        font-size: 13px;
        font-weight: 600;
      }
      .keyValueListPreviewItemValue{
        flex: 1;
        font-size: 13px;
      }
    }
  }
  .accordion{
    border: 2px solid $color1;
    .previewFormFieldTitle{
      color: #000;
    }
    .accordionComponent{
      .faq-box{
        .faq-title{
          background-color: $color1;
          color: #FFF;
        }
      }
    }
  }
  .inlineFile{
    margin: -10px 0 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .textWrapper{
      .title{
        font-size: 15px;
        font-weight: 500;
        color: #000;
      }
      .id {
        font-size: 12px;
      }
      .createAt{
        font-size: 12px;
      }
    }
    .buttonWrapper{
      .button.button1{
        padding: 8px 15px;
        font-size: 12px;
        min-width: 100px;
        margin: 0;
      }
    }
  }


  .alignerGroups{
    overflow: auto;
    height: 500px;
    min-height: 500px;
    max-height: 500px;
    &.single{
      height: auto;
      min-height: 0;
      max-height: none;
      .group{
        .accordionZone{
          .accordionZoneInner{
            > .cont{
              padding: 10px 10px 15px 10px;
              .info{
                &.infoTop{
                  .text{
                  }
                }
                .text{
                  .desc{
                    font-weight: 600;
                    padding-right: 15px;
                    min-width: 100px;
                    font-size: 14px;
                    text-align: right;
                  }
                  .val{
                    flex: 1;
                    overflow: auto;
                    
                    font-size: 14px;
                  }
                }
              }
            }
          }
        }
      }
    }
    .group{
      border: 2px solid $color5;
      margin-bottom: 20px;
      .title{
        display: flex;
        cursor: pointer;
        align-items: center;
        padding: 10px 15px;
        &:hover{
          background-color: lighten($color5, 51%);
        }
        .textZone{
          display: flex;
          flex: 1;
          align-items: center;
          .checkWrapper{
            display: flex;
            align-items: center;
            justify-content: center;
            border-right: 2px solid $color5;
            padding-right: 20px;
            .check{
              text-align: center;
              .checkbox{
                font-size: 40px;
                &.active{
                  color: $color5;
                }
                &.ship{
                  color: $color5;
                }
              }
              .checkText{
                text-transform: uppercase;
                font-weight: 700;
                font-size: 12px;
              }
            }
          }
          .text{
            font-size: 18px;
            font-weight: 600;
            padding-left: 10px;
            flex: 1;
          }
        }
        .fa{
          height: 15px;
          margin-top: -5px;
        }
      }
      .accordionZone{
        height: 0px;
        overflow: hidden;
        &.active{
          height: auto;
        }
        .accordionZoneInner{
          border-top: 2px solid $color5;
          padding: 10px 15px;
          .cont{
            padding-bottom: 20px;
            .info{
              flex: 1;
              &.infoTop{
                display: flex;
                flex-wrap: wrap;
                .text{
                  flex: 1;
                  min-width: 50%;
                }
              }
              .text{
                display: flex;
                .desc{
                  font-weight: 600;
                  padding-right: 15px;
                  min-width: 100px;
                  font-size: 12px;
                  text-align: right;
                }
                .val{
                  flex: 1;
                  overflow: auto;
                  
                  font-size: 12px;
                }
              }
            }
          }
          .aligners{
            padding: 5px 10px;
            border: 2px solid $color4;
            .aligner{
              border-bottom: 2px solid $color4;
              padding: 10px;
              &:last-child{
                border: none;
              }
              .title{
                font-size: 16px;
                font-weight: 400;
              }
              .cont{
                display: flex;
                padding: 0;
                .checkWrapper{
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-right: 2px solid $color4;
                  padding-right: 20px;
                  .check{
                    text-align: center;
                    .checkbox{
                      font-size: 40px;
                      &.active{
                        color: $color4;
                      }
                    }
                    .checkText{
                      text-transform: uppercase;
                      font-weight: 700;
                      font-size: 12px;
                    }
                  }
                }
                .info{
                  flex: 1;
                  .text{
                    display: flex;
                    .desc{
                      min-width: 80px;
                    }
                    .val{
                      flex: 1;
                      max-width: 500px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .previewFormPictureList{
    padding: 0 10px;
    border: 1px solid $color1;
    max-height: 500px;
    overflow: auto;
    margin-bottom: 25px;
    display: flex;
    flex-wrap: wrap;
    .previewFormPictureListPic{
      flex: 1;
      max-width: 200px;
      width: 100%;
      padding: 10px 0;
      .imageWrapper{
        border: 1px solid #ddd;
        padding: 5px;
        .image{
          color: #000;
          font-size: 14px;
          padding-bottom: 5px;
          img{
            max-width: 100%;
          }
        }
        .emph{
          font-weight: 500;
          color: #000;
        }
        .nameAndDate{
          //display: flex;
          //flex-wrap: wrap;
          .date{
            flex: 1;
            color: $color1Dark;
            font-size: 12px;
          }
          .status, .name{
            color: $color1Dark;
            font-size: 12px;
          }
        }
      }
    }
  }
  .notesList{
    .notesListInputWrapper{
      flex: 1 1;
      overflow: auto;
      border: 2px solid $color4;
      border-radius: 5px;
      padding: 0 10px;
      .notesListForm{
        padding-bottom: 15px;
        border-bottom: 1px solid #ddd;
        padding-top: 5px;
        .noteForm{
          border: 1px solid $color1;
          border-radius: 5px;
          padding: 10px;
          position: relative;
          margin-top: 10px;
          .noteTitle{
            font-weight: 600;
            color: #333;
            text-transform: uppercase;
          }
          .noteMessage{
            padding-bottom: 10px;
            padding-right: 20px;
            font-size: 13px;
            border-bottom: 1px solid #ddd;
            margin-bottom: 10px;
          }
          .emph{
            font-weight: 500;
            color: #000;
          }
          .noteDates{
            display: flex;
            flex-wrap: wrap;
            font-weight: 500;
            *{
              font-size: 10px;
            }
            .noteDateFirst{
              flex: 1;
            }
            .noteDate{
              color: $color1Dark;
              flex: 1;
            }
            .noteStatus{
              flex: 1;
            }
          }
          .noteStatus{
            color: $color1Dark;
           //font-size: 12px; 
          }
        }
      }
    }
  }
}