@import '../../global/colors.scss';
#LabHelp{
  .infoColumn{
    min-width: 400px;
    //max-width: 300px;
    .infoSection{
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 100%;
      .infoTitle{
        font-weight: 600;
        text-transform: uppercase;
        font-size: 30px;
        padding-bottom: 20px;
      }
      .infoItemsWrapper{
        flex: 1;
        display: flex;
        .infoItems{
          width: 100%;
          .infoItem{
            display: flex;
            align-items: center;
            justify-content: center;
            padding-bottom: 20px;
            .icon{
              font-size: 25px;
              width: 50px;
              text-align: left;
            }
            .infoContent{
              flex: 1;
              font-size: 16px;
            }
          }
        }
      }
    }
  }
}