@import '../../global/colors.scss';
#PreStartChecklist{
	background: rgba(255,255,255,.8);
  // position: absolute;
  // left: 0;
  // right: 0;
  // top: 0;
  // bottom: 0;
  border-radius: 15px;
  padding: 30px;
  border: 1px solid #ddd;
  z-index: 10;
  .blurSmall{
  	overflow: hidden;
  	max-height: 300px;
    filter: blur(8px);
  }
  .preStartChecklistTitle{
  	font-size: 40px;
  	font-weight: 500;
  	border-bottom: 1px solid #ddd;
  }
  .preStartItems{
  	padding-top: 40px;
		.preStartStepButtons{
			display: flex;
			//max-width: 700px;
			margin: auto;
			align-items: center;
			//justify-content: center;
			text-align: center;
			padding-bottom: 40px;
			.step{
				flex: 1;
				border-bottom: 5px solid;
				margin: 0 5px;
				max-width: 400px;
				&.active{
					border-color: $color1;
					.stepIcon{
						border-color: $color1;
						background-color: $color1;
					}
				}
				.stepIcon{
					display: flex;
			    align-items: center;
			    justify-content: center;
			    font-size: 14px;
			    border-radius: 8px;
    			background: #000000;
    			margin: auto;
			    border: 2px solid #000000;
			    color: #FFF;
			    width: 100px;
			    .checkbox{
		    		padding-right: 10px;
		  			font-weight: 500;
		    	}
		    	.stepText{
		    		font-weight: 600;
			    }
				}
				.stepTitle{
						text-transform: uppercase;
					font-size: 24px;
					padding: 5px 0;
				}
			}
  		
		}
  	.preStartItem{
  		display: flex;
  		padding-bottom: 40px;
  		border-bottom: 1px solid #ddd;
  		margin-bottom: 40px;
    	.text{
    		padding: 0 20px;
    		.title{
    			display: flex;
    			align-items: center;
    			padding-bottom: 10px;
    			.number{
		    		background-color: $color1;
		    		overflow: hidden;
		    		border-radius: 500px;
		    		display: flex;
		    		align-items: center;
		    		justify-content: center;
		    		min-width: 30px;
		    		width: 30px;
		    		min-height: 30px;
		    		height: 30px;
		    		div{
			    		color: #FFF;
		    			font-size: 20px;
		    			font-weight: 500;
		    		}
		    	}
		    	.titleText{
		    		padding-left: 10px;
		    		font-size: 25px;
    				font-weight: 500;
    				flex: 1;
		    	}
    		}
    		.description{
    			padding-top: 10px;
    		}
    	}
    	.status{
		    width: 350px;
		    min-width: 350px;
		    background: #f5f5f5;
		    padding: 15px 20px;
		    min-height: 200px;
    		.statusText{
    			.statusTextContainer{
    				display: flex;
    				border-bottom: 2px solid $color1;
    				padding: 15px 0;
    				margin-bottom: 10px;
	    			.statusIcon{
	    				display: flex;
	    				align-items: center;
		  				justify-content: center;
		  				i{
		  					font-size: 50px;
		  				}
	    			}
	    			.statusText{
	    				padding-left: 20px;
					    flex: 1;
		    			.statusTextTitle{
		    				font-size: 18px;
		    				margin-bottom: -5px;
		    			}
		    			.statusTextStatus{
		    				font-size: 22px;
		    				font-weight: 700;
		    				color: $color1;
		    				text-transform: uppercase;
		    			}
	    			}
    			}
    			.statusTextDesc{
    				padding-top: 10px;
    			}
    		}
    	}


	  	.contentSections{
	  		margin-bottom: 40px;
	  		&.products{
	  			position: relative;
	  			.purchasedMessageWrapper{
	  				background: rgba(255, 255, 255, .4);
	  				display: flex;
	  				align-items: center;
	  				justify-content: center;
	  				position: absolute;
	  				left: 0;
	  				right: 0;
	  				top: 0;
	  				bottom: 0;
	  				z-index: 2;
	  				border: 1px solid $color1;
		  			.purchasedMessage{
		  				max-width: 800px;
		  				text-align: center;
							.purchasedMessageTitle{
								font-size: 30px;
								font-weight: 600;
							}
							.purchasedMessageText{
								font-size: 17px;
								line-height: 1.4;
							}
							.buttonGroup{
						    align-items: center;
						    justify-content: center;
						    padding-top: 20px;
							}
		  			}
	  			}
	  		}
	  		.overlayMessageOutter{
	  			position: relative;
	  			overflow: hidden;
		  		.overlayMessageWrapper{
	  				background: rgba(255, 255, 255, .8);
	  				display: flex;
	  				align-items: center;
	  				justify-content: center;
	  				position: absolute;
	  				left: 0;
	  				right: 0;
	  				top: 0;
	  				bottom: 0;
	  				z-index: 2;
	  				border: 1px solid $color1;
		  			.overlayMessage{
		  				max-width: 800px;
		  				text-align: center;
							.overlayMessageTitle{
								font-size: 30px;
								font-weight: 600;
							}
							.overlayMessageText{
								font-size: 17px;
								line-height: 1.4;
							}
							.buttonGroup{
						    align-items: center;
						    justify-content: center;
						    padding-top: 20px;
							}
		  			}
	  			}
	  		}
	  		h4{
	  			margin-bottom: 10px;
	  		}
	  		.description{
	  			padding: 0;
	  			p{
	  				line-height: 1.5;
	  			}
	  			p:last-child{
	  				margin: 0;
	  			}
	  		}
	  		.SelfieImagePairSet{
	  			//display: flex;
	  			//flex-wrap: wrap;
	  			margin: 40px 0px 0;
	  			.selfieImagePair{
		  			padding-bottom: 40px;
	  				.editableImage * {
						  max-height: 500px;
						}
		  			//min-width: 500px;
		  			//width: 500px;
		  			//display: flex;
	  				//align-items: center;
	  				//justify-content: center;
	  				//height: 500px;
	  				
		  			.imageExample{
		  				flex: 1;
		  			}
						.imageUploaded{
							flex: 1;
						}
		  		}
	  		}
	  	}
  	}
  }

  .productWrapper{
  	background: #ffffff;
    padding: 20px 30px;
    border: 2px solid #03a9f4;
    &.disabled{
    	background: #f5f5f5;
	    opacity: 0.5;
	    filter: blur(5px);
    }
  }
  .productTitle{
  	font-size: 25px;
  	padding: 0px 0px 0px;
  }




  .product.productLayout2{
	  display: flex;
	  padding-top: 10px;
	  .productImages{
	    padding: 0 40px 40px 0;
	    max-width: 310px;
	    .productImage{
	      padding: 0 0 10px 0;
	      img{
	        max-width: 100%;
	      }
	    }
	    .smollImages{
	      .smollImage{
	        max-width: 25%;
	        img{
	          max-width: 100%;
	        }
	      }
	    }
	  }
	  .productText{
	    padding-bottom: 40px;
	    flex: 1;
	    .productTextName{
	      font-weight: 500;
	      font-size: 24px;
	    }
	    .productTextDesc{
	      padding-bottom: 15px;
	    }
	    .productTextCost{
	      font-size: 20px;
	      font-weight: 500;
	      small{
	        padding-left: 10px;
	        text-decoration: line-through;
	      }
	    }
	    .productTextSave{
	      font-size: 18px;
	      padding-bottom: 15px;
	    }
	    .buttonGroup{
	    }
	  }
	}
}