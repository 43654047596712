@import './colors.scss';
.languageMenu{
  align-items: center;
  justify-content: flex-end;
  .languageMenuItem{
    display: flex;
    padding-left: 15px;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    height: 38px;
    opacity: .5;
    &:hover, &.active{
      opacity: 1;
      div{
        color: #000;
      }
      img{
        border-color: #000; 
      }
    }
    div{
      padding-right: 5px;
      text-transform: uppercase;
      color: #fff;
      font-size: 14px;
      font-weight: 500;
    }
    img{
      border: 1px solid transparent;
      width: 20px;
      height: 20px;
      border-radius: 100px;
    }
  }
}


@media only screen and (max-width: 800px) and (min-width: 576px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 480px) {
}