@import './colors.scss';
#Cart{
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  width: 90%;
  max-width: 450px;
  background: #FFF;
  border-left: 1px solid #eee;
  display: flex;
  height: 100%;
  overflow: hidden;
  animation-duration: .5s;
  flex-direction: column;
  &.hidden{
    display: none;
  }
  .title{
    background: $color5;
    color: #fff;
    padding: 10px;
    font-size: 25px;
  }
  .cartContent{
    flex: 1;

  }
  .buttonGroup{
    padding: 10px 20px 20px;
    justify-content: flex-end;
    button{
      margin-bottom: 0;
      margin-top: 0;
    }
  }
}

#CartContent{
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  #Products{
      padding: 0 0 20px;
      flex: 1;
      border-bottom: 1px solid #ddd;
      .productsTitle{
        font-weight: 600;
        font-size: 20px;
        padding-bottom: 20px;
      }
      .products{
        .product{
          padding-bottom: 10px;
          .image{
            width: 80px;
            border: 1px solid #ddd;
            img{
              max-width: 100%;
            }
          } 
          .productInfo{
            padding-left: 15px;
            flex: 1;
            .name{
              font-size: 14px;
              padding-bottom: 5px;
            }
            .quantity{
              align-items: center;
              i{
                padding: 0 8px;
              }
              .quantityInput{
                input{
                  max-width: 60px;
                  font-size: 12px;
                }
              }
              .quantityPrice{
                font-weight: 500;
                font-size: 14px;
              }
              .quantityPriceDiscounted{
                text-decoration: line-through;
                padding-right: 6px;
                font-size: 14px;
                color: #656565;
              }
            }
          }
          .toss{
            cursor: pointer;
            &:hover{
              color: $color5;
            }
          }
        }
      }
    }
    #Coupons{
      padding: 20px 0;
      border-bottom: 1px solid #ddd;
      .coupon{
        .couponTitle{
          padding-bottom: 10px;
        }
        .couponFunction{
          input{

          }
          button{
            margin: 0;
            padding: 6px 20px;
            min-width: 100px;
            margin-left: 10px;
            height: 38px;
          }
        }
      }
      .couponInfo{
        padding-top: 10px;
        font-size: 14px;
        font-weight: 600;
        .couponName{
          padding-left: 5px;
        }
      }
    }
    #Summary{
      padding: 20px 0 0;
      .summary{
        .summaryRow{
          padding-bottom: 10px;
          &.summaryTotal{
            border-top: 1px solid #000;
            padding-top: 20px;
            margin-top: 15px;
            .summaryTitle{
              font-weight: 700;
              font-size: 18px;
            }
            .summaryValue{
              font-weight: 600;
              font-size: 18px;
            }  
          }
          &.summaryDiscount{
            .summaryValue{
              font-weight: 600;
            }
          }
          .summaryTitle{
            flex: 1;
            font-weight: 600;
          }
          .summaryValue{
            flex: 1;
            text-align: right;
          }
        }
      }
    }
}


@media only screen and (max-width: 800px) and (min-width: 576px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 480px) {
}