@import '../../global/colors.scss';
#ImpressionKitBar{
  align-items: center;
  align-items: center;
  border-top: 10px solid #e91e63;
  border-bottom: 10px solid #e91e63 !important;
  margin-top: -1px;
  .productLayout1{
    display: flex;
    .productImage{
      &.largeScreen{
        padding: 40px 40px 0px 0;
        max-width: 500px;
      }
      padding: 0 40px 40px 0;
      max-width: 500px;
      .activeImage{
        padding: 0 0 10px 0;
        img{
          max-width: 100%;
        }
      }
      .smollImages{
        display: flex;
        margin: 0 -5px;
        .smollImage{
          flex: 1;
          padding: 5px;
          cursor: pointer;
          &:hover{
            opacity: .5;
          }
          img{
            max-width: 100%;
          }
        }
      }
    }
    .productText{
      padding-bottom: 0px;
      flex: 1;
      .productTextName{
        padding-top: 0px;
        font-weight: 800;
        font-size: 33px;
      }
      .productTextDesc{
        padding-top: 15px;
        padding-bottom: 15px;
        div, span, p, ul, li, ol{
          font-weight: 400;
          strong{
            font-weight: 600;
          }
        }
      }
      .productTextCost{
        display: flex;
        align-items: center;
        .cost{
          font-size: 22px;
          font-weight: 600;
          color: $color1;
        }
        .originalPrice{
          padding-left: 10px;
          font-weight: 300;
          text-decoration: line-through;
        }
      }
      .productTextSave{
        font-size: 18px;
        padding-bottom: 15px;
      }
      .buttonGroup{
        .dontCloseCart{
          width: 200px;
        }
      }
    }
  }

  .productImageWrapper{
    max-width: 400px;
    min-width: 330px;
    padding-right: 30px;
    padding-bottom: 20px;
    .productImage{
      padding: 0;
      img{
        border-radius: 10px;
        overflow: hidden;
        border: 1px solid #ddd;
      }
    }
  }
  .productText{
    padding-bottom: 20px;
    .buttonGroup{
      flex-wrap: wrap;
      .button{
        margin: 20px 5px 10px;
      }
      .orSpacer{
        margin: 20px 5px 10px; 
      }
    }
  }

  .stars{
    padding: 5px 0 10px;
    .star{
      display: inline-block;
      color: #ffb600;
      font-size: 18px;
      padding-right: 2px;
    }
  }
  .riskFree{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 20px;
    .award{
      color: #ffb600;
      font-size: 60px;
      width: 60px;
    }
    .text{
      flex: 1;
      padding-left: 10px;
      .header{
        font-weight: 600;
        font-size: 20px;
      }
      .guarantee{
        .emph{
          font-weight: 500;
        }
        .emph2{
          font-weight: 600;
          color: $color4;
        }
      }
    }
  }
}




@media only screen and (max-width: 990px){
  #ImpressionKitBar{
    flex-wrap: wrap;
    .productImageWrapper{
      max-width: 400px;
      min-width: 0px;
      width: 90%;
      padding-right: 0px;
      padding-bottom: 30px;
      margin: auto;
      .productImage{
        padding: 0;
      }
    }
    .productText{
      padding-bottom: 20px;
      width: 100%;
    }
  }
}





@media only screen and (max-width: 767px){
  #ImpressionKitBar{
    .productImageWrapper{
      .productImage{
      }
    }
    .productText{
      text-align: center;
      .productTextCost{
        justify-content: center;
      }
      .buttonGroup{
        justify-content: center;
      }
      h2{
        max-width: 400px;
        margin: 10px auto 0;
        padding-bottom: 10px;
      }
    }
    .riskFree{
      flex-wrap: wrap;
      .award{
        width: 100%;
        min-width: 100%;
      }
      .text{
        flex: 1;
        padding: 0px 0px 0px;
        .header{
          font-weight: 600;
          font-size: 18px;
        }
        .guarantee{
          font-size: 14px;
          .emph{
            font-weight: 500;
          }
          .emph2{
            font-weight: 600;
            color: $color4;
          }
        }
      }
    }
  }
}
