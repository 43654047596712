@import './colors.scss';
$borderBasic: 1px solid #ddd;
form.FormBuilder{
  input, textarea{
    &:hover{
      border-color: $color1;
    }
  }
  select{
    &:hover{
      border-color: $color1;
      cursor: pointer;
    }
  }
	.react-datepicker-wrapper{
		width: 100%;
	}
	.form-group{
		margin-bottom: 1.5rem;
		.form-text{
			&.errorMessage.red-text{
				color: red;
			}
		}
		.input-header{
			display: flex;
			.formTitle{
				font-weight: 500;
				font-size: 16px;
			}
			.requiredStar{
				padding-left: 10px;
		    font-size: 6px;
		    padding-top: 8px;
				&.orange-text{
					color: red;
				}
			}
		}
		.form-control{
			&.false{
				border-color: red;
			}
		}
		.input-header-sub{
			margin: -10px 0 0 0;
			padding-bottom: 10px;
			.formSubTitle{
				font-size: 13px;
				color: #4e4e4e;
			}
		}
    .input-input-wrapper{
      display: flex;
      align-items: center;
      justify-content: center;
      input{
        flex: 1;
      }
    }
		.tabedFields{
	    padding: 20px 10px 20px 20px;
	    border: 1px solid $color1;
	    border-left-width: 15px;
	    margin-bottom: 40px;
			.tabedFieldsTitle{
				.formTitle{
					font-weight: 600;
					font-size: 16px;
					text-transform: uppercase; 
				}
			}
			.tabedFieldsSubTitle{
				margin: -10px 0 0 0;
				padding-bottom: 10px;
				.formSubTitle{
					font-size: 12px;
					color: #4e4e4e;
				}
			}
		}
		.APISelectBoxWithAdd{
			position: relative;
			padding-right: 50px;
			select{
			}
			.APISelectBoxWithAddCustomInput{
				position: absolute;
				overflow: hidden;
				top: 0;
				right: 0;
				bottom: 0;
				max-width: 40px;
				width: 100%;
				transition: max-width .5s ease;
				&.active{
					max-width: 100%;
					.inputField{
						.inputWrapper{
							flex: 1;
							max-width: 100%;
							padding-right: 10px;
						}
					}
				}
				.inputField{
					display: flex;
					.inputWrapper{
						overflow: hidden;
						//transition: max-width .5s ease;
						max-width: 0px;
					}
					input{
						border-color: $color1;
					}
					button{
						width: 40px;
						color: $color1;
						cursor: pointer;
						border: 1px solid $color1;
						background-color: #FFF;
						border-radius: 5px;
						&:hover{
							color: #FFF;
							background-color: $color1;
						}
					}
				}
			}
		}
    .keyValueList{
      .keyValueListInput{
        display: flex;
        .keyValueListInputWrapper{
          flex: 1;
          display: flex;
          padding-right: 10px;
          .keyValueListInputKey{
            margin-right: 10px;
          }
          .keyValueListInputKey, .keyValueListInputValue{
            padding: 0.375rem 0.75rem;
            font-size: 14px;
            font-weight: 400;
            line-height: 1.5;
            height: 40px;
          }
        }
        .btn.button{
          width: 40px;
          max-width: 40px;
          min-width: 40px;
          height: 40px;
          color: $color1;
          border-color: $color1;
          cursor: pointer;
          background-color: #FFF;
          margin: 0;
          text-align: center;
          justify-content: center;
          display: flex;
          padding: 0;
          line-height: 40px;
          i{
            font-size: inherit;
            margin: auto;
            padding: 0;
          }
          &:hover{
            color: #FFF;
            background-color: $color1;
          }
        }
      }
      .keyValueListCurrent{
        padding-top: 20px;
        padding-bottom: 20px;
        margin-top: 20px;
        border-top: $borderBasic;
        .keyValueListCurrentTitle{
          font-weight: 500;
          font-size: 16px;
          padding-bottom: 10px;
        }
        .keyValueListItemWrapperHeader{
          display: flex;
          margin-bottom: 10px;
          border-radius: 5px;
          .keyValueListItem{
            display: flex;
            flex: 1;
            .keyValueListKey, .keyValueListValue{
              padding: 0px;
              flex: 1;
              font-size: 12px;
              font-weight: 600;
              text-transform: uppercase;
            }
            .keyValueListValue{
              margin-left: -20px;
            }
          }
        }
        .keyValueListItemWrapper{
          display: flex;
          border: $borderBasic;
          margin-bottom: 10px;
          border-radius: 5px;
          &:hover{
            border-color: $color1;
          }
          .keyValueListItem{
            display: flex;
            flex: 1;
            .dividerVer{
              height: 30px;
              border-right: 1px solid #ddd;
              margin: auto;
              padding-left: 8px;
              margin-right: 8px;
            }
            .keyValueListKey, .keyValueListValue{
              padding: 10px;
              flex: 1;
              font-size: 14px;
              font-weight: 500;
            }
          }
          i{
            height: 40px;
            width: 40px;
            align-items: center;
            justify-content: center;
            text-align: center;
            display: flex;
            font-size: 15px;
            cursor: pointer;
            &:hover{
              color: red;
            }
          }
        }
      }
    }
	}
	.imageUpload{
		//.editableImage found in /Users/danielezaldivar/Sites/sonrisas/src/styles/global/EditableImage.scss
		&.error{
			.dropZone{
				img{
					border: 4px solid red;
				}
			}
		}
	}
	.wordList{
		.wordListInput{
			input{

			}
			.button{
				margin: 0px 0px 0px 10px;
		    padding: 0px;
		    min-width: 40px;
		    display: flex;
		    align-items: center;
		    justify-content: center;
		    font-size: 24px;
			}
		}
		.wordListCurrent{
			padding: 10px 0;
			.wordListCurrentItem{
				border: $borderBasic;
				padding: 5px;
				margin-bottom: 5px;
				align-items: center;
		    justify-content: center;
				div{
					padding: 0 5px;
					flex: 1;
				}
				i{
					padding: 0 5px;
					cursor: pointer;
					&:hover{
						color: $color1;
					}
				}
			}
		}
	}
	.form-toggle {
	  padding:10px 0;
	  display:-ms-flexbox;
	  display:flex
	  h5{
	    padding: 10px 0px;
	    flex: 1 1 0%;
	    overflow: hidden;
	    display: flex;
	    align-items: center;
	    margin: 0px;
	  }
	  .text{
	    flex: 1;
	    margin
	    .input-header{
	      .formTitle{
	        margin: 0;
	      }
	    }
	    .input-header-sub{
	      margin: 0;
	      padding: 0;
	    }
	  }
	  .Routes {
	    position: relative;
	    display: inline-block;
	    width: 60px;
	    height: 34px;
	    input{
	      opacity: 0;
	    }
	    .slider{
	      position: absolute;
	      cursor: pointer;
	      top: 0;
	      left: 0;
	      right: 0;
	      bottom: 0;
	      background-color: #ccc;
	      -webkit-transition: .4s;
	      transition: .4s;
	      border-radius: 60px;
	      &:before{
	        position: absolute;
	        content: "";
	        height: 26px;
	        width: 26px;
	        left: 4px;
	        bottom: 4px;
	        background-color: white;
	        -webkit-transition: .4s;
	        transition: .4s;
	        border-radius: 60px;
	      }
	      &.checked{
	        background-color: $color1;
	        &:before {
	          -webkit-transform: translateX(26px);
	          -ms-transform: translateX(26px);
	          transform: translateX(26px);
	        }
	      }
	    }
	  }
	}
  .nothingSelected{
    position: absolute;
    inset: 0px;
    background: rgb(255, 255, 255);
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: bold;
    border: 1px solid;
    border-radius: 5px;
    cursor: pointer;
    &:hover{
      border-color: $color1;
      cursor: pointer;
      color: $color1;
    }
  }
  .formPassword{
    position: relative;
    .passwordCapslockMessage {
      position: absolute;
      background: #FFF;
      top: -15px;
      padding: 5px 10px;
      border: 1px solid #ff3535;
      border-radius: 5px;
      font-weight: 500;
      text-transform: uppercase;
    }
    .arrow-down {
      z-index: 2;
      width: 0;
      height: 0;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #FFF;
      position: absolute;
      bottom: -8px;
      left: 14px;
    }
    .arrow-down2 {
      z-index: 1;
      width: 0;
      height: 0;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 9px solid #ff3535;
      position: absolute;
      bottom: -9px;
      left: 12px;
    }
  }


  .sectionDivider{
    .sectionDividerBasic{
      padding: 15px 0;
      text-transform: uppercase;
      font-weight: 600;
      font-size: 20px;
      display: flex;
      align-items: center;
      .sectionDividerText{

      }
      .sectionDividerBorder{
        flex: 1 1 0%;
        border-top: 4px solid rgb(103, 58, 183);
        height: 4px;
        display: flex;
        align-items: center;
        font-size: 0px;
        margin-left: 30px;
      }
    }
  }
  #AddProductsFormField{
    .change{
      opacity: .6;
      &:hover{
        opacity: 1;
      }
    }
    .subTitle{
      font-size: 14px;
      font-weight: 500;
      padding-bottom: 5px;
    }
    .products{
      .product{
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 10px;
        border-bottom: 1px solid $color1;
        margin-bottom: 10px;
        .img{
          max-width: 50px;
          border: 1px solid $color1;
          border-radius: 5px;
          img{
            border-radius: 5px;
            max-width: 100%;
          }
        }
        .name{
          flex: 1;
          padding-left: 10px;
          text-align: left;
        }
      }
    }
  }
}


@media only screen and (max-width: 800px) and (min-width: 576px) {
}

@media only screen and (max-width: 575px) {
}

@media only screen and (max-width: 480px) {
}